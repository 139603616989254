import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const MdlViewStaffLeaveDetails = ({ isOpen, onClose }) => {
  const [show, setShow] = useState(true);
  const [status, setStatus] = useState("Pending");

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const handleAccept = () => {
    setStatus("Accepted");
    onClose();
  };

  const handleReject = () => {
    setStatus("Rejected");
    onClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Staff Leave Application Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            padding: "20px",
            lineHeight: "1.6",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <div style={{ marginBottom: "20px" }}>
            <strong>To,</strong>
            <br />
            The Principal,
            <br />
            XYZ School,
            <br />
            City, State.
          </div>

          <div style={{ marginBottom: "20px" }}>
            <strong>Subject:</strong> Application for Leave Due to Family
            Emergency
          </div>

          <div style={{ marginBottom: "20px" }}>
            <p>Respected Sir/Madam,</p>
            <p>
              I am <strong>Mr. Rajesh Sharma</strong>, working as a{" "}
              <strong>Mathematics Teacher</strong>. Due to a family emergency, I
              am unable to attend school from{" "}
              <strong>10th December 2024</strong> to{" "}
              <strong>12th December 2024</strong>. Kindly grant me leave for the
              mentioned days. I shall be highly grateful for your consideration.
            </p>
            <div style={{ margin: "5px", lineHeight: "1.2" }}>
              <p>Thanking You,</p>
              <p>
                Yours sincerely,
                <br />
                Mr. Rajesh Sharma, Department: Mathematics,
                <br />
                Designation: Teacher,
                <br />
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <div>
              <strong>Status:</strong> {status}
            </div>
            <div>
              <strong>Date:</strong> 10/12/2024
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleAccept}>
          Accept
        </Button>
        <Button variant="danger" onClick={handleReject}>
          Reject
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MdlViewStaffLeaveDetails;
