import React, { useState, useEffect } from "react";
import "../css/Admin_css/admin-customize.css";
import { Redirect, Route } from "react-router-dom";
import axios from "axios";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import SessionManager from "../Utils/SessionManager";
import MyLoader from "../Spinner/MyLoader";
import myToast from "../Utils/MyToast";
import Form from "react-bootstrap/Form";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const StaffRegistration = () => {
  const [formData, setFormData] = useState({
    staffName: "",
    department_id: "",
    dob: "",
    doj: "",
    stRegion: "",
    gender: "",
    AdharNo: "",
    nationality: "",
    staffRole: "",
    loginType: "",
    phone: "",
    email: "",
    Village: "",
    Post: "",
    District: "",
    State: "",
    Pincode: "",
  });

  const [mDepartment, setMDepartment] = useState([]);
  const [mstaffRole, setMstaffRole] = useState([
    "Teacher",
    "Clerk",
    "Class Teacher",
    "Clerk And Techer",
    "Director And Principal",
    "Director And Voice Principal",
    "Faculty",
    "LDA",
    "UDA",
    "Tutor And LDA",
    "Academic Incharge",
    "Child Caretaker",
    "Mother Teacher",
    "Computer Operator",
    "Head Master",
    "Physical Teacher",
    "Office Bearers",
    "Driver",
    "Helper",
  ]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDepartment();
  }, []);

  const getDepartment = async () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getDepartment.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    try {
      const response = await axios.get(URL, {
        params: { school_code: SCHOOL_CODE },
      });
      setLoading(false);
      if (response.data.api_status === "OK") {
        setMDepartment(response.data.department);
      } else {
        myToast.notify_warning(response.data.api_remark);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching staff list:", error);
    }
  };

  const regChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleChangeDepartment = (e) => {
    setFormData({
      ...formData,
      department_id: e.target.value,
    });
  };

  const handleChangeStaffRole = (e) => {
    setFormData({
      ...formData,
      staffRole: e.target.value,
    });
  };

  const handleChangeloginType = (e) => {
    setFormData({
      ...formData,
      loginType: e.target.value,
    });
  };

  const handleChangeGender = (e) => {
    setFormData({
      ...formData,
      gender: e.target.value,
    });
  };

  const handleChangeReligion = (e) => {
    setFormData({
      ...formData,
      stRegion: e.target.value,
    });
  };

  const isValidInput = () => {
    if (!formData.staffName || !formData.phone || !formData.email) {
      myToast.notify_warning("Please fill all required fields.");
      return false;
    }
    return true;
  };

  const regSubmit = () => {
    if (isValidInput()) {
      setLoading(true);
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      const FUNCTION_NAME = "registerStaff.php?";
      const URL =
        process.env.REACT_APP_SERVER_NAME +
        process.env.REACT_APP_TARGET_DIR +
        FUNCTION_NAME;
      console.log("URL>>>>>" + URL);
      axios
        .get(URL, {
          params: {
            school_code: SCHOOL_CODE,
            StaffName: formData.staffName,
            department_id: formData.department_id,
            dob: formData.dob,
            doj: formData.doj,
            religion: formData.stRegion,
            // Gender directly from formData.gender
            gender: formData.gender,
            aadhar_no: formData.AdharNo,
            nationality: formData.nationality,
            role: formData.staffRole,
            login_type: formData.loginType,
            address:
              formData.Village +
              formData.Post +
              formData.District +
              formData.State +
              formData.Pincode,
            phone: formData.phone,
            email: formData.email,
          },
        })
        .then((res) => res.data)
        .then((response) => {
          setLoading(false);
          let api_status = response.api_status;
          alert("successfully Updated !");
          if (typeof window !== "undefined") {
            window.location.reload(false);
            window.location.href = "../AdminMain/StaffList/MDL012";
          }
          console.log("response>>>>>" + api_status);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error in registration:", error);
        });
    }
  };

  if (loading) {
    return <MyLoader />;
  }

  return (
    <div className="content">
      <div className="content-header">
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li>
              <i className="fa fa-home" aria-hidden="true" />
              <a href="#">Staff Registration</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="row animated fadeInUp">
        <div className="col-sm-12 col-lg-12">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="panel">
                <div
                  className="panel-content text-center"
                  style={{ minHeight: "400px" }}
                >
                  <div className="panel-body">
                    <div className="pnlSubHedingBorder">
                      <div className="pnlSubHeding heading-with-bg-w">
                        Personal Details
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 bg-white mar_bottom mar_top1 ">
                        <div className="circular_image1">
                          <img
                            src="https://clevelandpolicefoundation.org/wp-content/uploads/2012/09/female-placeholder.png"
                            className="img_staff1"
                            alt="student list image"
                          />
                        </div>
                        <div className="photo-row-bottom posi-rel mar_top1">
                          <input
                            type="file"
                            name="upload photo"
                            style={{ width: "168px" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div className="row">
                          {/* Staff Name */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Staff Name</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input
                                    type="text"
                                    required
                                    id="staffName"
                                    name="staff name"
                                    variant="outlined"
                                    placeholder="Staff Name"
                                    value={formData.staffName}
                                    onChange={regChange}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Department */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Department</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="admission_form2">
                                  <Form.Select
                                    className="staff_role staff_mar"
                                    onChange={handleChangeDepartment}
                                  >
                                    <option>Select Department</option>
                                    {mDepartment.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item.department_name}
                                        department_id={item.department_id}
                                      >
                                        {item.department_name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Staff Role */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Staff Role</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="admission_form2">
                                  <Form.Select
                                    className="staff_role staff_mar"
                                    onChange={handleChangeStaffRole}
                                  >
                                    <option>Select Role</option>
                                    {mstaffRole.map((item, index) => (
                                      <option key={index} value={item}>
                                        {item}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Login Type */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Login Type</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="admission_form2">
                                  <Form.Select
                                    className="staff_role staff_mar"
                                    onChange={handleChangeloginType}
                                  >
                                    <option>login Type</option>
                                    <option value="Admin">Admin</option>
                                    <option value="Teacher">Teacher</option>
                                    <option value="Clerk">Clerk</option>
                                    <option value="None">None</option>
                                  </Form.Select>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Phone Number */}
                          <div className="col-md-6 mar_top1">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Phone Number</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input
                                    type="tel"
                                    required
                                    id="phone"
                                    name=""
                                    placeholder="Phone Number"
                                    value={formData.phone}
                                    onChange={regChange}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Email Address */}
                          <div className="col-md-6 mar_top1">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Email Address</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input
                                    type="email"
                                    required
                                    id="email"
                                    name=""
                                    placeholder="Email id"
                                    value={formData.email}
                                    onChange={regChange}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Date of Joining */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Date of Joining</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input
                                    type="date"
                                    required
                                    id="doj"
                                    name="doj"
                                    placeholder="Date of Joining"
                                    value={formData.doj}
                                    onChange={regChange}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Date of Birth */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Date of Birth</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input
                                    type="date"
                                    required
                                    id="dob"
                                    name="dob"
                                    placeholder="Date of Birth"
                                    value={formData.dob}
                                    onChange={regChange}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Gender */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0 mar_top1">
                                <label> Gender </label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="d-flex">
                                  <RadioGroup
                                    aria-label="position"
                                    name="radio_css_inline"
                                    id="inlineCssRadio"
                                    value={formData.gender}
                                    onChange={handleChangeGender}
                                    row
                                  >
                                    <FormControlLabel
                                      value="Male"
                                      control={<Radio color="primary" />}
                                      label="Male"
                                      labelPlacement="end"
                                    />
                                    <FormControlLabel
                                      value="Female"
                                      control={<Radio color="primary" />}
                                      label="Female"
                                      labelPlacement="end"
                                    />
                                  </RadioGroup>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Religion */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Religion</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="admission_form2">
                                  <Form.Select
                                    className="staff_role staff_mar"
                                    onChange={handleChangeReligion}
                                  >
                                    <option>Select Religion</option>
                                    <option value="Hindu">Hindu</option>
                                    <option value="Muslim">Muslim</option>
                                    <option value="Christian">Christian</option>
                                    <option value="Sikh">Sikh</option>
                                    <option value="Buddhist">Buddhist</option>
                                    <option value="Jain">Jain</option>
                                    <option value="Other">Other</option>
                                  </Form.Select>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Adhar Number */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Adhar Number</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input
                                    type="text"
                                    required
                                    id="AdharNo"
                                    name=""
                                    placeholder="Adhar Number"
                                    value={formData.AdharNo}
                                    onChange={regChange}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Nationality */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Nationality</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input
                                    type="text"
                                    required
                                    id="nationality"
                                    name="nationality"
                                    placeholder="Nationality"
                                    value={formData.nationality}
                                    onChange={regChange}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ float: "left", width: "100%" }}>
                      <div className="pnlSubHedingBorder headMarginTop">
                        <div className="pnlSubHeding heading-with-bg-w">
                          Address
                        </div>
                      </div>
                      <div className="form-horizontal form-bordered">
                        <div className="form-group row">
                          <label className="col-md-2 col-form-label">
                            {" "}
                            Village/Town{" "}
                          </label>
                          <div className="col-md-10">
                            <div className="input-group">
                              <input
                                id="Village"
                                value={formData.Village}
                                onChange={regChange}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <label className="col-md-2 col-form-label">
                            {" "}
                            Post{" "}
                          </label>
                          <div className="col-md-4">
                            <div className="input-group">
                              <input
                                id="Post"
                                type="text"
                                value={formData.Post}
                                onChange={regChange}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <label className="col-md-2 col-form-label">
                            {" "}
                            District{" "}
                          </label>
                          <div className="col-md-4">
                            <div className="input-group">
                              <input
                                id="District"
                                type="text"
                                value={formData.District}
                                onChange={regChange}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <label className="col-md-2 col-form-label">
                            {" "}
                            State{" "}
                          </label>
                          <div className="col-md-4">
                            <div className="input-group">
                              <input
                                id="State"
                                type="text"
                                value={formData.State}
                                onChange={regChange}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <label className="col-md-2 col-form-label">
                            {" "}
                            Pin Code{" "}
                          </label>
                          <div className="col-md-4">
                            <div className="input-group">
                              <input
                                id="Pincode"
                                type="text"
                                value={formData.Pincode}
                                onChange={regChange}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ float: "left", width: "100%" }}>
                      <div className="pnlSubHedingBorder headMarginTop">
                        <div className="pnlSubHeding heading-with-bg-w">
                          Education Details
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="col-form-label">
                          Degree/Diploma:
                        </label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="col-md-6">
                        <label className="col-form-label">
                          University/Institute:
                        </label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="col-md-6">
                        <label className="col-form-label">Passing Year:</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="col-md-6">
                        <label className="col-form-label">Percentage:</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>

                    <div style={{ float: "left", width: "100%" }}>
                      <div className="row">
                        <div className="col-md-12 padding_0">
                          <button
                            className="btn btn-success"
                            onClick={regSubmit}
                            style={{
                              margin: "2% 0px",
                              fontSize: "20px",
                              width: "100%",
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffRegistration;
