import React, { Component, createRef } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import COLORS from "../resources/colors/COLORS";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import "../css/Admin_css/vp_style.css";
import SelectClass from "../ChildComponent/SelectClass";
import myToast from "../Utils/MyToast";
export default class CurrentDue extends Component {
  constructor(props) {
    super(props);
    this.slt_board = createRef();
    this.slt_medium = createRef();

    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      loggedIn: true,
      Loading: false,
      LoadingStudent: false,
      mClassData: [],
      mClassBoard: [],
      mClassMedium: [],
      mFilteredClassList: [],
      mFilteredClassSectionList: [],

      mDueDetails: [],
      CLASS_SELECTED_POSITION: 0,
      total_amount: 0,
    };
    this.CallBackClassSelect = this.CallBackClassSelect.bind(this);
  }

  componentDidMount() {}
  CallBackClassSelect = (
    class_id,
    section_id,
    session_id,
    selected_position,
    class_name,
    section_name,
    session_name
  ) => {
    this.state.CLASS_ID = class_id;
    this.state.SECTION_ID = section_id;
    this.state.SESSION_ID = session_id;
    this.state.SELECTED_CLASS_POSITION = selected_position;
    // alert("Back"+class_id+"/"+section_id+"/"+session_id);
    this.getClassStudentCurrentDue();
  };
  getClassStudentCurrentDue() {
    this.setState({
      LoadingStudent: true,
      mDueDetails: [],
    });
    this.state.mStudentPayList = [];
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getClassStudentCurrentDue.php";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    console.log("URL>>>>>" + URL);
    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          class_id: this.state.CLASS_ID,
          section_id: this.state.SECTION_ID,
          session_id: this.state.SESSION_ID,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          LoadingStudent: false,
        });
        let api_status = response.api_status;
        // alert(api_status);
        if (api_status == "OK") {
          this.setState({
            mDueDetails: response.data,
          });
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
        //console.log(JSON.stringify(response));
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        console.log("error>>>>>" + error);
      });
  }

  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to="/Login" />;
    }
    if (this.state.Loading) {
      return <MyLoader />;
    } else {
      return (
        <div className="content">
          <div
            className="content-header"
            style={{ background: COLORS.primary_color_shade5 }}
          >
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li>
                  <i
                    className="fa fa-home"
                    aria-hidden="true"
                    style={{ color: COLORS.white }}
                  />
                  <a href="#"> Current Due </a>
                </li>
              </ul>
            </div>
          </div>

          <div
            className="bg_page_white_rounded box_shadow"
            style={{ "--mMargin": "50px" }}
          >
            <SelectClass
              action={this.CallBackClassSelect}
              selected_position="0"
            ></SelectClass>
            <section className="currentdue">
              <div className="currentdue-container">
                {this.state.LoadingStudent ? (
                  <div className="currentdue-loader">
                    <MyLoader />
                  </div>
                ) : (
                  this.state.mDueDetails &&
                  this.state.mDueDetails.map((item, index) => (
                    <div className="currentdue-box" key={index}>
                      <section className="deuold" key={index}>
                        <div className="deuold-container2">
                          <div className="deuold-row">
                            <div className="deuold-profile">
                              <div className="deuold-circle1">
                                <img
                                  src={item.student_img_path}
                                  alt="Student"
                                />
                                <span className="deuold-enrollment">
                                  {item.student_enrollment_no}
                                </span>
                              </div>
                            </div>

                            <div className="deuold-details">
                              <h5>{item.stu_name}</h5>
                              <p>
                                {" "}
                                Father:<span>{item.father_name}</span>
                                &nbsp;&nbsp; Board:
                                <span>{item.board_name}</span> &nbsp;&nbsp;
                                Medium:
                                <small></small>
                              </p>
                            </div>

                            <div className="deuold-roll">
                              <p>Roll No.</p>
                              <h5>{item.student_roll_no}</h5>
                              <p>{item.class_name}</p>
                            </div>
                          </div>
                        </div>
                      </section>

                      <div className="currentdue-details">
                        <div className="currentdue-detail-box current-section">
                          Current Section: INR{" "}
                          {item.pay_summary[0].current_session_due}
                        </div>
                        <div className="currentdue-detail-box prev-section">
                          Prev Section: INR{" "}
                          {item.pay_summary[0].previous_session_due}
                        </div>
                        <div className="currentdue-detail-box accessories">
                          Accessories: INR{" "}
                          {item.pay_summary[0].total_accessories_fee}
                        </div>
                        <div className="currentdue-detail-box current-service">
                          Current Service: INR{" "}
                          {item.pay_summary[0].current_session_service_fee}
                        </div>
                        <div className="currentdue-detail-box prev-service">
                          Prev Service: INR{" "}
                          {item.pay_summary[0].previous_session_service_fee}
                        </div>
                        <div className="currentdue-detail-box old-due">
                          Old Due: INR {item.pay_summary[0].student_old_due}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </section>
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <button
                  className="btn btn-success"
                  style={{
                    width: "100%",
                    fontSize: "24px",
                    margin: "30px 0px 15px 0px",
                    background: COLORS.card_red,
                    border: COLORS.primary_color_shade5,
                  }}
                >
                  {" "}
                  Total Amount: INR {this.state.total_amount}{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
