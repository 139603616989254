import React, { Component } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import proj from "../resources/images/proj.png";
import NavMenuAdmin from "./NavMenuAdmin";
import myToast from "../Utils/MyToast";
export default class StaffPrivilege extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      mStaffPrivilage: [],
      mStaffNameTitle: [],
      SELECTED_STAFF_POSITION: 0,
    };
  }
  componentDidMount() {
    this.getStaffNameTitle();
  }
  getStaffPrivilege() {
    this.setState({
      Loading: true,
    });
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const StaffId =
      this.state.mStaffNameTitle[this.state.SELECTED_STAFF_POSITION].staff_id;
    const FUNCTION_NAME = "getStaffPrivilege.php";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    console.log("URL>>>>>" + URL);
    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          staff_id: StaffId,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          Loading: false,
        });
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        if (api_status == "OK") {
          this.setState({
            mStaffPrivilage: response.data,
          });
          //  alert("response hiiii>>>>>"+JSON.stringify(this.state.mStaffPrivilage));
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }
        //console.log("response>>>>>"+api_status)
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        console.log("error>>>>>" + error);
      });
  }
  // add method for staff details
  getStaffNameTitle() {
    this.setState({
      Loading: true,
    });
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getStaff.php";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    console.log("URL>>>>>" + URL);
    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          Loading: false,
        });
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        if (api_status == "OK") {
          this.setState({
            mStaffNameTitle: response.staff,
          });
          this.getStaffPrivilege();
          //  alert("response hiiii>>>>>"+JSON.stringify(this.state.mStaffPrivilage));
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }
        //console.log("response>>>>>"+api_status)
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        console.log("error>>>>>" + error);
      });
  }

  privilageStaff = (event) => {
    var module_id = event.target.getAttribute("module_id");
    var status = event.target.getAttribute("status");
    var mStatus;
    if (status === "Granted") {
      mStatus = "InActive";
    } else {
      mStatus = "Active";
    }
    //alert(module_id);
    var STAFF_ID =
      this.state.mStaffNameTitle[this.state.SELECTED_STAFF_POSITION].staff_id;
    //alert(STAFF_ID);

    this.setState({
      Loading: true,
    });
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "updateStaffPrivilege.php";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    console.log("URL>>>>>" + URL);
    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          staff_id: STAFF_ID,
          module_id: module_id,
          status: mStatus,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          Loading: false,
        });
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {
          alert("Updated Successfully");

          this.getStaffNameTitle();
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        console.log("error>>>>>" + error);
      });
  };
  Rightclick = () => {
    if (
      this.state.mStaffNameTitle.length - 1 >
      this.state.SELECTED_STAFF_POSITION
    ) {
      this.state.SELECTED_STAFF_POSITION =
        this.state.SELECTED_STAFF_POSITION + 1;
      //alert(this.state.SELECTED_STAFF_POSITION);
      this.getStaffPrivilege();
    } else {
      var elem = document.getElementById("r-arr");
      elem.classList.add("right-arrow-light"); // Add class
      elem.classList.remove("right-arrow"); // Remove class
    }
  };
  Leftclick = () => {
    if (this.state.SELECTED_STAFF_POSITION > 0) {
      this.state.SELECTED_STAFF_POSITION =
        this.state.SELECTED_STAFF_POSITION - 1;
      //alert(this.state.SELECTED_STAFF_POSITION);
      this.getStaffPrivilege();
    } else {
      var elem = document.getElementById("L-arr");
      elem.classList.remove("left-arrow"); // Remove class
      elem.classList.add("left-arrow-light"); // Add class
    }
  };
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to="/Login" />;
    }
    if (this.state.Loading) {
      return <MyLoader />;
    } else {
      return (
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li>
                  <i className="fa fa-home" aria-hidden="true" />
                  <a href="#"> Staff Privilege </a>
                </li>
              </ul>
            </div>
          </div>

          <div
            className="animated fadeInUp bg_page_white_rounded box_shadow"
            style={{ "--mMargin": "80px" }}
          >
            {this.state.mStaffNameTitle.length > 0 ? (
              this.state.mStaffNameTitle.map((staff, index) => (
                <div
                  key={index}
                  className="bg_card_burnt_orange_outline margin_staff"
                >
                  <div className="row StaffPrivilegeNew-container ">
                    <div className="col-sm-2 col-md-2 d-flex align-items-center justify-content-center">
                      <img
                        src={
                          staff.staff_image_path
                            ? staff.staff_image_path
                            : "Staff Images Path"
                        }
                        className="StaffPrivilegeNew-img"
                        alt=""
                      />
                    </div>

                    <div className="col-sm-6 col-md-6 d-flex align-items-center flex-column justify-content-center ">
                      <h4 className="StaffPrivilegeNew-name">
                        {staff.staff_name ? staff.staff_name : "Staff Name"}
                      </h4>
                      <h6 className="StaffPrivilegeNew-role">
                        ({staff.staff_role ? staff.staff_role : "Staff Role"})
                      </h6>
                    </div>

                    <div className="col-sm-4 col-md-4 d-flex align-items-center justify-content-end">
                      <button
                        className="btn btn-primary StaffPrivilegeNew-btn"
                        onClick={() =>
                          this.setState({
                            showPrivileges:
                              this.state.showPrivileges === index
                                ? null
                                : index,
                          })
                        }
                      >
                        Show Privilege
                      </button>
                    </div>
                  </div>

                  {this.state.showPrivileges === index && (
                    <div className="row">
                      {this.state.mStaffPrivilage.map((item, idx) => (
                        <div
                          className="col-sm-4 col-md-4"
                          style={{ padding: "5px" }}
                          key={idx}
                        >
                          <div
                            className="row class-list-box-privelege"
                            style={{
                              height: "60px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-md-8">
                              <h5>{item.module_name}</h5>
                            </div>
                            <div className="col-md-4 text-center">
                              <span className="s-bt">{item.status}</span>
                              <label className="switch switch-height-20">
                                <input
                                  type="checkbox"
                                  checked={item.status === "Granted"}
                                  onClick={this.privilageStaff}
                                  module_id={item.module_id}
                                  status={item.status}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p className="text-center">No staff found</p>
            )}
          </div>
        </div>
      );
    }
  }
}
