import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import myToast from "../Utils/MyToast";

const MdlEditTransportVehicles = ({ actionClose, vehicle }) => {
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [vehicleTypeList] = useState(["AC", "Non-AC", "Other"]);

  const [vehicleData, setVehicleData] = useState({
    vehicle_id: "",
    vehicle_number: "",
    vehicle_name: "",
    vehicle_type: "",
    vehicle_image_url: "Null",
  });

  useEffect(() => {
    if (vehicle) {
      setVehicleData(vehicle);
    }
  }, [vehicle]);

  // Handle Input Changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVehicleData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      const API_NAME = "updateVehicleDetails.php";
      const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${API_NAME}`;

      const params = {
        school_code: SCHOOL_CODE,
        vehicle_id: vehicleData.vehicle_id,
        vehicle_name: vehicleData.vehicle_name,
        vehicle_number: vehicleData.vehicle_number,
        vehicle_type: vehicleData.vehicle_type,
        vehicle_image_url: vehicleData.vehicle_image_url,
      };

      const queryString = new URLSearchParams(params).toString();
      console.log("Full URL: ", `${URL}${queryString}`);
      const response = await axios.get(URL, { params });
      const { api_status, data, api_remark } = response.data;
      //alert(JSON.stringify(data));

      if (api_status === "OK") {
        myToast.notify_success("Vehicle updated successfully!");
        handleClose();
      } else {
        myToast.notify_warning(api_remark || "Update failed.");
      }
    } catch (error) {
      console.error("Error updating vehicle data:", error);
      myToast.notify_error("An error occurred while updating.");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShow(false);
    actionClose();
  };

  return (
    <div className="addTransportVehicle-wrapper">
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton className="addTransportVehicle-header">
          <Modal.Title className="addTransportVehicle-title">
            Edit Vehicle
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="addTransportVehicle-body Vehicles_Transport-container">
          {loading ? (
            <MyLoader />
          ) : (
            <>
              {/* First Row: Vehicle Number and Vehicle Name */}
              <div className="addTransportVehicle-formRow">
                <div className="addTransportVehicle-formField">
                  <label className="addTransportVehicle-label">
                    Vehicle Number
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Vehicle Number"
                    name="vehicle_number"
                    value={vehicleData.vehicle_number}
                    onChange={handleInputChange}
                    className="addTransportVehicle-input"
                  />
                </div>
                <div className="addTransportVehicle-formField">
                  <label className="addTransportVehicle-label">
                    Vehicle Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Vehicle Name"
                    name="vehicle_name"
                    value={vehicleData.vehicle_name}
                    onChange={handleInputChange}
                    className="addTransportVehicle-input"
                  />
                </div>
              </div>

              {/* Second Row: Select Vehicle Type */}
              <div className="addTransportVehicle-formRow">
                <div className="addTransportVehicle-formField">
                  <label className="addTransportVehicle-label">
                    Select Vehicle Type
                  </label>
                  <select
                    name="vehicle_type"
                    value={vehicleData.vehicle_type}
                    onChange={handleInputChange}
                    className="addTransportVehicle-select"
                  >
                    {vehicleTypeList.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="addTransportVehicle-footer">
          <Button
            variant="success"
            onClick={handleSubmit}
            className="addTransportVehicle-submitButton"
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MdlEditTransportVehicles;
