import React, { useState, useEffect } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import { Modal, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import COLORS from "../resources/colors/COLORS";
import myToast from "../Utils/MyToast";

const Rs = "₹";

const MdlAddParticulars = (props) => {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("token") != null
  );
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [classId, setClassId] = useState("");
  const [className, setClassName] = useState("");
  const [mSubjectList, setMSubjectList] = useState([]);
  const [mSubjectMasterList, setMSubjectMasterList] = useState([]);
  const [mFreshSubjectList, setMFreshSubjectList] = useState([]);
  const [activeCategory, setActiveCategory] = useState("All"); // State for category selection

  useEffect(() => {
    setClassId(props.class_id);
    setClassName(props.class_name);
    setMSubjectList(props.class_subject);
    getSubjectMasterList();
  }, []);

  const handleClose = () => {
    setShow(false);
    props.actionClose();
  };

  const handleSubmit = () => {
    setShow(false);
    props.actionClose();
  };

  const getSubjectMasterList = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const API_NAME = "getSubjectMasterList.php";
    const URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      API_NAME;

    axios
      .get(URL, { params: { school_code: SCHOOL_CODE } })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        if (response.api_status === "OK") {
          setMSubjectMasterList(response.data);
          getNotAddedSubject(response.data);
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  };

  const getNotAddedSubject = (subjectMasterList) => {
    const freshSubjects = subjectMasterList.filter(
      (item) =>
        !mSubjectList.some((subject) => subject.subject_id === item.subject_id)
    );
    setMFreshSubjectList(freshSubjects);
  };

  const addClassSubject = (item, index) => {
    // Handle adding the subject logic here
  };

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        style={{ opacity: 1 }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* Header */}
        <Modal.Header className="AddParticulars_header">
          <h2>Select Particulars</h2>
          <button className="close-btn" onClick={handleClose}>
            ✖
          </button>
        </Modal.Header>

        {/* Category Buttons */}
        <Modal.Body>
          <div className="category-buttons">
            {[
              "All",
              "Bags",
              "Dairy",
              "Uniform",
              "School Bags",
              "Chair",
              "Book",
              "Pen",
              "Adm Kit",
            ].map((category) => (
              <button
                key={category}
                className={`category-btn ${
                  activeCategory === category ? "active" : ""
                }`}
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </button>
            ))}
          </div>

          {/* Product Information */}
          {loading ? (
            <MyLoader />
          ) : (
            <div className="row">
              {mFreshSubjectList &&
                mFreshSubjectList
                  .filter(
                    (item) =>
                      activeCategory === "All" ||
                      item.category === activeCategory
                  )
                  .map((item, index) => (
                    <div className="col-md-6 padding_5" key={index}>
                      <div
                        className="product-card bg_card_primary_outline"
                        onClick={() => addClassSubject(item, index)}
                      >
                        <div className="stock-info">
                          <span>In Stock</span>
                          <h3>14</h3>
                        </div>
                        <div className="product-details">
                          <h4>{item.subject_name}</h4>
                          <p>{item.subject_name}</p>
                          <span className="price">{Rs} 4500</span>
                          <button className="add-btn">ADD</button>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          )}
        </Modal.Body>

        {/* Footer */}
        <Modal.Footer>
          <Button
            variant="primary"
            size="lg"
            className="done-btn"
            onClick={handleSubmit}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MdlAddParticulars;
