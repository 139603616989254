import React, { useState } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import "../css/Admin_css/admin-customize.css";
import { useHistory } from "react-router-dom";

const AddHoliday = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [strtDate, setStrtDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [holioccas, setHolioccas] = useState("");
  const [holiremark, setHoliremark] = useState("");
  const [holitype, setHolitype] = useState("");

  const regSubmit = () => {
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "registerHolidays.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    console.log("URL>>>>>" + URL);

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          holiday_start_date: strtDate,
          holiday_end_date: endDate,
          holiday_occasion: holioccas,
          holiday_remark: holiremark,
          holiday_type: holitype,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        let api_status = response.api_status;
        alert("Successfully Updated!");
        history.replace("/AdminMain/Holiday/MDL007");
        window.location.reload();
        console.log("response>>>>>" + api_status);
      });
  };

  if (loading) {
    return <MyLoader />;
  }

  return (
    <div className="content">
      <div className="content-header">
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li>
              <i className="fa fa-home" aria-hidden="true" />
              <a href="#"> Add Holiday </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        className="animated fadeInUpn bg_page_white_rounded box_shadow"
        style={{ "--mMargin": "50px" }}
      >
        <div className="row mar_top">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 col-form-label">
                <label className="">Start Date</label>
              </div>
              <div className="col-md-8 padding_0">
                <div className="input-group date admission_form2">
                  <input
                    type="date"
                    id="strtDate"
                    name="strtDate"
                    value={strtDate}
                    onChange={(e) => setStrtDate(e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 col-form-label">
                <label className="">End Date</label>
              </div>
              <div className="col-md-8 padding_0">
                <div className="input-group date">
                  <input
                    type="date"
                    id="endDate"
                    name="endDate"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 col-form-label">
                <label className="">Holiday Occasion </label>
              </div>
              <div className="col-md-8 padding_0">
                <div className="input-group date">
                  <input
                    type="text"
                    id="holioccas"
                    name="holioccas"
                    value={holioccas}
                    onChange={(e) => setHolioccas(e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 col-form-label">
                <label className="">Holiday Remark</label>
              </div>
              <div className="col-md-8 padding_0">
                <div className="input-group date">
                  <input
                    type="text"
                    id="holiremark"
                    name="holiremark"
                    value={holiremark}
                    onChange={(e) => setHoliremark(e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row">
              <div className="col-md-2 col-form-label">
                <label className="">Holiday type</label>
              </div>
              <div className="col-md-10 padding_0">
                <div className="input-group date padding_0">
                  <input
                    type="text"
                    id="holitype"
                    name="holitype"
                    value={holitype}
                    onChange={(e) => setHolitype(e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-12">
            <button
              className="btn btn-info"
              style={{
                width: "100%",
                fontSize: "24px",
                margin: "30px 0px 15px 0px",
              }}
              onClick={regSubmit}
            >
              {" "}
              Submit Holiday{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHoliday;
