import React, { useState, useEffect } from "react";
import MyLoader from "../Spinner/MyLoader";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import SessionManager from "../Utils/SessionManager";
import { FaUser, FaChalkboardTeacher, FaClipboardList } from "react-icons/fa";
import boyImage from "../img/boy.jpg";
import MdlAddParticulars from "../dialog/MdlAddParticulars";

const IssueAccessories = () => {
  const [loggedIn, setLoggedIn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [ShowMdl, setShowMdl] = useState(false);
  useEffect(() => {
    const checkLoginStatus = async () => {
      // Check login status logic here
    };
    checkLoginStatus();
  }, []);

  const handleShow1 = () => {
    alert("open");
    setShowMdl(true);
  };

  if (loading) {
    return <MyLoader />;
  }

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  return (
    <Router>
      <Switch>
        <Route
          path="/AdminMain/IssueAccessories"
          exact
          render={() => (
            <div className="content">
              <div className="content-header">
                <div className="leftside-content-header">
                  <ul className="breadcrumbs">
                    <li>
                      <i className="fa fa-home" aria-hidden="true" />
                      <Link to="#"> Issue Accessories</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="bg_page_grey_rounded box_shadow"
                style={{ "--mMargin": "50px" }}
              >
                <div className="IssueAccessories-container">
                  <div className="IssueAccessories-header-section">
                    <button className="IssueAccessories-select-student-button">
                      Select Student{" "}
                      <span className="IssueAccessories-arrow"> &gt; </span>{" "}
                    </button>
                  </div>

                  <div className="IssueAccessories-student-info">
                    <div className="IssueAccessories-profile-pic">
                      <img src={boyImage} alt="staff" />
                    </div>
                    <div className="IssueAccessories-student-details">
                      <h2>AMRITA PANDEY</h2>
                      <p>D/O: OMPRAKASH</p>
                    </div>
                    <div className="IssueAccessories-roll-info">
                      <p>Roll No</p>
                      <div className="IssueAccessories-roll-number">1</div>
                      <p>Play Group: A</p>
                    </div>
                  </div>

                  <button
                    className="IssueAccessories-add-particulars-button"
                    onClick={handleShow1}
                  >
                    Add Particulars{" "}
                    <span className="IssueAccessories-arrow"> &gt; </span>
                  </button>
                  {ShowMdl === true ? (
                    <MdlAddParticulars></MdlAddParticulars>
                  ) : (
                    ""
                  )}

                  <div className="IssueAccessories-accessories-added">
                    Accessories Already Added
                  </div>

                  <div className="IssueAccessories-table-container">
                    <div className="IssueAccessories-date-section">
                      Date: 2024-11-09
                    </div>
                    <div className="IssueAccessories-table">
                      <div className="IssueAccessories-table-header">
                        Particular
                      </div>
                      <div className="IssueAccessories-table-header">Rate</div>
                      <div className="IssueAccessories-table-header">Qty</div>
                      <div className="IssueAccessories-table-header">
                        Amount
                      </div>

                      <div className="IssueAccessories-table-cell">adm kit</div>
                      <div className="IssueAccessories-table-cell">
                        Rs: 4500
                      </div>
                      <div className="IssueAccessories-table-cell">1</div>
                      <div className="IssueAccessories-table-cell">
                        Rs: 4500
                      </div>
                    </div>

                    <div className="IssueAccessories-payment-summary">
                      <div className="IssueAccessories-summary-item">
                        Amount ₹4500
                      </div>
                      <div className="IssueAccessories-summary-item">
                        Discount ₹0
                      </div>
                      <div className="IssueAccessories-summary-item">
                        Payable ₹4500
                      </div>
                      <div className="IssueAccessories-summary-item IssueAccessories-paid">
                        paid ₹0
                      </div>
                      <div className="IssueAccessories-summary-item IssueAccessories-due">
                        Due ₹4500
                      </div>
                    </div>
                  </div>
                  <button className="IssueAccessories-submit-button">
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          )}
        />
      </Switch>
    </Router>
  );
};

export default IssueAccessories;
