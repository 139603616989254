import React, { useState, useEffect } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import { Redirect } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import myToast from "../Utils/MyToast";
import "../css/Admin_css/admin-customize.css";

const MdlAddSection = ({ actionClose, action2 }) => {
  const token = localStorage.getItem("token");
  const [loggedIn, setLoggedIn] = useState(token !== null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [mSessionList, setSectionMasterList] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");

  const handleClose = () => {
    setShow(false);
    actionClose();
  };

  useEffect(() => {
    getSectionMasterList();
  }, []);

  const getSectionMasterList = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getSectionMasterList.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    axios
      .get(URL, {
        params: { school_code: SCHOOL_CODE },
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);

        if (response.api_status === "OK") {
          setSectionMasterList(response.data);
          //alert(response.data)
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const handleSubmit = () => {
    if (!selectedSection) {
      myToast.notify_warning("Please select a section");
      return;
    }
    action2(selectedSection);
    handleClose();
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Select Section
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <MyLoader />
        ) : (
          <div className="ASClassSettings">
            <div className="label-container">
              <label>Add Section</label>
            </div>
            <div className="dropdown-container">
              <div className="input-group">
                <select
                  value={selectedSection}
                  onChange={(e) => setSelectedSection(e.target.value)}
                >
                  <option value="">Select Section</option>
                  {mSessionList.map((section) => (
                    <option
                      key={section.section_id}
                      value={section.section_name}
                    >
                      {section.section_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" size="lg" onClick={handleSubmit}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MdlAddSection;
