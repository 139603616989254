import React, { useState, useEffect } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import COLORS from "../resources/colors/COLORS";
import SessionManager from "../Utils/SessionManager";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import SelectClass from "../ChildComponent/SelectClass";
import boyImage from "../img/boy.jpg";

const StudentLogin = () => {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("token") !== null
  );
  const [Loading, setLoading] = useState(false);
  const [LoadClass, setLoadClass] = useState(false);
  const [SESSION_ID, setSESSION_ID] = useState("");
  const [CLASS_ID, setCLASS_ID] = useState("");
  const [SECTION_ID, setSECTION_ID] = useState("");
  const [SELECTED_CLASS_POSITION, setSELECTED_CLASS_POSITION] = useState(0);
  const [CLASS_SECTION_NAME, setCLASS_SECTION_NAME] = useState("");
  const [mStudentList, setMStudentList] = useState([]);
  const [Inputs, setInputs] = useState([]);

  useEffect(() => {
    setLoadClass(true);
  }, []);

  useEffect(() => {
    StudentList();
  }, [CLASS_ID, SECTION_ID]);

  const toggleDisableRollNo = (index, state) => {
    setInputs((prev) =>
      prev.map((input, i) =>
        i === index
          ? { ...input, disable_roll_no: !input.disable_roll_no }
          : input
      )
    );

    if (!state) {
      const ROLL_NUMBER_OLD = mStudentList[index].student_roll_no;
      const STUDENT_ID = mStudentList[index].student_enrollment_no;

      // Using the updated state (prev) instead of Inputs directly
      setInputs((prev) => {
        const ROLL_NUMBER_NEW = prev[index].student_roll_no;
        // Uncomment this to call the update function
        updateStudentRollNumber(STUDENT_ID, ROLL_NUMBER_OLD, ROLL_NUMBER_NEW);
        return prev;
      });
    }
  };

  const toggleDisableAdmNo = (index, state) => {
    setInputs((prev) =>
      prev.map((input, i) =>
        i === index
          ? { ...input, disable_adm_no: !input.disable_adm_no }
          : input
      )
    );

    if (!state) {
      const ADM_NO_OLD = mStudentList[index].sr_admission_no;
      const STUDENT_ID = mStudentList[index].student_enrollment_no;
      const ADM_NO_NEW = Inputs[index].sr_admission_no;
      updateStudentAdmissionNumber(STUDENT_ID, ADM_NO_OLD, ADM_NO_NEW);
    }
  };
  const handleInputChange = (id, my_key, event) => {
    //alert(JSON.stringify(id));
    const newInputs = Inputs.map((input) => {
      if (input.id === id) {
        return { ...input, [my_key]: event.target.value };
      }
      return input;
    });
    setInputs(newInputs);
  };

  const CallBackClassSelect = (
    class_id,
    section_id,
    session_id,
    selected_position,
    class_name,
    section_name
  ) => {
    const classSectionName =
      section_id === "No" ? class_name : class_name + ":" + section_name;

    setSECTION_ID(section_id);
    setSESSION_ID(session_id);
    setCLASS_SECTION_NAME(classSectionName);
    setSELECTED_CLASS_POSITION(selected_position);
    setCLASS_ID(class_id);
  };

  const StudentList = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getCurrentStudent.php?";
    // const URL = ${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME};
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          class_id: CLASS_ID,
          section_id: SECTION_ID,
          session_id: SESSION_ID,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        // alert(JSON.stringify(response));
        if (response.api_status === "OK") {
          setMStudentList(response.data);
          const formattedData = response.data.map((item) => ({
            id: item.id,
            student_roll_no: item.student_roll_no,
            sr_admission_no: item.sr_admission_no,
            disable_roll_no: true,
            disable_adm_no: true,
          }));
          setInputs(formattedData);
        } else {
          // myToast.notify_warning(response.api_remark);
          setMStudentList([]);
        }
      })
      .catch((error) => {
        console.log("error>>>>>" + error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateStudentRollNumber = (
    STUDENT_ID,
    ROLL_NUMBER_OLD,
    ROLL_NUMBER_NEW
  ) => {
    setLoading(true);

    var mStaffData = SessionManager.getStaffDetails();
    var STAFF_ID = mStaffData.staff_id;

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "updateStudentRollNumber.php?";
    //const URL = ${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME};
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    axios
      .get(URL, {
        params: {
          staff_id: STAFF_ID,
          school_code: SCHOOL_CODE,
          class_id: CLASS_ID,
          section_id: SECTION_ID,
          session_id: SESSION_ID,
          student_id: STUDENT_ID,
          student_roll_no: ROLL_NUMBER_NEW,
          student_roll_no_old: ROLL_NUMBER_OLD,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        // alert(JSON.stringify(response));
        if (response.api_status === "OK") {
          alert("Roll Number updated successfully");
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        console.log("error>>>>>" + error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateStudentAdmissionNumber = (
    STUDENT_ID,
    ADM_NUMBER_OLD,
    ADM_NUMBER_NEW
  ) => {
    setLoading(true);

    var mStaffData = SessionManager.getStaffDetails();
    var STAFF_ID = mStaffData.staff_id;

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "updateStudentAdmissionNo.php?";
    //const URL = ${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME};
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    axios
      .get(URL, {
        params: {
          staff_id: STAFF_ID,
          school_code: SCHOOL_CODE,
          session_id: SESSION_ID,
          enrollment_no: STUDENT_ID,
          admission_no: ADM_NUMBER_NEW,
          admission_no_old: ADM_NUMBER_OLD,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        // alert(JSON.stringify(response));
        if (response.api_status === "OK") {
          alert("Admission Number updated successfully");
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        console.log("error>>>>>" + error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  return (
    <Router>
      <Route
        path="/AdminMain/StudentLogin"
        exact
        render={() => (
          <div className="content" style={{ background: COLORS.whitesmoke }}>
            <div
              className="content-header"
              style={{ background: COLORS.primary_color_shade6 }}
            >
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li>
                    <i className="fa fa-home" aria-hidden="true" />
                    <a href="#"> StudentLogin </a>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="animated fadeInUp bg_page_white_rounded box_shadow"
              style={{
                "--mMargin": "50px",
                marginTop: "20px",
                padding: "20px",
              }}
            >
              {LoadClass && (
                <SelectClass
                  value={LoadClass}
                  action={CallBackClassSelect}
                  selected_position={SELECTED_CLASS_POSITION}
                />
              )}

              {Loading ? (
                <MyLoader />
              ) : Inputs.length > 0 ? (
                <div className="StudentLoginNew_container">
                  {mStudentList.map((item, index) => (
                    <div key={index} style={{ width: "100%" }}>
                      <div className="Sln_container">
                        <div className="Sln_box">
                          <div className="Sln_mainRow">
                            <div className="Sln_imageWrapper">
                              <img
                                src={boyImage}
                                alt="Student"
                                className="Sln_image"
                              />
                            </div>

                            <p className="Sln_detail">
                              <span className="Sln_label">Student:</span>{" "}
                              {item.stu_name}
                            </p>
                            <p className="Sln_detail">
                              <span className="Sln_label">Father:</span>{" "}
                              {item.father_name}
                            </p>
                            <p className="Sln_detail">
                              <span className="Sln_label">Village:</span>{" "}
                              {item.village_town}
                            </p>
                            <p className="Sln_detail">
                              <span className="Sln_label">Class:</span>{" "}
                              {item.class_name}
                            </p>

                            <div className="Sln_rollBox">
                              <p className="Sln_label">Roll No.</p>
                              <div className="Sln_roll">
                                {item.student_roll_no}
                              </div>
                            </div>
                          </div>
                          <div className="Sln_additionalInfo">
                            <h6 className="Sln_label">
                              App Installed | Logged In
                            </h6>
                            <p>Found in device: 1 (Logout Included)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>No students found</div>
              )}
            </div>
          </div>
        )}
      />
    </Router>
  );
};

export default StudentLogin;
