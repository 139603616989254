import React, { Component } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import COLORS from "../resources/colors/COLORS";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import Iframe from "react-iframe";
import SelectClass from "../ChildComponent/SelectClass";
import SelectAllClass from "../ChildComponent/SelectAllClass";
import uploadIcon from "../resources/images/upload-icon.png";
import MdlSelectSession from "../dialog/MdlSelectSession";
export default class MarkEntryForm extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      showPreview: false,
      iFrameHeight: "0px",
      FILE_URL: "",
      FILE_NAME: "",
      FILE_DIRECTORY: "",
      DOCUMENT_TYPE: "",
      mPrintableSamples: [],
      SELECTED_SAMPLE_ID: "",
      SELECTED_SAMPLE_POSITION: 0,
      CLASS_SECTION_NAME: "",

      isOpenSessionDialog: false,
      mExamList: [],
      CLASS_ID: "",
      SECTION_ID: "",
      EXAM_ID: "",
      EXAM_NAME: "",
      SESSION_ID: "",
      SESSION_NAME: "",
      SESSION_STATUS: "",
      EXAM_SELECTED_POSITION: 0,
      mClassList: [],
      selectedClassIndex: -1,
      SUJECT_SELECTED_POSITION: -1,
      selected_subject_id: "",
      selected_subject_name: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.state.DOCUMENT_TYPE = this.props.match.params.document_type;

    var mSession = SessionManager.getCurrentSession();
    this.state.SESSION_ID = mSession[0].session_id;
    this.state.SESSION_NAME = mSession[0].session_name;
    this.state.SESSION_STATUS = mSession[0].status;

    this.getExamList();
  }
  getExamList() {
    this.setState({
      Loading: true,
    });
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getExamListLite.php?";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    const params = {
      school_code: SCHOOL_CODE,
      session_id: this.state.SESSION_ID,
    };
    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);

    axios
      .get(URL, {
        params: params,
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          Loading: false,
        });
        // console.log("Response2>>>>>"+response);
        let api_status = response.api_status;
        //alert(JSON.stringify(response));

        if (api_status == "OK") {
          this.setState({
            mExamList: response.data,
          });
          if (this.state.mExamList.length !== 0) {
            this.state.EXAM_ID =
              this.state.mExamList[this.state.EXAM_SELECTED_POSITION].exam_id;
            this.state.EXAM_NAME =
              this.state.mExamList[this.state.EXAM_SELECTED_POSITION].exam_name;
            this.getClassWithSubjectsPartAndPaper();
          }

          //console.log("response>>>>>"+this.state.mClassData);
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        console.log("error>>>>>" + error);
      });
  }
  getClassWithSubjectsPartAndPaper() {
    this.setState({
      Loading: true,
    });

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getClassWithSubjectsPartAndPaper.php?";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;

    // console.log("URL>>>>>"+URL);
    const params = {
      school_code: SCHOOL_CODE,
      exam_id: this.state.EXAM_ID,
      session_id: this.state.SESSION_ID,
    };

    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);

    axios
      .get(URL, {
        params: params,
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          Loading: false,
        });
        // console.log("Response2>>>>>"+response);
        let api_status = response.api_status;
        //alert(JSON.stringify(response));

        if (api_status == "OK") {
          this.setState({
            mClassList: response.data,
          });

          this.selectClass(this.state.selectedClassIndex);
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        //console.log("error>>>>>"+error);
      });
  }

  ChangeSession = () => {
    this.HandleShowSessionDialog();
  };

  HandleShowSessionDialog = () => {
    this.setState({
      isOpenSessionDialog: !this.state.isOpenSessionDialog,
    });
  };

  HandleCloseSessionDialog = () => {
    this.setState({
      isOpenSessionDialog: false,
    });
  };
  HandleSelectedSession = (session_id, session_name, session_status) => {
    this.state.SESSION_ID = session_id;
    this.state.SESSION_NAME = session_name;
    this.state.SESSION_STATUS = session_status;
    this.setState({
      isOpenSessionDialog: false,
    });

    this.getExamList();
  };

  HandleClose = () => {
    this.setState({
      show: !this.state.show,
    });
    this.props.action_close();
  };
  SelectExam = (exam_id, exam_name, position) => {
    //alert("okk"+student_id);

    this.setState({
      EXAM_ID: exam_id,
      EXAM_NAME: exam_name,
      EXAM_SELECTED_POSITION: position,
    });
    this.getClassWithSubjectsPartAndPaper();
  };
  HandleSelectedExam = () => {
    //alert("okk"+student_id);
    this.setState({
      show: !this.state.show,
    });
    this.props.action_select(this.state.EXAM_ID, this.state.EXAM_NAME);
  };
  selectClass = (position) => {
    if (position >= 0) {
      const selectedClass = this.state.mClassList[position];
      this.state.CLASS_ID = selectedClass.class_id;
      this.state.SECTION_ID = selectedClass.section_id;
      if (this.state.SECTION_ID == "null") {
        this.state.SECTION_ID = "No";
      }

      this.state.selectedClassIndex = position;
      // alert("Back" + class_id + "/" + section_id + "/" + session_id);
      if (selectedClass.section_id == "No") {
        this.state.CLASS_SECTION_NAME = selectedClass.section_name;
      } else {
        this.state.CLASS_SECTION_NAME =
          selectedClass.class_name + ":" + selectedClass.section_name;
      }

      this.getSubject(this.state.SUJECT_SELECTED_POSITION);
    }
  };

  getSubject(selected_position_subject) {
    this.state.mSubject = [];
    this.state.mSubject =
      this.state.mClassList[this.state.selectedClassIndex].subject;

    if (selected_position_subject >= 0) {
      this.state.SUJECT_SELECTED_POSITION = selected_position_subject;
      if (this.state.mSubject !== null && this.state.mSubject.length !== 0) {
        this.state.selected_subject_id =
          this.state.mSubject[selected_position_subject].subject_id;
        this.state.selected_subject_name =
          this.state.mSubject[selected_position_subject].subject_name;
        if (this.state.mPrintableSamples.length == 0) {
          this.getPrintableSamples();
        } else {
          this.setFileUrL();
        }
      } else {
        this.setState({
          mSubject: [],
        });
      }
    } else {
      this.setState({});
    }
  }

  getPrintableSamples() {
    this.setState({
      Loading: true,
    });

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getPrintableSample.php?";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    // alert(URL);
    //console.log("URL>>>>>"+URL);

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          document_type: this.state.DOCUMENT_TYPE,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          Loading: false,
        });
        //console.log("Response2>>>>>"+response);
        let api_status = response.api_status;
        //alert(JSON.stringify(response));

        if (api_status == "OK") {
          this.setState({
            mPrintableSamples: response.data,
          });
          //alert("mPrintableSamples>>>>>"+JSON.stringify(this.state.mPrintableSamples));
          this.setSample();
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        // console.log("error>>>>>"+error);
      });
  }
  setSample() {
    var sample_id = this.state.mPrintableSamples[0].sample_id;
    this.state.FILE_NAME = this.state.mPrintableSamples[0].file_name;
    this.state.FILE_DIRECTORY = this.state.mPrintableSamples[0].file_directory;
    this.state.SELECTED_SAMPLE_POSITION = 0;
    this.state.SELECTED_SAMPLE_ID = sample_id;
    this.setFileUrL();
  }

  setFileUrL() {
    const SCHOOL_CODE = SessionManager.getSchoolCode();

    this.state.FILE_URL =
      process.env.REACT_APP_SERVER_NAME +
      this.state.FILE_DIRECTORY +
      this.state.FILE_NAME +
      "?&class_id=" +
      this.state.CLASS_ID +
      "&section_id=" +
      this.state.SECTION_ID +
      "&class_section_name=" +
      this.state.CLASS_SECTION_NAME +
      "&session_id=" +
      this.state.SESSION_ID +
      "&exam_id=" +
      this.state.EXAM_ID +
      "&exam_name=" +
      this.state.EXAM_NAME +
      "&subject_id=" +
      this.state.selected_subject_id +
      "&subject_name=" +
      this.state.selected_subject_name +
      "&sample_id=" +
      this.state.SELECTED_SAMPLE_ID +
      "&system_code=" +
      process.env.REACT_APP_SYSTEM_CODE +
      "&school_code=" +
      SCHOOL_CODE;
    console.log("FILE_URL", this.state.FILE_URL);
    //alert(this.state.FILE_URL);
    this.setState({
      showPreview: true,
    });
  }

  handleChange = (event) => {
    const value = event.target.value;
    this.setState(
      {
        selectedOption: value,
      },
      () => {
        this.setFileUrL();
      }
    );
  };
  autoResize = () => {
    //alert("loaded");
    var iFrame = document.getElementById("iFrame1");

    iFrame.width = iFrame.contentWindow.document.body.scrollWidth;
    iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
  };

  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to="/Login" />;
    }
    if (this.state.Loading) {
      return <MyLoader />;
    } else {
      return (
        <div className="content">
          <div
            className="content-header"
            style={{ background: COLORS.primary_color_shade5 }}
          >
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li>
                  <i
                    className="fa fa-home"
                    aria-hidden="true"
                    style={{ color: COLORS.white }}
                  />
                  <a href="#">Mark Entry Form </a>
                </li>
              </ul>
            </div>
          </div>

          <div
            className="animated fadeInUpn bg_page_grey_rounded box_shadow"
            style={{ "--mMargin": "50px" }}
          >
            <div className="row bg_card_primary">
              <div className="col-sm-12 col-md-12">
                <Link to="#" onClick={() => this.ChangeSession()}>
                  <p
                    style={{
                      padding: "1px 5px 1px 5px",
                      fontSize: "18px",
                      textAlign: "center",
                      color: COLORS.white,
                    }}
                  >
                    {" "}
                    Session:
                    {this.state.SESSION_NAME +
                      "(" +
                      this.state.SESSION_STATUS +
                      ")"}
                    <span style={{ float: "right" }}>
                      {" "}
                      <img
                        src={uploadIcon}
                        alt="upload image"
                        className="bg_card_blue"
                        style={{ height: "25px", width: "25px" }}
                      />{" "}
                    </span>{" "}
                  </p>
                </Link>
              </div>
            </div>

            {this.state.isOpenSessionDialog === true ? (
              <MdlSelectSession
                action={this.HandleCloseSessionDialog}
                action2={this.HandleSelectedSession}
              ></MdlSelectSession>
            ) : (
              ""
            )}

            <div
              className="row bg_card_primary"
              style={{ padding: "0px 15px 0px 15px" }}
            >
              {this.state.mExamList.map((item, index) => (
                <div
                  className="col-sm-2 col-md-2"
                  style={{ padding: "0px 2px 5px 2px" }}
                >
                  <div
                    className={
                      this.state.EXAM_SELECTED_POSITION === index
                        ? "bg_class_select gravity_center"
                        : "bg_class_deselect gravity_center"
                    }
                    onClick={(e) =>
                      this.SelectExam(item.exam_id, item.exam_name, index)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <p style={{ fontSize: 14 }}> {item.exam_name} </p>
                  </div>
                </div>
              ))}
            </div>

            <div style={{ padding: "0px" }}>
              <h5>Participating Class</h5>
            </div>
            <div
              className="row bg_card_primary"
              style={{ padding: "5px", margin: "0px" }}
            >
              {this.state.mClassList &&
                this.state.mClassList.map((item, index) => (
                  <div
                    className="col-sm-1 col-md-1"
                    style={{ padding: "0px 2px 5px 2px" }}
                  >
                    <div
                      className={
                        this.state.selectedClassIndex === index
                          ? "bg_class_select gravity_center"
                          : "bg_class_deselect gravity_center"
                      }
                      onClick={(e) => this.selectClass(index)}
                      style={{ cursor: "pointer" }}
                    >
                      <p style={{ fontSize: 14 }}>
                        {" "}
                        {item.section_id === "No"
                          ? item.class_name
                          : item.class_name + ":" + item.section_name}{" "}
                      </p>
                    </div>
                  </div>
                ))}
            </div>

            <div
              className="bg_card_blue_outline"
              style={{ margin: "10px 0px 5px 0px" }}
            >
              <div style={{ padding: "5px" }}>
                <h5>Participating Subject</h5>
              </div>
              <div className="row " style={{ padding: "0px 5px 5px 5px" }}>
                {this.state.mSubject &&
                  this.state.mSubject.map((item2, index2) => (
                    <div
                      className="col-sm-2 col-md-2"
                      style={{ padding: "0px 2px 5px 2px" }}
                    >
                      <div
                        className={
                          this.state.SUJECT_SELECTED_POSITION === index2
                            ? "bg_class_select gravity_center"
                            : "bg_white_outline_grey gravity_center"
                        }
                        onClick={(e) => this.getSubject(index2)}
                        style={{ cursor: "pointer" }}
                      >
                        <p style={{ fontSize: 18 }}> {item2.subject_name} </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="row" style={{ margin: "0px 10px 0px 10px" }}>
              <h5 className="">Preview</h5>
            </div>

            <div className="row" style={{ margin: "0px 10px 0px 10px" }}>
              {this.state.showPreview ? (
                <div>
                  <MyLoader />
                </div>
              ) : (
                ""
              )}
              <Iframe
                url={this.state.FILE_URL}
                width={this.state.showPreview ? "0%" : "100%"}
                height={this.state.showPreview ? "0px" : "800px"}
                overflow="auto"
                id="iFrame1"
                className=""
                display="initial"
                position="relative"
                onLoad={() => {
                  //this.autoResize();

                  this.setState({
                    showPreview: false,
                  });
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  }
}
