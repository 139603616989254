import React, { useState, useEffect } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import { Link, Redirect } from "react-router-dom";
import { Modal } from "react-bootstrap";
import "../css/Admin_css/admin-customize.css";
import COLORS from "../resources/colors/COLORS";
import myToast from "../Utils/MyToast";
import boyImage from "../img/boy.jpg";

const MdlSelectExamClassAndStudent = ({
  exam_id,
  session_id,
  action,
  actionSelect,
}) => {
  const [loggedIn, setLoggedIn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingStudent, setLoadingStudent] = useState(false);
  const [show, setShow] = useState(true);
  const [mExamParticipatingClass, setMExamParticipatingClass] = useState([]);
  const [classId, setClassId] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [studentList, setStudentList] = useState([]);
  const [classSelectedPosition, setClassSelectedPosition] = useState(0);

  useEffect(() => {
    //alert(JSON.stringify(exam_id + session_id))
    if (!localStorage.getItem("token")) {
      setLoggedIn(false);
    } else {
      getClass();
    }
  }, []);

  useEffect(() => {
    getStudentList();
  }, [classId, sectionId]);

  const handleClose = () => {
    setShow(false);
    action();
  };

  const handleSelectedStudent = (student_id, selected_item) => {
    actionSelect(student_id, selected_item);
  };

  const getClass = async () => {
    try {
      setLoading(true);
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      const FUNCTION_NAME = "getExamParticipatingClass.php";
      const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

      const params = {
        school_code: SCHOOL_CODE,
        session_id,
        exam_id,
      };
      const queryString = new URLSearchParams(params).toString();
      //console.log("Full URL: ", `${URL}${queryString}`);
      const response = await axios.get(URL, {
        params: params,
      });

      if (response.data.api_status === "OK") {
        //alert(JSON.stringify(response.data.data));
        setMExamParticipatingClass(response.data.data);
        setClassList(0);
      } else {
        myToast.notify_warning(
          response.data.api_remark ||
            "Error fetching classes. Please try again."
        );
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
      myToast.notify_error("An error occurred while fetching classes.");
    } finally {
      setLoading(false);
    }
  };

  const setClassList = (selectedPosition) => {
    if (mExamParticipatingClass.length > 0) {
      const selectedClass = mExamParticipatingClass[selectedPosition];
      setClassId(selectedClass.class_id);
      setSectionId(
        selectedClass.section_id === "null" ? "No" : selectedClass.section_id
      );
      setClassSelectedPosition(selectedPosition);
    }
  };

  const getStudentList = async () => {
    try {
      setLoadingStudent(true);
      setStudentList([]);
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      const FUNCTION_NAME = "getCurrentStudent.php";
      const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;
      const params = {
        school_code: SCHOOL_CODE,
        class_id: classId,
        section_id: sectionId,
        session_id,
      };

      const queryString = new URLSearchParams(params).toString();
      console.log("Full URL: ", `${URL}${queryString}`);
      const response = await axios.get(URL, {
        params: params,
      });
      if (response.data.api_status === "OK") {
        setStudentList(response.data.data);
        //alert(JSON.stringify(response.data.data));
      } else {
        myToast.notify_warning(response.data.api_remark);
      }
    } catch (error) {
      console.error("Error fetching students:", error);
      myToast.notify_error("An error occurred while fetching students.");
    } finally {
      setLoadingStudent(false);
    }
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <MyLoader />
          ) : (
            <div className="row class-li">
              <div className="row bg_card_primary" style={{ padding: "5px" }}>
                {mExamParticipatingClass.map((item, index) => (
                  <div className="col-sm-2" key={index}>
                    <div
                      className={
                        classSelectedPosition === index
                          ? "bg_class_select gravity_center"
                          : "bg_class_deselect gravity_center"
                      }
                      onClick={() => setClassList(index)}
                      style={{ cursor: "pointer" }}
                    >
                      <p>
                        {item.section_id === "No"
                          ? item.class_name
                          : `${item.class_name}:${item.section_name}`}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              {loadingStudent ? (
                <MyLoader />
              ) : (
                studentList.map((item, index) => (
                  <Link
                    to="#"
                    onClick={() =>
                      handleSelectedStudent(item.student_enrollment_no, item)
                    }
                  >
                    <div
                      className="SettlementNew-border d-flex align-items-center p-3 mt-3"
                      key={index}
                    >
                      <img
                        src={boyImage}
                        alt="user"
                        className="rounded-circle"
                      />
                      <div className="ml-3 flex-grow-1">
                        <h5>
                          {item.stu_name} S/D/O {item.father_name}
                        </h5>
                        <p>{item.village_town}</p>
                      </div>
                      <div className="SettlementNewroll text-center mx-3">
                        <p className="mb-1">
                          <strong>Roll No:</strong> {item.student_roll_no}
                        </p>
                        <p>
                          <strong>Class:</strong> {item.class_name}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MdlSelectExamClassAndStudent;
