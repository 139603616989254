import React, { Component } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
export default class HelpDesk extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      mHelpdesk: [],
    };
  }
  componentDidMount() {
    this.getContactUsList();
  }
  getContactUsList() {
    this.setState({
      Loading: true,
    });
    // const SCHOOL_CODE=SessionManager.getSchoolCode();
    // const Sess="SSN0001";
    // const Lim="30";
    // const Offset="0";
    const FUNCTION_NAME = "getContactUsList.php";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    console.log("URL>>>>>" + URL);
    axios
      .get(URL, {
        params: {},
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          Loading: false,
        });
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {
          this.setState({
            mHelpdesk: response.data,
          });
          console.log("response>>>>>" + JSON.stringify(this.state.mHelpdesk));
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        console.log("error>>>>>" + error);
      });
  }
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to="/Login" />;
    }
    if (this.state.Loading) {
      return <MyLoader />;
    } else {
      return (
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li>
                  <i className="fa fa-home" aria-hidden="true" />
                  <a href="#"> Help Desk </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="helpdesk-container">
            {this.state.mHelpdesk.map((item, index) => (
              <div className="helpdesk-card bg_card_orange" key={index}>
                <div className="helpdesk-card-content">
                  {/* Image Section */}
                  <div className="helpdesk-image-container">
                    <img
                      src="https://icons.iconarchive.com/icons/graphicloads/business/512/contact-icon.png"
                      alt="staff list image"
                      className="helpdesk-image"
                    />
                  </div>

                  {/* Information Section */}
                  <div className="helpdesk-info">
                    <h3 className="helpdesk-name">
                      {item.contact_name}{" "}
                      <span className="highlight">({item.designation})</span>
                    </h3>
                    <hr className="helpdesk-divider" />
                    <p className="helpdesk-languages">
                      <strong>{item.languages}</strong>
                    </p>

                    <div className="helpdesk-contact">
                      <div className="helpdesk-contact-item">
                        <i className="fas fa-phone-alt contact-icon"></i>
                        <span className="contact-number">
                          {item.contact_number}
                        </span>
                      </div>

                      <div className="helpdesk-contact-item">
                        <i className="fab fa-whatsapp contact-icon"></i>
                        <span className="contact-number">
                          {item.contact_number}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  }
}
