import React, { useEffect, useState } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import AddHoliday from "./AddHoliday";
import myToast from "../Utils/MyToast";
import COLORS from "../resources/colors/COLORS";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const Holiday = () => {
  const [loggedIn, setLoggedIn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [mHoliday, setMHoliday] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token === null) {
      setLoggedIn(false);
    } else {
      fetchHolidays();
    }
  }, []);

  const fetchHolidays = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const mSession = SessionManager.getCurrentSession();
    const SESSION_ID = mSession[0].session_id;

    const FUNCTION_NAME = "getHolidays.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    axios
      .get(URL, {
        params: { school_code: SCHOOL_CODE, session_id: SESSION_ID },
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        if (response.api_status === "OK") {
          setMHoliday(response.holidays);
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching holidays:", error);
      });
  };

  const handleDelete = (holiday_id) => {
    setLoading(true);

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const SESSION_ID = SessionManager.getCurrentSession()[0]?.session_id;
    const mStaffData = SessionManager.getStaffDetails();
    const STAFF_ID = mStaffData?.staff_id;

    console.log("Staff ID:", STAFF_ID); // ✅ Debugging
    if (!STAFF_ID) {
      myToast.notify_warning("Staff ID not found. Please re-login.");
      setLoading(false);
      return;
    }

    const FUNCTION_NAME = "deleteHolidays.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    console.log("Delete Request Sent To:", URL); // ✅ Debugging
    console.log("With Params:", {
      school_code: SCHOOL_CODE,
      session_id: SESSION_ID,
      staff_id: STAFF_ID,
      holiday_id: holiday_id,
    });

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          session_id: SESSION_ID,
          staff_id: STAFF_ID,
          holiday_id: holiday_id,
        },
      })
      .then((res) => {
        console.log("Delete API Response:", res.data); // ✅ Debugging
        return res.data;
      })
      .then((response) => {
        setLoading(false);

        if (response.api_status === "OK") {
          myToast.notify_success("Holiday deleted successfully");

          // ✅ स्टेट से भी डिलीट करें
          setMHoliday((prevHolidays) =>
            prevHolidays.filter((holiday) => holiday.holiday_id !== holiday_id)
          );
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error deleting holiday:", error);
        myToast.notify_error("Failed to delete holiday. Please try again.");
      });
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  if (loading) {
    return <MyLoader />;
  }

  return (
    <Router>
      <Route
        path="/AdminMain/Holiday/:module_id"
        exact
        render={() => (
          <div className="content">
            <div className="content-header">
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li>
                    <i className="fa fa-home" aria-hidden="true" />
                    <a href="#"> Add Holiday </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="animated fadeInUpn bg_page_greylite_rounded box_shadow"
              style={{ "--mMargin": "80px" }}
            >
              <div className="holiday-header">
                <h2 className="holiday-title">Holiday List</h2>
                <Link to={"/AdminMain/AddHoliday"}>
                  <button className="holiday-btn-add">Add Holiday</button>
                </Link>
              </div>
              <div className="holiday-card-container">
                {mHoliday.length > 0 ? (
                  mHoliday.map((item, index) => (
                    <div key={index} className="holiday-card">
                      <div className="holiday-card-content">
                        <div className="holiday-card-row">
                          <div className="holiday-card-item wide">
                            <strong>Announced Date:</strong> {item.date_added}
                          </div>
                          <div className="holiday-card-item">
                            <strong>Status:</strong> {item.status}
                          </div>
                          <div className="holiday-card-item">
                            <strong>From:</strong> {item.start_holiday_date}
                          </div>
                          <div className="holiday-card-item">
                            <strong>To:</strong> {item.end_holiday_date}
                          </div>
                        </div>
                        <div className="">
                          <strong className="holiday-card-header">
                            Holiday Occasion:
                          </strong>{" "}
                          {item.holiday_Occasion}
                        </div>
                        <div className="holiday-card-item remark">
                          <strong>Remark:</strong> {item.holiday_remark}
                        </div>
                        <button
                          className="delete-btn"
                          onClick={() => handleDelete(item.holiday_id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="holiday-no-data">No Holidays Found</div>
                )}
              </div>
            </div>
          </div>
        )}
      />
      <Route path="/AdminMain/AddHoliday" component={AddHoliday} />
    </Router>
  );
};

export default Holiday;
