import React, { useState, useEffect } from "react";
import MyLoader from "../Spinner/MyLoader";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import SessionManager from "../Utils/SessionManager";
import { Pagination } from "react-bootstrap";
import StudentLogin from "../cms/StudentLogin";
import StaffLogin from "../cms/StaffLogin";
import LoginLogs from "../cms/LoginLogs";
import { FaUser, FaChalkboardTeacher, FaClipboardList } from "react-icons/fa";

const AppUser = () => {
  const [loggedIn, setLoggedIn] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Example of fetching session status or other initialization logic
    const checkLoginStatus = async () => {
      // Session check or loading logic can go here
      // Example: setLoggedIn(SessionManager.isLoggedIn());
    };
    checkLoginStatus();
  }, []);

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  } else if (loading) {
    return <MyLoader />;
  } else {
    return (
      <Router>
        <Switch>
          <Route
            path="/AdminMain/AppUser/:module_id"
            exact
            render={() => (
              <div className="content">
                <div className="content-header">
                  <div className="leftside-content-header">
                    <ul className="breadcrumbs">
                      <li>
                        <i className="fa fa-home" aria-hidden="true" />
                        <a href="#"> App User </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="bg_page_grey_rounded box_shadow"
                  style={{ "--mMargin": "50px" }}
                >
                  <div className="login-boxes">
                    <Link to="/AdminMain/StudentLogin">
                      <div className="login-box">
                        <FaUser className="icon" />
                        <p>Student Login</p>
                      </div>
                    </Link>
                    <Link to="/AdminMain/StaffLogin">
                      <div className="login-box">
                        <FaChalkboardTeacher className="icon" />
                        <p>Staff Login</p>
                      </div>
                    </Link>
                    <Link to="/AdminMain/LoginLogs">
                      <div className="login-box">
                        <FaClipboardList className="icon" />
                        <p>Login Logs</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          />
          <Route path="/AdminMain/StudentLogin" component={StudentLogin} />
          <Route path="/AdminMain/StaffLogin" component={StaffLogin} />
          <Route path="/AdminMain/LoginLogs" component={LoginLogs} />
        </Switch>
      </Router>
    );
  }
};

export default AppUser;
