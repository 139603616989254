import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import myToast from "../Utils/MyToast";
import axios from "axios";
import OldDueCollection from "./OldDueCollection";
import uploadIcon from "../resources/images/upload-icon.png";
import MdlSelectSession from "../dialog/MdlSelectSession";
import SelectClass from "../ChildComponent/SelectClass";
import boyImage from "../img/boy.jpg";

const ViewSettlement = () => {
  const [LoadClass, setLoadClass] = useState(false);
  const [CLASS_SECTION_NAME, setCLASS_SECTION_NAME] = useState("");
  const [SESSION_ID, setSESSION_ID] = useState("");
  const [CLASS_ID, setCLASS_ID] = useState("");
  const [SECTION_ID, setSECTION_ID] = useState("");
  const token = localStorage.getItem("token");
  const [loggedIn, setLoggedIn] = useState(token !== null);
  const [loading, setLoading] = useState(false);
  const [sessionName, setSessionName] = useState("");
  const [isOpenSessionDialog, setIsOpenSessionDialog] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [settlementData, setSettlementData] = useState([]);
  const [settlement, setSettlement] = useState([]);
  const [SELECTED_CLASS_POSITION, setSELECTED_CLASS_POSITION] = useState(0);

  const CallBackClassSelect = (
    class_id,
    section_id,
    session_id,
    selected_position,
    class_name,
    section_name
  ) => {
    const classSectionName =
      section_id === "No" ? class_name : class_name + ":" + section_name;
    setSECTION_ID(section_id);
    setSESSION_ID(session_id);
    setCLASS_SECTION_NAME(classSectionName);
    setSELECTED_CLASS_POSITION(selected_position);
    setCLASS_ID(class_id);
  };

  useEffect(() => {
    setLoadClass(true);
    const mSession = SessionManager.getCurrentSession();
    setSessionId(mSession[0].session_id);
    setSessionName(mSession[0].session_name);
    window.scrollTo(0, 0);
    getFeeSettlementDashboard(mSession[0].session_id);
  }, []);

  const getFeeSettlementDashboard = (session_id) => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getFeeSettlementDashboard.php";
    const URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;

    const params = { school_code: SCHOOL_CODE, session_id: session_id };
    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);

    axios
      .get(URL, {
        params: params,
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        if (response.api_status === "OK") {
          setSettlement(response.dashboard.settlement_request_list);
          //alert(JSON.stringify(response.dashboard.settlement_request_list))
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching exam list:", error);
      });
  };

  const handleReject = async (item) => {
    setLoading(true);
    try {
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      var mStaffData = SessionManager.getStaffDetails();
      var STAFF_ID = mStaffData.staff_id;
      const FUNCTION_NAME = "updateSettlementRequest.php";
      const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

      const params = {
        school_code: SCHOOL_CODE,
        request_id: item.settlement_request_id,
        student_id: item.student_id,
        session_id: item.session_id,
        class_id: item.class_id,
        section_id: item.section_id,
        staff_id: STAFF_ID,
        fee_id: item.fee_id,
        applicable_month: item.applicable_month,
        amount: item.due_amount,
        settlement_status: "3",
      };

      const queryString = new URLSearchParams(params).toString();
      console.log("Full URL: ", `${URL}${queryString}`);
      const response = await axios.get(URL, {
        params: params,
      });

      if (response.data.api_status === "OK") {
        alert("Amount Rejected successfully!");
        window.location.reload();
      } else {
        myToast.notify_warning(
          response.data.api_remark || "Rename failed. Please try again."
        );
      }
    } catch (error) {
      console.error("Rename error:", error);
      myToast.notify_error("An error occurred while renaming the class.");
    } finally {
      setLoading(false);
    }
  };

  const handleSettle = async (item) => {
    setLoading(true);
    try {
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      var mStaffData = SessionManager.getStaffDetails();
      var STAFF_ID = mStaffData.staff_id;
      const FUNCTION_NAME = "updateSettlementRequest.php";
      const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

      const params = {
        school_code: SCHOOL_CODE,
        request_id: item.settlement_request_id,
        student_id: item.student_id,
        session_id: item.session_id,
        class_id: item.class_id,
        section_id: item.section_id,
        staff_id: STAFF_ID,
        fee_id: item.fee_id,
        applicable_month: item.applicable_month,
        amount: item.due_amount,
        settlement_status: "2",
      };

      const queryString = new URLSearchParams(params).toString();
      console.log("Full URL: ", `${URL}${queryString}`);
      const response = await axios.get(URL, {
        params: params,
      });

      if (response.data.api_status === "OK") {
        alert("Amount Settle successfully!");
        window.location.reload();
      } else {
        myToast.notify_warning(
          response.data.api_remark || "Rename failed. Please try again."
        );
      }
    } catch (error) {
      console.error("Rename error:", error);
      myToast.notify_error("An error occurred while renaming the class.");
    } finally {
      setLoading(false);
    }
  };

  const handleSessionDialogToggle = () => {
    setIsOpenSessionDialog((prev) => !prev);
  };

  const handleSelectedSession = (session_id, session_name) => {
    setSessionId(session_id);
    setSessionName(session_name);
    setIsOpenSessionDialog(false);
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  if (loading) {
    return <MyLoader />;
  }

  return (
    <Router>
      <Switch>
        <Route
          path="/AdminMain/ViewSettlement"
          render={() => (
            <div className="content">
              <div className="content-header">
                <div className="leftside-content-header">
                  <ul className="breadcrumbs">
                    <li>
                      <i className="fa fa-home" aria-hidden="true" />
                      <a href="#"> Settlement </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="animated fadeInUp bg_page_white_rounded box_shadow"
                style={{ "--mMargin": "50px" }}
              >
                <div className="SettlementNew">
                  {LoadClass && (
                    <SelectClass
                      value={LoadClass}
                      action={CallBackClassSelect}
                      selected_position={SELECTED_CLASS_POSITION}
                    />
                  )}
                  <div className="SettlementNew-card p-3 mt-4">
                    <h4 className="mb-3">Recent Settlement Request</h4>
                    <div className="d-flex justify-content-between">
                      <button
                        className={`SettlementNew-btn1 ${
                          activeTab === "1" ? "SettlementNew-btn-warning" : ""
                        }`}
                        onClick={() => setActiveTab("1")}
                      >
                        Pending
                      </button>
                      <button
                        className={`SettlementNew-btn1 ${
                          activeTab === "2" ? "SettlementNew-btn-primary" : ""
                        }`}
                        onClick={() => setActiveTab("2")}
                      >
                        Settled
                      </button>
                      <button
                        className={`SettlementNew-btn1 ${
                          activeTab === "3" ? "SettlementNew-btn-danger" : ""
                        }`}
                        onClick={() => setActiveTab("3")}
                      >
                        Rejected
                      </button>
                    </div>

                    {settlement
                      .filter((item) => item.settlement_status === activeTab)
                      .map((item) => (
                        <div
                          key={item.id}
                          className="SettlementNew-border d-flex align-items-center p-3 mt-3"
                        >
                          <img
                            src={
                              item.student_details[0]?.student_img_path ||
                              boyImage
                            }
                            alt="user"
                            className="rounded-circle"
                          />
                          <div className="ml-3 flex-grow-1">
                            <h5>
                              {item.student_details[0].stu_name} D/O{" "}
                              {item.student_details[0].father_name}
                            </h5>
                            <p>
                              Month: {item.applicable_month} - Amount: &#8377;{" "}
                              {item.due_amount}
                            </p>
                          </div>
                          <div className="SettlementNewroll text-center mx-3">
                            <p className="mb-1">
                              <strong>Roll No:</strong>{" "}
                              {
                                item.student_details[0]?.class[0]
                                  ?.student_roll_no
                              }
                            </p>
                            <p>
                              <strong>Class:</strong>{" "}
                              {item.student_details[0]?.class[0]?.class_name}
                            </p>
                          </div>
                          {activeTab === "1" && (
                            <>
                              <button
                                onClick={() => handleReject(item)}
                                className="SettlementNew-btn SettlementNew-btn-danger mx-2 p-2"
                              >
                                Reject
                              </button>
                              <button
                                onClick={() => handleSettle(item)}
                                className="SettlementNew-btn SettlementNew-btn-success p-2"
                              >
                                Settle
                              </button>
                            </>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        />
        <Route
          path="/AdminMain/OldDueCollection"
          component={OldDueCollection}
        />
      </Switch>
    </Router>
  );
};

export default ViewSettlement;
