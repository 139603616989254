import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import { Redirect } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";

const AddClassTeacher = ({ actionClose, selectedClass }) => {
  const [loggedIn] = useState(localStorage.getItem("token") !== null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [teacherList, setTeacherList] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [className, setClassName] = useState();

  useEffect(() => {
    if (selectedClass) {
      setClassName(selectedClass);
    }
    fetchClassTeacherList();
  }, []);

  const handleClose = () => {
    setShow(false);
    actionClose();
  };

  const fetchClassTeacherList = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    var mSession = SessionManager.getCurrentSession();
    var SESSION_ID = mSession[0].session_id;
    const FUNCTION_NAME = "getClassTeacherList.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;
    axios
      .get(URL, {
        params: { school_code: SCHOOL_CODE, session_id: SESSION_ID },
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);

        if (response.api_status === "OK") {
          setTeacherList(response.data);
          //alert(response.data)
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const handleClassTeacherSubmit = async () => {
    setLoading(true);
    try {
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      var mStaffData = SessionManager.getStaffDetails();
      var STAFF_ID = mStaffData.staff_id;
      const FUNCTION_NAME = "updateClassTeacher.php";
      const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

      const params = {
        school_code: SCHOOL_CODE,
        staff_id: STAFF_ID,
        section_id: selectedClass.section_id,
        class_id: selectedClass.class_id,
        session_id: selectedClass.session_id,
      };

      const queryString = new URLSearchParams(params).toString();
      console.log("Full URL: ", `${URL}${queryString}`);
      const response = await axios.get(URL, {
        params: params,
      });

      if (response.data.api_status === "OK") {
        myToast.notify_success("Class Teacher Update successfully!");
        //window.location.reload();
        actionClose();
      } else {
        myToast.notify_warning(
          response.data.api_remark || "Rename failed. Please try again."
        );
      }
    } catch (error) {
      console.error("Rename error:", error);
      myToast.notify_error("An error occurred while renaming the class.");
    } finally {
      setLoading(false);
    }
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      style={{ opacity: 1 }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Class Teacher
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <MyLoader />
        ) : (
          <div className="ACTClassSettings-container">
            <div className="ACTClassSettings-input-container">
              <div className="ACTClassSettings-class-info">
                <span className="ACTClassSettings-class-group">
                  {className
                    ? `${className.class_name}${
                        className.section_name
                          ? ` - ${className.section_name}`
                          : ""
                      }`
                    : ""}
                </span>
              </div>
              <div className="ACTClassSettings-input-group">
                <label className="ACTClassSettings-label">Class Teacher:</label>
                <select
                  required
                  value={selectedTeacher}
                  onChange={(e) => setSelectedTeacher(e.target.value)}
                  className="ACTClassSettings-input"
                >
                  <option value="">Select Class Teacher</option>
                  {teacherList.map((item, index) => (
                    <option key={index} value={item.staff_name}>
                      {item.staff_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          size="lg"
          onClick={handleClassTeacherSubmit}
          disabled={loading}
        >
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddClassTeacher;
