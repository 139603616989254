import React, { Component } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import UpdateStudentDetails from "./UpdateStudentDetails";
import Acknowledgement from "./Acknowledgement";
import myToast from "../Utils/MyToast";
import EditStudentDetails from "./EditStudentDetails";
export default class StudentDetails extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      mStudentDetails: [],
      mclass: [],
      mContactDetails: [],
      mFamilyDetails: [],
    };
  }
  componentDidMount() {
    this.StudentDetails();
  }
  ClstuDetails = () => {
    var arr = [];
    arr = this.state.mStudentDetails;
    localStorage.setItem("STDetails", JSON.stringify(arr));
    window.location.reload();
    window.location = "/AdminMain/UpdateStudentDetails";
  };

  StudentDetails() {
    this.setState({
      Loading: true,
    });
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    var mSession = SessionManager.getCurrentSession();
    const SESSION_ID = mSession[0].session_id;
    const STUDENT_ID = this.props.match.params.student_id;

    const Adhar_ID = "";
    const FUNCTION_NAME = "searchStudentDetails.php";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    console.log("URL>>>>>" + URL);
    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          enrollment_no: STUDENT_ID,
          session_id: SESSION_ID,
          aadhar_no: Adhar_ID,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          Loading: false,
        });
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        //alert(api_status);
        //alert(JSON.stringify(response.data));
        if (api_status == "OK") {
          this.setState({
            mStudentDetails: response.data,
            mclass: response.data[0].class,
            mContactDetails: response.data[0].contact_details,
            mFamilyDetails: response.data[0].family_details,
          });

          // console.log("response>>>>>" + this.state.mStudentDetails);
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        console.log("error>>>>>" + error);
      });
  }
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to="/Login" />;
    }
    if (this.state.Loading) {
      return <MyLoader />;
    } else {
      return (
        <Router>
          <switch>
            <Route
              path="/AdminMain/StudentDetails/"
              render={() => {
                return (
                  <div className="content">
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li>
                            <i className="fa fa-home" aria-hidden="true" />
                            <a href="#">Student Details</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="row animated fadeInUp">
                      <div className="">
                        <div className="SdDetails-panel">
                          <div className="SdDetails-panelContent">
                            {/* Header */}
                            <div className="SdDetails-header">
                              <div className="SdDetails-studentImage">
                                <img
                                  src={
                                    this.state.mStudentDetails.length !== 0
                                      ? this.state.mStudentDetails[0]
                                          .student_img_path
                                      : ""
                                  }
                                  alt="student"
                                />
                              </div>
                              <div className="SdDetails-studentName">
                                <h3>
                                  {this.state.mStudentDetails.length !== 0
                                    ? this.state.mStudentDetails[0].stu_name
                                    : ""}{" "}
                                  S/D/O{" "}
                                  {this.state.mFamilyDetails.length !== 0
                                    ? this.state.mFamilyDetails[0].father_name
                                    : ""}
                                </h3>
                                <p>
                                  {this.state.mclass.length !== 0
                                    ? this.state.mclass[0].class_name
                                    : ""}
                                </p>
                              </div>
                              <div className="SdDetails-edit">
                                <div
                                  className="SdDetails-editButton"
                                  onClick={this.ClstuDetails}
                                  style={{ cursor: "pointer" }}
                                >
                                  {/* Edit icon replacing image */}
                                  <i className="SdDetails-editIcon fas fa-edit"></i>
                                </div>
                                <div className="SdDetails-editLabel">
                                  <h3>Edit Information</h3>
                                </div>
                              </div>
                            </div>

                            {/* Basic Information Section */}
                            <div className="SdDetails-section">
                              <h2>Basic Information</h2>
                              <div className="SdDetails-infoRow">
                                <div className="SdDetails-infoItem">
                                  <label>Student Name</label>
                                  <h3>
                                    {this.state.mStudentDetails.length !== 0
                                      ? this.state.mStudentDetails[0].stu_name
                                      : ""}
                                  </h3>
                                </div>
                                <div className="SdDetails-infoItem">
                                  <label>Adhar No.</label>
                                  <h3>
                                    {this.state.mStudentDetails.length !== 0
                                      ? this.state.mStudentDetails[0]
                                          .adharcard_no
                                      : ""}
                                  </h3>
                                </div>
                                <div className="SdDetails-infoItem">
                                  <label>Gender</label>
                                  <h3>
                                    {this.state.mStudentDetails.length !== 0
                                      ? this.state.mStudentDetails[0].gender
                                      : ""}
                                  </h3>
                                </div>
                                <div className="SdDetails-infoItem">
                                  <label>DOB</label>
                                  <h3>
                                    {this.state.mStudentDetails.length !== 0
                                      ? this.state.mStudentDetails[0].birthday
                                      : ""}
                                  </h3>
                                </div>
                              </div>
                              <div className="SdDetails-infoRow">
                                <div className="SdDetails-infoItem">
                                  <label>DOB</label>
                                  <h3>
                                    {this.state.mStudentDetails.length !== 0
                                      ? this.state.mStudentDetails[0].birthday
                                      : ""}
                                  </h3>
                                </div>
                                <div className="SdDetails-infoItem">
                                  <label>Caste</label>
                                  <h3>
                                    {this.state.mStudentDetails.length !== 0
                                      ? this.state.mStudentDetails[0].caste
                                      : ""}
                                  </h3>
                                </div>
                                <div className="SdDetails-infoItem">
                                  <label>Nationality</label>
                                  <h3>
                                    {this.state.mStudentDetails.length !== 0
                                      ? this.state.mStudentDetails[0]
                                          .nationality
                                      : ""}
                                  </h3>
                                </div>
                                <div className="SdDetails-infoItem">
                                  <label>Community</label>
                                  <h3>
                                    {this.state.mStudentDetails.length !== 0
                                      ? this.state.mStudentDetails[0].community
                                      : ""}
                                  </h3>
                                </div>
                              </div>
                            </div>

                            {/* Current Address Section */}
                            <div className="SdDetails-section">
                              <h2>Current Address</h2>
                              <div className="SdDetails-address">
                                <label>Current Address:</label>
                                <h3>
                                  {this.state.mStudentDetails.length !== 0
                                    ? this.state.mStudentDetails[0]
                                        .current_address
                                    : ""}
                                </h3>
                              </div>
                            </div>

                            {/* Permanent Address Section */}
                            <div className="SdDetails-section">
                              <h2>Permanent Address</h2>
                              <div className="SdDetails-address">
                                <label>Permanent Address:</label>
                                <h3>
                                  {this.state.mStudentDetails.length !== 0
                                    ? this.state.mStudentDetails[0]
                                        .permanent_address
                                    : ""}
                                </h3>
                              </div>
                            </div>
                            <div className="SdDetails-section">
                              <h2>Family Detail</h2>
                              <div className="SdDetails-familyRow">
                                <div className="SdDetails-familyItem">
                                  <div className="SdDetails-familyImage">
                                    <img
                                      src={
                                        this.state.mFamilyDetails.length !== 0
                                          ? this.state.mFamilyDetails[0]
                                              .father_img_path
                                          : ""
                                      }
                                      alt="Father"
                                    />
                                  </div>
                                  <div className="SdDetails-familyInfo">
                                    <div className="SdDetails-familyDetail">
                                      <label>Father Name:</label>
                                      <h3>
                                        {this.state.mFamilyDetails.length !== 0
                                          ? this.state.mFamilyDetails[0]
                                              .father_name
                                          : ""}
                                      </h3>
                                    </div>
                                    <div className="SdDetails-familyDetail">
                                      <label>Occupation:</label>
                                      <h3>
                                        {this.state.mFamilyDetails.length !== 0
                                          ? this.state.mFamilyDetails[0]
                                              .father_occupation
                                          : ""}
                                      </h3>
                                    </div>
                                  </div>
                                </div>

                                {/* Mother Info */}
                                <div className="SdDetails-familyItem">
                                  <div className="SdDetails-familyImage">
                                    <img
                                      src={
                                        this.state.mFamilyDetails.length !== 0
                                          ? this.state.mFamilyDetails[0]
                                              .mother_img_path
                                          : ""
                                      }
                                      alt="Mother"
                                    />
                                  </div>
                                  <div className="SdDetails-familyInfo">
                                    <div className="SdDetails-familyDetail">
                                      <label>Mother Name:</label>
                                      <h3>
                                        {this.state.mFamilyDetails.length !== 0
                                          ? this.state.mFamilyDetails[0]
                                              .mother_name
                                          : ""}
                                      </h3>
                                    </div>
                                    <div className="SdDetails-familyDetail">
                                      <label>Occupation:</label>
                                      <h3>
                                        {this.state.mFamilyDetails.length !== 0
                                          ? this.state.mFamilyDetails[0]
                                              .mother_occupation
                                          : ""}
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Contact Details Section */}
                            <div className="SdDetails-section">
                              <h2>Contact Details</h2>
                              {this.state.mContactDetails &&
                                this.state.mContactDetails.map(
                                  (item, index) => (
                                    <div
                                      className="SdDetails-contactRow"
                                      key={index}
                                    >
                                      <div className="SdDetails-contactItem">
                                        <label>Contact Number 1:</label>
                                        <h3>
                                          {this.state.mContactDetails.length !==
                                          0
                                            ? this.state.mContactDetails[index]
                                                .contact_number
                                            : ""}
                                        </h3>
                                      </div>
                                      <div className="SdDetails-contactItem">
                                        <label>Name:</label>
                                        <h3>
                                          {this.state.mContactDetails.length !==
                                          0
                                            ? this.state.mContactDetails[index]
                                                .contact_person_name
                                            : ""}
                                        </h3>
                                      </div>
                                      <div className="SdDetails-contactItem">
                                        <label>Relation:</label>
                                        <h3>
                                          {this.state.mContactDetails.length !==
                                          0
                                            ? this.state.mContactDetails[index]
                                                .contact_person_relation
                                            : ""}
                                        </h3>
                                      </div>
                                    </div>
                                  )
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            />
            <Route
              path="/AdminMain/EditStudentDetails/:student_id"
              component={EditStudentDetails}
            />
            <Route
              path="/AdminMain/Acknowledgement/:Enrollment_no/:Session_id"
              component={Acknowledgement}
            />
            <Route
              path="/AdminMain/UpdateStudentDetails"
              component={UpdateStudentDetails}
            />
          </switch>
        </Router>
      );
    }
  }
}
