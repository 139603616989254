import React, { useState, useEffect } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import boyImage from "../img/boy.jpg";

const StaffPassword = () => {
  const [loggedIn, setLoggedIn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [mstaffRole, setMstaffRole] = useState([
    "Admin",
    "Teacher",
    "Clerk",
    "Class Teacher",
    "Clerk And Techer",
    "Director And Principal",
    "Director And Voice Principal",
    "Faculty",
    "LDA",
    "UDA",
    "Tutor And LDA",
    "Academic Incharge",
    "Child Caretaker",
    "Mother Teacher",
    "Computer Operator",
    "Head Master",
    "Physical Teacher",
    "Office Bearers",
    "Driver",
    "Helper",
  ]);
  const colors = ["#99CCFF", "#FF99CC"];

  useEffect(() => {
    fetchStaffList();
  }, []);

  const fetchStaffList = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getStaffPassword.php";
    const URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;

    const params = { school_code: SCHOOL_CODE };
    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);
    axios
      .get(URL, { params: params })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        if (response.api_status === "OK") {
          setStaffList(response.data);
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching exam list:", error);
      });
  };

  const handleRoleChange = (staffId, Role) => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "updateStaffRole.php";
    const URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;

    const params = {
      school_code: SCHOOL_CODE,
      staff_id: staffId,
      staff_role: Role,
    };
    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);
    axios
      .get(URL, { params: params })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        if (response.api_status === "OK") {
          alert("successfully Updated !");
          fetchStaffList();
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching exam list:", error);
      });
  };

  const handleLoginTypeChange = (staffId, Type) => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "updateStaffLoginType.php";
    const URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;

    const params = {
      school_code: SCHOOL_CODE,
      staff_id: staffId,
      login_type: Type,
    };
    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);
    axios
      .get(URL, { params: params })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        if (response.api_status === "OK") {
          alert("successfully Updated !");
          fetchStaffList();
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching exam list:", error);
      });
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  if (loading) {
    return <MyLoader />;
  }

  return (
    <Router>
      <Switch>
        <Route
          path="/AdminMain/StaffPassword"
          render={() => (
            <div className="content">
              <div className="content-header">
                <div className="leftside-content-header">
                  <ul className="breadcrumbs">
                    <li>
                      <i className="fa fa-home" aria-hidden="true" />
                      <a href="#">Staff Password</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="animated fadeInUpn bg_page_white_rounded box_shadow"
                style={{ "--mMargin": "50px" }}
              >
                {staffList.map((staff, index) => (
                  <div
                    className="bg_card_burnt_orange_outline margin_staff"
                    key={index}
                  >
                    <div className="staff-password-card">
                      <div
                        className="staff-password-header"
                        style={{
                          backgroundColor: colors[index % colors.length],
                        }}
                      >
                        <div className="staff-password-profile-pic">
                          <img src={boyImage} alt="Profile" />
                        </div>
                        <div className="staff-password-profile-info">
                          <h3>
                            {staff.staff_name} ({staff.staff_role})
                          </h3>
                          <p>MO: {staff.staff_phone}</p>
                        </div>
                        <div className="staff-password-details">
                          <p>
                            <strong>User Id:</strong> {staff.staff_id}
                          </p>
                          <p>
                            <strong>Password:</strong> {staff.password}
                          </p>
                          <p>
                            <strong>Email:</strong> {staff.staff_email}
                          </p>
                        </div>
                      </div>
                      <div className="staff-password-roles-container">
                        <div className="staff-password-role-box">
                          <label>Staff Role</label>
                          <select
                            className="role-dropdown"
                            value={staff.staff_role}
                            onChange={(e) =>
                              handleRoleChange(staff.staff_id, e.target.value)
                            }
                          >
                            {mstaffRole.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="staff-password-role-box">
                          <label>Login Type</label>
                          <select
                            className="role-dropdown"
                            value={staff.login_type}
                            onChange={(e) =>
                              handleLoginTypeChange(
                                staff.staff_id,
                                e.target.value
                              )
                            }
                          >
                            <option value="None">None</option>
                            <option value="Clerk">Clerk</option>
                            <option value="Teacher">Teacher</option>
                            <option value="Admin">Admin</option>
                          </select>
                        </div>

                        <button className="send-message-btn">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        />
      </Switch>
    </Router>
  );
};

export default StaffPassword;
