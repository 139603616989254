import React, { useState } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import "../css/Admin_css/admin-customize.css";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confPass, setConfPass] = useState("");

  const isValidInput = () => {
    if (oldPass === "") {
      alert("Please Enter Old Password");
      return false;
    } else if (oldPass !== "145") {
      alert("Your Old Password did not match!");
      return false;
    } else if (newPass === "") {
      alert("Please Enter New Password");
      return false;
    } else if (confPass === "") {
      alert("Please Enter Confirm Password");
      return false;
    } else if (newPass !== confPass) {
      alert("New Password and Confirm Password must match");
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (isValidInput()) {
      setLoading(true);
      const StaffId = "stf000111";
      const FUNCTION_NAME = "changeStaffPassword.php";
      const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

      axios
        .get(URL, {
          params: {
            staff_id: StaffId,
            new_password: newPass,
          },
        })
        .then((res) => {
          setLoading(false);
          alert("Password successfully updated!");
          window.location.reload();
        })
        .catch((error) => {
          setLoading(false);
          alert("An error occurred while updating the password");
          console.error(error);
        });
    }
  };

  if (loading) {
    return <MyLoader />;
  }

  return (
    <div className="content">
      <div className="content-header">
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li>
              <i className="fa fa-home" aria-hidden="true" />
              <a href="#"> Change Password </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        className="bg_page_white_rounded box_shadow animated fadeInUp "
        style={{ "--mMargin": "30px" }}
      >
        <div className="form-group row mar_top">
          <label className="col-md-2 col-form-label">Old Password</label>
          <div className="col-md-4">
            <div className="input-group">
              <input
                type="password"
                id="oldPass"
                name="oldPass"
                value={oldPass}
                onChange={(e) => setOldPass(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
          <label className="col-md-2 col-form-label">New Password</label>
          <div className="col-md-4">
            <div className="input-group">
              <input
                type="password"
                id="newPass"
                name="newPass"
                value={newPass}
                onChange={(e) => setNewPass(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
          <label className="col-md-2 col-form-label">Confirm Password</label>
          <div className="col-md-10">
            <div className="input-group">
              <input
                type="password"
                id="confPass"
                name="confPass"
                value={confPass}
                onChange={(e) => setConfPass(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <button
              className="btn btn-info"
              onClick={handleSubmit}
              style={{ width: "100%", fontSize: "14px" }}
            >
              Submit Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
