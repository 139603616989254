import React, { Component } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import proj from "../resources/images/proj.png";
import StudentDetails from "./StudentDetails";
import ExamList from "./ExamList";
import myToast from "../Utils/MyToast";
import COLORS from "../resources/colors/COLORS";
import icon_topper from "../resources/icons/icons_topper_64px_white.png";
import icon_exam from "../resources/icons/icons_exam_100_white.png";
import icom_assignment from "../resources/icons/icons_assignment_80px_white.png";
import icom_achievement from "../resources/icons/icons_achievement_100px_white.png";
import ExamTopperList from "./ExamTopperList";
import ExamReportCardList from "./ExamReportCardList";
export default class Academic extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Router>
        <switch>
          <Route
            path={"/AdminMain/Academic/:module_id"}
            exact
            render={() => {
              return (
                <div className="content">
                  <div className="content-header">
                    <div className="leftside-content-header">
                      <ul className="breadcrumbs">
                        <li>
                          <i className="fa fa-home" aria-hidden="true" />
                          <a href="#"> Academic </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="row animated fadeInUp bg_page_white_rounded box_shadow"
                    style={{ "--mMargin": "50px", marginTop: "20px" }}
                  >
                    <div
                      className="col-sm-4 col-md-4"
                      style={{ padding: "10px" }}
                    >
                      <Link to="/AdminMain/ExamList">
                        <div className="row class-list-box">
                          <div
                            className="col-sm-4"
                            style={{
                              background: COLORS.primary_color_shade6,
                              padding: "1em",
                            }}
                          >
                            <div>
                              <img
                                src={icon_exam}
                                className="admission-dashboard-img"
                                alt="student list image"
                              />
                            </div>
                          </div>
                          <div className="col-sm-8 gravity_center">
                            <h4 className="text-center"> Exam </h4>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div
                      className="col-sm-4 col-md-4"
                      style={{ padding: "10px" }}
                    >
                      <Link to="/AdminMain/ExamTopperList">
                        <div className="row class-list-box">
                          <div
                            className="col-sm-4"
                            style={{
                              background: COLORS.primary_color_shade6,
                              padding: "1em",
                            }}
                          >
                            <div>
                              <img
                                src={icon_topper}
                                className="admission-dashboard-img"
                                alt="student list image"
                              />
                            </div>
                          </div>
                          <div className="col-sm-8 gravity_center">
                            <h4 className="text-center">Topper List </h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div
                      className="col-sm-4 col-md-4"
                      style={{ padding: "10px" }}
                    >
                      <Link to="#">
                        <div className="row class-list-box">
                          <div
                            className="col-sm-4"
                            style={{
                              background: COLORS.primary_color_shade6,
                              padding: "1em",
                            }}
                          >
                            <div>
                              <img
                                src={icom_assignment}
                                className="admission-dashboard-img"
                                alt="student list image"
                              />
                            </div>
                          </div>
                          <div className="col-sm-8 gravity_center">
                            <h4 className="text-center">Assignment </h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div
                      className="col-sm-4 col-md-4"
                      style={{ padding: "10px" }}
                    >
                      <Link to="#">
                        <div className="row class-list-box">
                          <div
                            className="col-sm-4"
                            style={{
                              background: COLORS.primary_color_shade6,
                              padding: "1em",
                            }}
                          >
                            <div>
                              <img
                                src={icom_achievement}
                                className="admission-dashboard-img"
                                alt="student list image"
                              />
                            </div>
                          </div>
                          <div className="col-sm-8 gravity_center">
                            <h4 className="text-center">Achievement</h4>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div
                      className="col-sm-4 col-md-4"
                      style={{ padding: "10px" }}
                    >
                      <Link to="/AdminMain/ExamReportCardList">
                        <div className="row class-list-box">
                          <div
                            className="col-sm-4"
                            style={{
                              background: COLORS.primary_color_shade6,
                              padding: "1em",
                            }}
                          >
                            <div>
                              <img
                                src={icom_achievement}
                                className="admission-dashboard-img"
                                alt="student list image"
                              />
                            </div>
                          </div>
                          <div className="col-sm-8 gravity_center">
                            <h4 className="text-center">Annual Report Card</h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            }}
          />

          <Route path="/AdminMain/ExamList" component={ExamList} />
          <Route path="/AdminMain/ExamTopperList" component={ExamTopperList} />
          <Route
            path="/AdminMain/ExamReportCardList"
            component={ExamReportCardList}
          />
        </switch>
      </Router>
    );
  }
}
