import React, { useState, useEffect } from "react";
import MyLoader from "../Spinner/MyLoader";
import { Redirect } from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import MdlViewStaffLeaveDetails from "../dialog/MdlViewStaffLeaveDetails";
import boyImage from "../img/boy.jpg";

const StaffLeave = () => {
  const [loggedIn, setLoggedIn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [mLeaveStudent, setMLeaveStudent] = useState([]);
  const [isOpenViewLeaveDetails, setViewLeaveDetails] = useState(false);

  const toggleViewLeaveDetails = () => {
    setViewLeaveDetails(!isOpenViewLeaveDetails);
  };
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setMLeaveStudent([
        {
          image: boyImage,
          name: "Varun Yadav",
          class: "Play Group-A",
          rollnumber: "12528",
          date: "12/12/2022",
          statusIcon: "fa-street-view",
          statusText: "Pending",
          applicationText: `Dear Sir, I am writing this application to inform you that I am suffering from severe viral disease and therefore, I want sick leave from work. I got this infection last night and I will not be capable to come to the office for at least 12 days. As advised by my doctor, I should take rest and recover appropriately before continuing work. Kindly grant me leave for 10 days. Thanks for the consideration. Yours Sincerely, Yadav`,
          status: "pending",
        },
        {
          image: boyImage,
          name: "Rahul Sharma",
          class: "Play Group-B",
          rollnumber: "12528",
          date: "15/12/2022",
          statusIcon: "fa-check-circle",
          statusText: "Approved",
          applicationText: `Dear Sir, I am pleased to inform you that my health has improved and I will be returning to work on the 20th of December. Thank you for your understanding and support during my leave. Yours Sincerely, Rahul Sharma`,
          status: "approved",
        },
      ]);
      setLoading(false);
    }, 1000);
  }, []);

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  if (loading) {
    return <MyLoader />;
  }

  return (
    <div className="content">
      <div className="content-header">
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li>
              <i className="fa fa-home" aria-hidden="true" />
              <a href="#"> Applications (Staff) </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="animated fadeInUpn bg_page_white_rounded box_shadow student-leave-container">
        <h2 className="page-title1">Staff Leave Details</h2>
        {mLeaveStudent.length > 0 ? (
          mLeaveStudent.map((leave, index) => (
            <div key={index} className={`leave-card1 ${leave.status}-outline`}>
              <img
                src={leave.image}
                alt="Student"
                className="leave-student-image"
              />
              <div className="leave-info">
                <div className="leave-details">
                  <span>
                    <strong> Staff Name:</strong> {leave.name}
                  </span>
                  <span>
                    <strong>Designation:</strong> {leave.class}
                  </span>
                  <span>
                    <strong>Date:</strong> {leave.date}
                  </span>
                </div>
                <div className="leave-footer">
                  <span>
                    <strong>Status:</strong> {leave.statusText}{" "}
                    <i className={`fa ${leave.statusIcon}`} />
                  </span>
                  <span>
                    <strong>Department:</strong> {leave.rollnumber}
                  </span>
                  <button
                    className="btn btn-primary"
                    onClick={() => toggleViewLeaveDetails()}
                  >
                    Application Details
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>No leave records found.</div>
        )}

        {isOpenViewLeaveDetails && (
          <MdlViewStaffLeaveDetails actionClose={toggleViewLeaveDetails} />
        )}
      </div>
    </div>
  );
};

export default StaffLeave;
