import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import MdlEditSubjectMasterList from "../dialog/MdlEditSubjectMasterList";
import MdlAddSubjectMasterList from "../dialog/MdlAddSubjectMasterList";

const SubjectList = () => {
  const [isOpenSubjectDialog, setIsOpenSubjectDialog] = useState(false);
  const [isOpenAddSubjectDialog, setIsOpenAddSubjectDialog] = useState(false);
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("token") !== null
  );
  const [loading, setLoading] = useState(false);
  const [subjectList, setSubjectList] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState({ name: "", id: "" });

  useEffect(() => {
    getSubjectMasterList();
  }, []);

  const getSubjectMasterList = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const API_NAME = "getSubjectMasterList.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${API_NAME}`;

    axios
      .get(URL, { params: { school_code: SCHOOL_CODE } })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        if (response.api_status === "OK") {
          setSubjectList(response.data);
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching subjects:", error);
      });
  };

  const handleEditSubject = (item) => {
    setSelectedSubject({ name: item.subject_name, id: item.subject_id });
    setIsOpenSubjectDialog(true);
  };

  const handleCloseEditDialog = () => {
    setIsOpenSubjectDialog(false);
    getSubjectMasterList();
  };

  const handleToggleAddDialog = () => {
    setIsOpenAddSubjectDialog(!isOpenAddSubjectDialog);
    getSubjectMasterList();
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  if (loading) {
    return <MyLoader />;
  }

  return (
    <Router>
      <Route path="/AdminMain/SubjectList" exact>
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li>
                  <i className="fa fa-home" aria-hidden="true" />
                  <a href="#">Subject List</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="bg_page_white_rounded box_shadow">
            <div className="row new_subjectlist">
              {subjectList.map((item, index) => (
                <div className="col-md-4 mb-3" key={index}>
                  <div className="subject-card">
                    <div className="subject-details">{item.subject_name}</div>
                    <div
                      className="edit-icon"
                      onClick={() => handleEditSubject(item)}
                    >
                      <i className="fas fa-edit"></i>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-md-12 text-center">
                <button
                  className="btn btn-success add-button"
                  onClick={handleToggleAddDialog}
                >
                  Add New Subject
                </button>
              </div>
              {isOpenSubjectDialog && (
                <MdlEditSubjectMasterList
                  actionClose={handleCloseEditDialog}
                  subject_name={selectedSubject.name}
                  subject_id={selectedSubject.id}
                />
              )}
              {isOpenAddSubjectDialog && (
                <MdlAddSubjectMasterList actionClose={handleToggleAddDialog} />
              )}
            </div>
          </div>
        </div>
      </Route>
    </Router>
  );
};

export default SubjectList;
