import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";

const MdlUpdateStatus = ({ onClose, item }) => {
  const [loggedIn] = useState(localStorage.getItem("token") !== null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [status, setStatus] = useState();
  const [ExamId, setExamId] = useState();
  const [SessionId, setSessionId] = useState();

  useEffect(() => {
    setStatus(item.status);
    setExamId(item.exam_id);
    setSessionId(item.session_id);
  }, []);

  const handleClose = () => {
    setShow(false);
  };
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      var mStaffData = SessionManager.getStaffDetails();
      var STAFF_ID = mStaffData.staff_id;
      const FUNCTION_NAME = "updateExamStatus.php";
      const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

      const params = {
        school_code: SCHOOL_CODE,
        exam_id: ExamId,
        session_id: SessionId,
        status: status,
      };

      const queryString = new URLSearchParams(params).toString();
      console.log("Full URL: ", `${URL}${queryString}`);
      const response = await axios.get(URL, {
        params: params,
      });

      if (response.data.api_status === "OK") {
        myToast.notify_success("Exam Name  Update successfully!");
        onClose(SessionId);
      } else {
        myToast.notify_warning(
          response.data.api_remark || "Rename failed. Please try again."
        );
      }
    } catch (error) {
      console.error("Rename error:", error);
      myToast.notify_error("An error occurred while renaming the class.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Exam Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Select Exam Status</Form.Label>
            <Form.Control
              as="select"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="2">Live</option>
              <option value="3">Over</option>
              <option value="1">Upcoming</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Update
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MdlUpdateStatus;
