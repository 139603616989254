import React, { useState, useEffect } from "react";
import MyLoader from "../Spinner/MyLoader";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import boyImage from "../img/boy.jpg";
import { FaUser, FaChalkboardTeacher, FaClipboardList } from "react-icons/fa";

const AccessoriesCollection = () => {
  const [loggedIn, setLoggedIn] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Example of fetching session status or other initialization logic
    const checkLoginStatus = async () => {
      // Session check or loading logic can go here
      // Example: setLoggedIn(SessionManager.isLoggedIn());
    };
    checkLoginStatus();
  }, []);

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  } else if (loading) {
    return <MyLoader />;
  } else {
    return (
      <Router>
        <Switch>
          <Route
            path="/AdminMain/AccessoriesCollection"
            exact
            render={() => (
              <div className="content">
                <div className="content-header">
                  <div className="leftside-content-header">
                    <ul className="breadcrumbs">
                      <li>
                        <i className="fa fa-home" aria-hidden="true" />
                        <a href="#"> Accessories Collection </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="bg_page_grey_rounded box_shadow"
                  style={{ "--mMargin": "50px" }}
                >
                  <div className="accessories-collection">
                    <div className="accessories-collection-user-info">
                      <div className="accessories-collection-profile-icon">
                        <div className="accessories-collection-profile-circle">
                          <img
                            src={boyImage}
                            alt="User"
                            className="accessories-collection-profile-image"
                          />
                        </div>
                        <div className="accessories-collection-profile-id">
                          S055311
                        </div>
                      </div>
                      <div className="accessories-collection-user-details">
                        <p className="accessories-collection-user-name">
                          AMRITA PANDEY
                        </p>
                        <p className="accessories-collection-user-parent">
                          D/O: OM PRAKASH
                        </p>
                        <p className="accessories-collection-user-location">
                          Vill/Town: null
                        </p>
                        <p className="accessories-collection-user-srn">
                          SRN: 1
                        </p>
                      </div>
                      <div className="accessories-collection-roll-info">
                        <p>Roll No</p>
                        <div className="accessories-collection-roll-number">
                          1
                        </div>
                        <p>Play Group: A</p>
                      </div>
                    </div>

                    <div className="accessories-collection-due-info">
                      <div className="bg_card_burnt_orange_outline accessories-collection-due-item">
                        <div className="accessories-collection-checkbox-container">
                          <input type="checkbox" className="" />
                        </div>
                        <div className="accessories-collection-due-amount-container">
                          <div className="accessories-collection-due-amount">
                            Due Amount Rs: 4500
                            <br />
                            2024-11-09
                          </div>
                        </div>
                      </div>

                      <div className="bg_card_burnt_orange_outline accessories-collection-due-item">
                        <div className="accessories-collection-checkbox-container">
                          <input type="checkbox" className="" />
                        </div>
                        <div className="accessories-collection-due-amount-container">
                          <div className="accessories-collection-due-amount">
                            Due Amount Rs: 4500
                            <br />
                            2024-11-09
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accessories-collection-collect-options">
                      <div className="accessories-collection-other-amount">
                        Collect By Other Amount
                      </div>
                      <input
                        type="text"
                        placeholder="Other Amount"
                        className="accessories-collection-other-amount-input"
                      />
                    </div>

                    <button className="accessories-collection-collect-now">
                      Collect Now (Rs: 9000)
                    </button>
                  </div>
                </div>
              </div>
            )}
          />
        </Switch>
      </Router>
    );
  }
};

export default AccessoriesCollection;
