import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Redirect, useParams } from 'react-router-dom';
import MyLoader from '../Spinner/MyLoader';
import "../css/Admin_css/admin-customize.css";
import myToast from '../Utils/MyToast';
import axios from 'axios';
import SessionManager from '../Utils/SessionManager';

const TransportRoutesDetails = () => {
  const { route_id } = useParams(); 
  const [students, setStudents] = useState([]);
  const [PickupPpoints, setPickupPpoints] = useState([]);
  const [RoutesDetails, setRoutesDetails] = useState([]);
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem("token") !== null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (route_id) {
      getRouteDetails(route_id);
    }
  }, [route_id]);

  const getRouteDetails = (route_id) => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = 'getRouteDetails.php';
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    axios
      .get(URL, { params: { school_code: SCHOOL_CODE, route_id } })
      .then(res => res.data)
      .then(response => {
        setLoading(false);
        if (response.api_status === "OK") {
          //alert(JSON.stringify(response.data));
          setRoutesDetails(response.data || []);
          setPickupPpoints(response.data[0].pickup_points || []);
          setStudents(response.data.students || []);
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch(error => {
        setLoading(false);
        console.error("Error fetching data:", error);
        myToast.notify_error("Failed to load route details.");
      });
  };

  if (!loggedIn) {
    return <Redirect to='/Login' />;
  }

  if (loading) {
    return <MyLoader />;
  }

  return (
    <Router>
      <Route path={'/AdminMain/TransportRoutesDetails/:route_id'} exact render={() => (
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Transport Routes Details</a></li>
              </ul>
            </div>
          </div>
          <div className="route-student-details-container">
            <div className="royal-dark-blue-box">
              <h2>Route Information</h2>
              <div className="route-info">
                <div className="route-info-item"><strong>Route Name</strong> {RoutesDetails?.[0]?.route_name} </div>
                <div className="route-info-item"><strong>From</strong> {RoutesDetails?.[0]?.start_from}</div>
                <div className="route-info-item"><strong>To</strong> {RoutesDetails?.[0]?.end_to}</div>
                <div className="route-info-item"><strong>Distance (Km)</strong> {RoutesDetails?.[0]?.route_distance}</div>
                <div className="route-info-item"><strong>Vehicle Number</strong> {RoutesDetails?.[0]?.route_name}</div>
              </div>
            </div>

            <div className="bg-page-grey-rounded-route-student-details box-shadow-route-student-details">
              <div className="pickup-points">
                <h2 className='colorful-heading'>Pickup Points</h2>
                <div className="pickup-points-list">
                  {PickupPpoints && PickupPpoints .map((point, index) => (
                    <div className="pickup-point" key={index}>{point.pickup_point_name}</div>
                  ))}
                </div>
              </div>

              <h2 className="colorful-heading">Route Student Details</h2>
              <div className="students-list1">
                {students.length > 0 ? (
                  students.map((item, index) => (
                    <div className='student-box-route-student-details' key={index}>
                      <div className="student-info-route-student-details">
                        <div className="student-info-item1"><strong>S.N:</strong> {item.student_id}</div>
                        <div className="student-info-item"><strong>Student Name:</strong> {item.student_name}</div>
                        <div className="student-info-item"><strong>Father Name:</strong> {item.father_name}</div>
                        <div className="student-info-item1"><strong>Class:</strong> {item.class_name}</div>
                        <div className="student-info-item"><strong>Mobile No:</strong> {item.mobile_no}</div>
                        <div className="student-info-item"><strong>Pickup Point:</strong> {item.pickup_point}</div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No students available</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )} />
    </Router>
  );
};

export default TransportRoutesDetails;
