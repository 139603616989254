import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import SessionManager from '../Utils/SessionManager';

const UpdateSchoolDetails = ({ match }) => {
  const history = useHistory();
  const [schoolDetails, setSchoolDetails] = useState({
    school_name: '',
    school_address: '',
    school_tag_line: '',
    school_reg_no: '',
    udise_no: '',
    school_estb_year: '',
    officail_contact_no1: '',
    officail_contact_no2: '',
    officail_email: '',
    website: '',
  });

  useEffect(() => {
    setSchoolDetails({
      school_name: match.params.school_name,
      school_address: match.params.school_address,
      school_tag_line: match.params.school_tag_line,
      school_reg_no: match.params.school_reg_no,
      udise_no: match.params.udise_no,
      school_estb_year: match.params.school_estb_year,
      officail_contact_no1: match.params.officail_contact_no1,
      officail_contact_no2: match.params.officail_contact_no2,
      officail_email: match.params.officail_email,
      website: match.params.website,
    });
  }, [match.params]);

  const handleChange = (e) => {
    setSchoolDetails({ ...schoolDetails, [e.target.id]: e.target.value });
  };

  const handleSubmit = () => {
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = 'updateSchoolDetails.php';
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          school_name: schoolDetails.school_name,
          address: schoolDetails.school_address,
          tagline: schoolDetails.school_tag_line,
          registration_no: schoolDetails.school_reg_no,
          udise_no: schoolDetails.udise_no,
          established_year: schoolDetails.school_estb_year,
          contact1: schoolDetails.officail_contact_no1,
          contact2: schoolDetails.officail_contact_no2,
          email: schoolDetails.officail_email,
          website: schoolDetails.website,
        },
      })
      .then(() => {
        alert('Successfully Updated!');
        history.push('/AdminMain/SchoolProfile/MDL023');
        window.location.reload(); 
      })
      .catch((error) => {
        console.error('Error updating school details:', error);
      });
  };

  return (
    <div className="content">
      <div className="content-header">
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li>
              <i className="fa fa-home" aria-hidden="true" />
              <a href="#"> School Profile </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="animated fadeInUpn bg_page_white_rounded box_shadow" style={{ '--mMargin': '50px' }}>
        <div className="update-school-details">
          <h1 className="update-school-header">Update School Details</h1>
          <div className="update-school-row">
            <div className="update-school-input-group">
              <label className="update-school-label">School Name:</label>
              <input type="text" id="school_name" className="update-school-input" value={schoolDetails.school_name} onChange={handleChange} />
            </div>
            <div className="update-school-input-group">
              <label className="update-school-label">School Address:</label>
              <input type="text" id="school_address" className="update-school-input" value={schoolDetails.school_address} onChange={handleChange} />
            </div>
          </div>
          <div className="update-school-row">
            <div className="update-school-input-group">
              <label className="update-school-label">School Tag Line:</label>
              <input type="text" id="school_tag_line" className="update-school-input" value={schoolDetails.school_tag_line} onChange={handleChange} />
            </div>
            <div className="update-school-input-group">
              <label className="update-school-label">Registration No:</label>
              <input type="text" id="school_reg_no" className="update-school-input" value={schoolDetails.school_reg_no} onChange={handleChange} />
            </div>
          </div>
          <div className="update-school-row">
            <div className="update-school-input-group">
              <label className="update-school-label">Established Year:</label>
              <input type="text" id="school_estb_year" className="update-school-input" value={schoolDetails.school_estb_year} onChange={handleChange} />
            </div>
            <div className="update-school-input-group">
              <label className="update-school-label">Contact 1:</label>
              <input type="text" id="officail_contact_no1" className="update-school-input" value={schoolDetails.officail_contact_no1} onChange={handleChange} />
            </div>
          </div>
          <div className="update-school-row">
            <div className="update-school-input-group">
              <label className="update-school-label">Contact 2:</label>
              <input type="text" id="officail_contact_no2" className="update-school-input" value={schoolDetails.officail_contact_no2} onChange={handleChange} />
            </div>
            <div className="update-school-input-group">
              <label className="update-school-label">Email:</label>
              <input type="email" id="officail_email" className="update-school-input" value={schoolDetails.officail_email} onChange={handleChange} />
            </div>
          </div>
          <div className="update-school-row">
            <div className="update-school-input-group">
              <label className="update-school-label">Website:</label>
              <input type="text" id="website" className="update-school-input" value={schoolDetails.website} onChange={handleChange} />
            </div>
            <div className="update-school-input-group">
              <label className="update-school-label">Udise No:</label>
              <input type="text" id="udise_no" className="update-school-input" value={schoolDetails.udise_no} onChange={handleChange} />
            </div>
          </div>
          <button className="update-school-submit-button" onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default UpdateSchoolDetails;
