import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import myToast from "../Utils/MyToast";

const MdlDeleteClassSubject = ({ actionClose, subject }) => {
  const [show, setShow] = useState(true);
  if (!subject) return null;

  const handleClose = () => {
    setShow(false);
    actionClose();
  };
  const handleDelete = () => {
    const API_NAME = "deleteClassSubject.php";
    const URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      API_NAME;

    axios
      .post(URL, { subject_id: subject.id })
      .then(({ data }) => {
        if (data.api_status === "OK") {
          myToast.notify_success("Subject deleted successfully!");
          actionClose();
        } else {
          myToast.notify_warning(data.api_remark);
        }
      })
      .catch((error) => {
        console.error("API error:", error);
        myToast.notify_error("Failed to delete subject.");
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Paper</Modal.Title>
      </Modal.Header>

      <Modal.Body className="MdlDeletePaper-modal-body">
        <p>
          Are you sure you want to delete{" "}
          <strong>{subject.subject_name}</strong>?
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
        <Button variant="secondary" onClick={actionClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MdlDeleteClassSubject;
