import React, { useState, useEffect } from "react";
import "../css/Admin_css/admin-customize.css";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import myToast from "../Utils/MyToast";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import AdvancedPayment from "./AdvancedPayment";
import COLORS from "../resources/colors/COLORS";

const ExamAddMarkSubjectWise = (props) => {
  const [loading, setLoading] = useState(false);
  const [classList, setClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [subjectId, setsubjectId] = useState();
  const [class_id, setClassId] = useState();
  const [section_id, setSectionId] = useState();
  const [selectedClassPosition, setSelectedClassPosition] = useState(-1);
  const [selectedSubjectPosition, setSelectedSubjectPosition] = useState(0);
  const [selectedPaperPosition, setSelectedPaperPosition] = useState(0);
  const [mMarkStatus, setMarkStatus] = useState([]);
  const [mStudentAltSub, setStudentAltSub] = useState([]);
  const [mStudentList, setStudentList] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [classSubjectPaper, setClassSubjectPaper] = useState([]);

  const [marks, setMarks] = useState("");

  const {
    exam_id: EXAM_ID,
    session_id: SESSION_ID,
    exam_name: EXAM_NAME,
  } = props.match.params;

  useEffect(() => {
    getExamParticipatingClass();
  }, []);

  useEffect(() => {
    if (selectedClassPosition >= 0) {
      getClassList(selectedClassPosition);
    }
  }, [selectedClassPosition]);

  useEffect(() => {
    if (subjectList && subjectList.length !== 0 && selectedClassPosition >= 0) {
      const CLASS_ID = classList[selectedClassPosition].class_id;
      const SECTION_ID =
        classList[selectedClassPosition].section_id === "null"
          ? "No"
          : classList[selectedClassPosition].section_id;
      setClassId(CLASS_ID);
      setSectionId(SECTION_ID);
      //alert("CLASS_ID"+CLASS_ID);
      //alert("SECTION_ID"+SECTION_ID);
      const selectedSubject = subjectList[selectedSubjectPosition];
      if (selectedSubject) {
        setsubjectId(selectedSubject.subject_id);
        getSubjectPaper(selectedSubject.subject_id);
        getClassStudentMarks(CLASS_ID, SECTION_ID, selectedSubject.subject_id);
      }
    }
  }, [selectedSubjectPosition, subjectList]);

  const getSubjectPaper = (selectedSubjectId) => {
    // Clear the current subject paper list
    const mClassSubjectPaper = [];
    const mAllSubjectPaper = classList[selectedClassPosition]?.subject_paper;

    if (mAllSubjectPaper && mAllSubjectPaper.length > 0) {
      mAllSubjectPaper.forEach((paper) => {
        if (
          paper.subject_id?.toLowerCase() === selectedSubjectId.toLowerCase()
        ) {
          mClassSubjectPaper.push(paper);
        }
      });
    }
    // alert(JSON.stringify(mClassSubjectPaper));
    setClassSubjectPaper(mClassSubjectPaper);
  };

  const getExamParticipatingClass = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getClassWithSubjectsPartAndPaper.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          exam_id: EXAM_ID,
          session_id: SESSION_ID,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        if (response.api_status === "OK") {
          setClassList(response.data);
          setSelectedClassPosition(-1);
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        myToast.notify_error("Error fetching class data");
      });
  };

  const getClassList = (selected_position) => {
    if (classList.length !== 0 && selected_position >= 0) {
      const selectedClass = classList[selected_position];
      setSubjectList(selectedClass.subject);
      setSelectedSubjectPosition(0);
    }
  };

  const getClassStudentMarks = (CLASS_ID, SECTION_ID, SUBJECT_ID) => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getClassSubjectMarks.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    const params = {
      school_code: SCHOOL_CODE,
      exam_id: EXAM_ID,
      class_id: CLASS_ID,
      section_id: SECTION_ID,
      subject_id: SUBJECT_ID,
      session_id: SESSION_ID,
    };

    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);
    // alert(CLASS_ID+SECTION_ID+SUBJECT_ID+EXAM_ID+SESSION_ID);
    axios
      .get(URL, {
        params: params,
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        //alert(JSON.stringify(response));
        if (response.api_status === "OK") {
          setMarkStatus(response.data.mark_status);
          setStudentAltSub(response.data.student_alt_sub);
          setStudentList(response.data.student_list);
        } else {
          myToast.notify_warning(response.api_remark);
          setMarkStatus([]);
          setStudentAltSub([]);
          setStudentList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        myToast.notify_error("Error fetching student marks");
      });
  };
  useEffect(() => {
    const filteredList = [];

    if (mStudentList && mStudentList.length > 0) {
      if (checkIfAlternativeSubject(subjectId)) {
        // Handling alternative subjects
        mStudentList.forEach((student) => {
          const studentId = student.student_enrollment_no;

          if (isSubGrantedToStudent(subjectId, studentId)) {
            const mark = getMark(studentId);
            if (mark.length > 0) {
              if (mark[0].is_mark_system == "1") {
                mark[0].obtained_mark_new = "";
                mark[0].obtained_mark_tmp = mark[0].obtained_mark;
                mark[0].changed_mark = "";
                mark[0].set_error = "";
              } else {
                mark[0].grade_obtained_new = "";
                mark[0].grade_obtained_tmp = mark[0].grade_obtained;
                mark[0].changed_mark = "";
                mark[0].set_error = "";
              }
            }
            // console.log(studentId + ":" + mark);
            filteredList.push({ ...student, mark_status: mark });
          }
        });
      } else {
        // Handling regular subjects
        mStudentList.forEach((student) => {
          const mark = getMark(student.student_enrollment_no);
          filteredList.push({ ...student, mark_status: mark });
        });
      }
    }
    // console.log("student>>" + JSON.stringify(filteredList));
    setFilteredStudents(filteredList);
  }, [mStudentList]);

  const checkIfAlternativeSubject = (subjectId) => {
    let TAG_ALT = "ALTERNATIVE";
    for (let i = 0; i < classSubjectPaper.length; i++) {
      const subject = classSubjectPaper[i];
      if (
        subject.subject_id.toLowerCase() === subjectId.toLowerCase() &&
        subject.subject_type.toLowerCase() === TAG_ALT.toLowerCase()
      ) {
        return true;
      }
    }
    return false;
  };
  const isSubGrantedToStudent = (subjectId, studentId) => {
    for (let i = 0; i < mStudentAltSub.length; i++) {
      const subject = mStudentAltSub[i];
      if (
        subject.subject_id?.toLowerCase() === subjectId.toLowerCase() &&
        subject.student_id?.toLowerCase() === studentId.toLowerCase()
      ) {
        return true;
      }
    }
    return false;
  };

  const getMark = (studentId) => {
    const mMark = [];

    // Step 1: Filter marks for the given student ID
    mMarkStatus.forEach((status) => {
      if (status.student_id?.toLowerCase() === studentId.toLowerCase()) {
        mMark.push({ ...status });
      }
    });

    // Step 2: Add missing papers to mMark
    if (classSubjectPaper && classSubjectPaper.length > 0) {
      classSubjectPaper.forEach((paper) => {
        const paperId = paper.exam_paper_id;

        if (!isAlreadyAdded(mMark, paperId)) {
          const map = { ...paper, obtained_mark: "", grade_obtained: "" };
          mMark.push(map);
        }
      });
    }

    // Step 3: Convert the result to a JSON string
    return mMark.length > 0 ? mMark : null;
  };
  const isAlreadyAdded = (mMark, paperId) => {
    return mMark.some(
      (mark) => mark.exam_paper_id?.toLowerCase() === paperId.toLowerCase()
    );
  };

  const submitMark = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "AddStudentMarkSubjectWiseNew.php?";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    const mark_details = filteredStudents.map((item) => ({
      student_enrollment_no: item.student_enrollment_no,
      mark_status: JSON.stringify(item.mark_status),
    }));

    const MARK_DETAILS = JSON.stringify(mark_details);
    // alert(MARK_DETAILS);
    //console.log("MARK_DETAILS:", MARK_DETAILS);

    var params = new URLSearchParams();
    params.append("exam_id", EXAM_ID);
    params.append("class_id", class_id);
    params.append("section_id", section_id);
    params.append("subject_id", subjectId);
    params.append("session_id", SESSION_ID);
    params.append("mark_details", MARK_DETAILS);
    params.append("school_code", SCHOOL_CODE);

    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);
    // alert(CLASS_ID+SECTION_ID+SUBJECT_ID+EXAM_ID+SESSION_ID);
    axios
      .post(URL, params)
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        //alert(JSON.stringify(response));

        console.log(JSON.stringify(response));

        if (response.api_status === "OK") {
          alert("Submitted Sucessfully");
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        myToast.notify_error("Error fetching student marks");
      });
  };

  const handleInputChange = (studentIndex, paperIndex, mark) => {
    setFilteredStudents((prevStudents) =>
      prevStudents.map((student, i) => {
        if (i !== studentIndex) return student; // Keep other students unchanged

        let updatedStudent = { ...student }; // Clone student object
        let updatedMarkStatus = [...updatedStudent.mark_status]; // Clone mark_status array

        if (!Array.isArray(updatedMarkStatus) || !updatedMarkStatus[paperIndex])
          return student;

        let updatedPaper = { ...updatedMarkStatus[paperIndex] }; // Clone paper object

        if (updatedPaper.is_mark_system === "1") {
          const max_mark = parseFloat(updatedPaper.max_mark);
          const inputMark = parseFloat(mark);

          if (!Number.isNaN(inputMark)) {
            if (mark !== "" && inputMark > max_mark) {
              alert("Obtain Mark is greater than max mark");
              updatedPaper.set_error = "1";
              updatedPaper.errorMessage =
                "Obtain Mark is greater than max mark";
            } else {
              updatedPaper.obtained_mark_new = mark;
              updatedPaper.obtained_mark_tmp = mark;
              updatedPaper.changed_mark = "1";
              updatedPaper.set_error = "0";
              delete updatedPaper.errorMessage;
            }
          } else {
            updatedPaper.obtained_mark_new = mark;
            updatedPaper.obtained_mark_tmp = mark;
            updatedPaper.changed_mark = "1";
            updatedPaper.set_error = "0";
            delete updatedPaper.errorMessage;
          }
        } else {
          updatedPaper.grade_obtained_new = mark;
          updatedPaper.grade_obtained_tmp = mark;
          updatedPaper.changed_mark = "1";
        }

        updatedMarkStatus[paperIndex] = updatedPaper; // Update specific paper
        updatedStudent.mark_status = updatedMarkStatus; // Update student data

        return updatedStudent;
      })
    );
  };

  const token = localStorage.getItem("token");
  if (!token) {
    return <Redirect to="/Login" />;
  }

  return (
    <Router>
      <Route
        exact
        path="/AdminMain/ExamAddMarkSubjectWise/:exam_id/:session_id/:exam_name/:exam_status"
      >
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li>
                  <i className="fa fa-home" aria-hidden="true" />
                  <a href="#">Add Mark Subject Wise</a>
                </li>
              </ul>
            </div>
          </div>

          <div
            className="animated fadeInUp bg_page_white_rounded box_shadow"
            style={{ "--mMargin": "80px" }}
          >
            {loading && <MyLoader />}
            <div
              className="row bg_card_primary gravity_center"
              style={{ padding: "5px", margin: "0px 15px 0px 15px" }}
            >
              {classList.map((item, index) => (
                <div
                  className="col-sm-1 col-md-1"
                  style={{ padding: "0px 2px 5px 2px" }}
                  key={index}
                >
                  <div
                    className={
                      selectedClassPosition === index
                        ? "bg_class_select gravity_center"
                        : "bg_class_deselect gravity_center"
                    }
                    onClick={() => setSelectedClassPosition(index)}
                    style={{ cursor: "pointer" }}
                  >
                    <p style={{ fontSize: 14 }}>
                      {item.section_id === "No"
                        ? item.class_name
                        : `${item.class_name}:${item.section_name}`}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div
              className="bg_card_blue_outline"
              style={{ margin: "5px 15px 5px 15px" }}
            >
              <div style={{ padding: "0px 10px 0px 10px" }}>
                <h5 className="margin_0 gravity_center">Select Subject</h5>
              </div>
              <div
                className="row gravity_center"
                style={{ padding: "0px 5px 0px 5px" }}
              >
                {subjectList &&
                  subjectList.map((item2, index2) => (
                    <div
                      className="col-sm-2 col-md-2"
                      style={{ padding: "0px 2px 5px 2px" }}
                      key={index2}
                    >
                      <div
                        className={
                          selectedSubjectPosition === index2
                            ? "bg_class_select gravity_center"
                            : "bg_white_outline_grey gravity_center"
                        }
                        onClick={() => setSelectedSubjectPosition(index2)}
                        style={{ cursor: "pointer" }}
                      >
                        <p style={{ fontSize: 18 }}>{item2.subject_name}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="Stu_mark">
              {filteredStudents &&
                filteredStudents.map((item, index) => {
                  let mMarkDetails = Array.isArray(item.mark_status)
                    ? item.mark_status
                    : [];

                  return (
                    <div
                      className="row ExamAddMarkSubjectWise-card"
                      key={index}
                    >
                      <div className="col-sm-3 col-md-3 padding_0 gravity_center">
                        <div className="ExamAddMarkSubjectWise-box">
                          <p className="ExamAddMarkSubjectWise-text">
                            <strong className="ExamAddMarkSubjectWise-label">
                              Roll Number:
                            </strong>{" "}
                            {item.student_roll_no}
                          </p>
                          <p className="ExamAddMarkSubjectWise-text">
                            <strong className="ExamAddMarkSubjectWise-label">
                              Student Name:
                            </strong>{" "}
                            {item.stu_name}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-9 col-md-9 padding_0">
                        <div className="ExamAddMarkSubjectWise-info">
                          {mMarkDetails.map((item2, index2) => (
                            <div
                              className="ExamAddMarkSubjectWise-box2"
                              key={index2}
                            >
                              <p className="ExamAddMarkSubjectWise-text">
                                <strong className="ExamAddMarkSubjectWise-label">
                                  Subject Paper:
                                </strong>{" "}
                                {item2.exam_paper_name}
                              </p>
                              <p className="ExamAddMarkSubjectWise-text">
                                <strong className="ExamAddMarkSubjectWise-label">
                                  {item2.is_mark_system === "1"
                                    ? "Max Marks: "
                                    : "Max Grade: "}
                                </strong>{" "}
                                {item2.is_mark_system === "1"
                                  ? item2.max_mark
                                  : item2.max_grade}
                              </p>
                              <p className="ExamAddMarkSubjectWise-text">
                                <strong className="ExamAddMarkSubjectWise-label">
                                  {item2.is_mark_system === "1"
                                    ? "Obt Marks: "
                                    : "Obt Grade: "}
                                </strong>
                                <input
                                  type="text"
                                  className="ExamAddMarkSubjectWise-field"
                                  value={
                                    item2.is_mark_system === "1"
                                      ? item2.obtained_mark_tmp
                                      : item2.grade_obtained_tmp
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      index2,
                                      e.target.value
                                    )
                                  }
                                />
                                {item2.set_error === "1" && (
                                  <p style={{ color: "red" }}>
                                    {item2.errorMessage}
                                  </p>
                                )}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 ">
                <button
                  className="btn btn-success gravity_center bg_card_blue"
                  style={{
                    width: "100%",
                    height: "35px",
                    fontSize: "16px",
                    margin: "0px 3px 3px 3px",
                  }}
                  onClick={submitMark}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Route>
    </Router>
  );
};

export default ExamAddMarkSubjectWise;
