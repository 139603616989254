import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import MyLoader from "../Spinner/MyLoader";
import COLORS from "../resources/colors/COLORS";
import icon_staff from "../resources/icons/icons_staff_100_white.png";
import icons_students from "../resources/icons/icons_students_100_white.png";
import StudentLeaveDetails from "./StudentLeaveDetails";
import StaffLeaveDetails from "./StaffLeaveDetails";
import boyImage from "../img/boy.jpg";

const LeaveManagementAdmin = () => {
  const token = localStorage.getItem("token");
  const [loggedIn] = useState(token !== null);
  const [loading, setLoading] = useState(true);
  const [leaveStudents, setLeaveStudents] = useState([]);

  useEffect(() => {
    // fetchLeaveStudents();
    setLoading(false); // Dummy data fetching simulation
  }, []);

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  if (loading) {
    return <MyLoader />;
  }

  return (
    <Router>
      <Switch>
        <Route
          path="/AdminMain/LeaveManagementAdmin"
          render={() => (
            <div className="content">
              <div className="content-header">
                <div className="leftside-content-header">
                  <ul className="breadcrumbs">
                    <li>
                      <i className="fa fa-home" aria-hidden="true" />
                      <a href="#"> Admin Leave Management </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="bg_page_white_rounded box_shadow"
                style={{ "--mMargin": "50px" }}
              >
                <div className="row class-li">
                  <div className="col-sm-6 col-md-6 ">
                    <Link to={{ pathname: "/AdminMain/StudentLeaveDetails" }}>
                      <div
                        className="row box_shadow bg_card_blue_outline"
                        style={{
                          padding: 2,
                          height: "80px",
                          margin: "10px 0px 10px 0px",
                        }}
                      >
                        <div
                          className="col-sm-4 gravity_center"
                          style={{
                            background: COLORS.card_blue,
                            height: "100%",
                            margin: 0,
                            padding: 0,
                            borderRadius: 10,
                          }}
                        >
                          <img
                            src={icons_students}
                            style={{ height: "50px", width: "50px" }}
                          />
                        </div>
                        <div
                          className="col-sm-8 gravity_center"
                          style={{ height: "100%", margin: 0, padding: 0 }}
                        >
                          <h5> Student Leave Details </h5>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-sm-6 col-md-6 ">
                    <Link to={{ pathname: "/AdminMain/StaffLeaveDetails" }}>
                      <div
                        className="row box_shadow bg_card_blue_outline"
                        style={{
                          padding: 2,
                          height: "80px",
                          margin: "10px 0px 10px 0px",
                        }}
                      >
                        <div
                          className="col-sm-4 gravity_center"
                          style={{
                            background: COLORS.card_blue,
                            height: "100%",
                            margin: 0,
                            padding: 0,
                            borderRadius: 10,
                          }}
                        >
                          <img
                            src={icon_staff}
                            style={{ height: "50px", width: "50px" }}
                          />
                        </div>
                        <div
                          className="col-sm-8 gravity_center"
                          style={{ height: "100%", margin: 0, padding: 0 }}
                        >
                          <h5> Staff Leave Details </h5>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        />

        <Route
          path="/AdminMain/StudentLeaveDetails"
          component={StudentLeaveDetails}
        />
        <Route
          path="/AdminMain/StaffLeaveDetails"
          component={StaffLeaveDetails}
        />
      </Switch>
    </Router>
  );
};

export default LeaveManagementAdmin;
