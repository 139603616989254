import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import SelectClass from "../ChildComponent/SelectClass";
import COLORS from "../resources/colors/COLORS";
import myToast from "../Utils/MyToast";
import MdlAddClassSubjectList from "../dialog/MdlAddClassSubjectList";
import MdlEditClassSubject from "../dialog/MdlEditClassSubject";
import MdlDeleteClassSubject from "../dialog/MdlDeleteClassSubject";

const ClassSubject = () => {
  const history = useHistory();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) history.push("/Login");
  }, [token, history]);

  const [isOpenAddSubjectDialog, setIsOpenAddSubjectDialog] = useState(false);
  const [isOpenEditSubjectDialog, setIsOpenEditSubjectDialog] = useState(false);
  const [isOpenDeleteSubjectDialog, setIsOpenDeleteSubjectDialog] =
    useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [SESSION_ID, setSESSION_ID] = useState("");
  const [CLASS_ID, setCLASS_ID] = useState("");
  const [CLASS_NAME, setCLASS_NAME] = useState("");
  const [SECTION_ID, setSECTION_ID] = useState("");
  const [mClassSubject, setMClassSubject] = useState([]);
  const [SELECTED_CLASS_POSITION, setSELECTED_CLASS_POSITION] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleAddSubjectDialog = () => {
    setIsOpenAddSubjectDialog((prev) => !prev);
  };
  const actionCloseAdd = () => {
    // alert("action");
    setIsOpenAddSubjectDialog(false);
    getClassSubject(CLASS_ID, SECTION_ID, SESSION_ID);
  };

  const toggleEditSubjectDialog = (subject) => {
    setSelectedSubject(subject);
    setIsOpenEditSubjectDialog((prev) => !prev);
  };

  const actionCloseEdit = () => {
    setIsOpenEditSubjectDialog((prev) => !prev);
  };
  const toggleDeleteSubjectDialog = (subject) => {
    setSelectedSubject(subject);
    setIsOpenDeleteSubjectDialog((prev) => !prev);
  };

  const callBackClassSelect = (
    class_id,
    section_id,
    session_id,
    selected_position,
    class_name
  ) => {
    //alert(section_id);
    setCLASS_ID(class_id);
    setCLASS_NAME(class_name);
    setSECTION_ID(section_id);
    setSESSION_ID(session_id);
    setSELECTED_CLASS_POSITION(selected_position);
    getClassSubject(class_id, section_id, session_id);
  };

  const getClassSubject = (class_id, section_id, session_id) => {
    setLoading(true);
    setMClassSubject([]);

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const API_NAME = "getClassSubject.php";
    const URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      API_NAME;

    axios
      .get(URL, {
        params: { school_code: SCHOOL_CODE, session_id, class_id, section_id },
      })
      .then(({ data }) => {
        setLoading(false);
        if (data.api_status === "OK") {
          setMClassSubject(data.data || []);
        } else {
          myToast.notify_warning(data.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("API error:", error);
      });
  };

  return (
    <div className="content" style={{ background: COLORS.white }}>
      <div className="content-header">
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li>
              <i className="fa fa-home" aria-hidden="true" />
              <a href="#"> Class Subject </a>
            </li>
          </ul>
        </div>
      </div>

      <div
        className="bg_page_grey_rounded box_shadow"
        style={{ "--mMargin": "50px" }}
      >
        <SelectClass action={callBackClassSelect} selected_position="0" />

        <div className="row">
          {mClassSubject.map((item, index) => (
            <div className="col-12 New_classsubject" key={index}>
              <div className="New_classsubject-box">
                <div className="New_classsubject-details">
                  <strong>Subject Name:</strong> {item.subject_name}
                  <br />
                  <strong>Subject Type:</strong> {item.subject_type}
                </div>
                <div className="New_classsubject-actions">
                  <button
                    className="New_classsubject-edit-btn"
                    title="Edit"
                    onClick={() => toggleEditSubjectDialog(item)}
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                  <button
                    className="New_classsubject-delete-btn"
                    title="Delete"
                    onClick={() => toggleDeleteSubjectDialog(item)}
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          ))}

          <div className="row">
            <button
              className="btn btn-success"
              onClick={toggleAddSubjectDialog}
              style={{ fontSize: "21px", width: "100%" }}
            >
              Add Class Subject
            </button>
          </div>

          {isOpenAddSubjectDialog && (
            <MdlAddClassSubjectList
              actionClose={actionCloseAdd}
              class_id={CLASS_ID}
              class_name={CLASS_NAME}
              class_subject={mClassSubject}
              section_id={SECTION_ID}
              session_id={SESSION_ID}
            />
          )}
          {isOpenEditSubjectDialog && (
            <MdlEditClassSubject
              actionClose={actionCloseEdit}
              subject={selectedSubject}
              session_id={SESSION_ID}
              section_id={SECTION_ID}
            />
          )}
          {isOpenDeleteSubjectDialog && (
            <MdlDeleteClassSubject
              actionClose={toggleDeleteSubjectDialog}
              subject={selectedSubject}
            />
          )}
        </div>
      </div>

      {loading && <MyLoader />}
    </div>
  );
};

export default ClassSubject;
