import React, { Component } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import UpdateSchoolDetails from "./UpdateSchoolDetails";
import myToast from "../Utils/MyToast";
export default class SchoolProfile extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      mSchoolProfile: [],
    };
  }
  componentDidMount() {
    this.SchoolProfile();
  }
  SchoolProfile() {
    this.setState({
      Loading: true,
    });
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getSchoolProfile.php";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    console.log("URL>>>>>" + URL);
    // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);

    axios
      .get(URL, {
        params: { school_code: SCHOOL_CODE },
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          Loading: false,
        });
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {
          this.setState({
            mSchoolProfile: response.data,
          });
          console.log(
            "response>>>>>" + JSON.stringify(this.state.mSchoolProfile)
          );
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        console.log("error>>>>>" + error);
      });
  }
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to="/Login" />;
    }
    if (this.state.Loading) {
      return <MyLoader />;
    } else {
      return (
        <Router>
          <switch>
            <Route
              path="/AdminMain/SchoolProfile/:module_id"
              exact
              render={() => {
                return (
                  <div className="content">
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li>
                            <i className="fa fa-home" aria-hidden="true" />
                            <a href="#"> School Profile </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {this.state.mSchoolProfile.map((item, index) => (
                      <div
                        className="animated fadeInUpn bg_page_white_rounded box_shadow"
                        style={{ "--mMargin": "50px" }}
                      >
                        <div className="school-profile-container">
                          <div className="school-profile-header">
                            <div className="school-profile-logo-container">
                              <img
                                src={item.school_logo_path}
                                alt={item.school_name}
                                className="school-profile-logo"
                              />
                              <div className="school-profile-camera-icon">
                                <img
                                  src="../resources/icons/icons_camera_100_white.png"
                                  alt="Camera Icon"
                                  className="camera-icon"
                                />
                              </div>
                            </div>

                            <div className="school-profile-info">
                              <h2 className="school-profile-name">
                                {item.school_name}
                              </h2>
                              <p className="school-profile-slogan">
                                {item.school_tag_line}
                              </p>
                              <p className="school-profile-details">
                                {item.school_address}
                              </p>
                            </div>
                          </div>

                          <div className="school-profile-metadata-container">
                            <div className="school-profile-metadata-box">
                              <p>
                                <strong>School Code:</strong> {item.school_code}
                              </p>
                              <p>
                                <strong>Estb Year:</strong>{" "}
                                {item.school_estb_year}
                              </p>
                            </div>
                            <div className="school-profile-metadata-box">
                              <p>
                                <strong>Reg No:</strong> {item.school_reg_no}
                              </p>
                              <p>
                                <strong>Udise No:</strong> {item.udise_no}
                              </p>
                            </div>
                          </div>

                          <div className="school-profile-contact-container">
                            <div className="school-profile-contact-box">
                              <p>
                                <strong>Contact 1:</strong>+91{" "}
                                {item.officail_contact_no1}
                              </p>
                              <p>
                                <strong>Contact 2:</strong>+91{" "}
                                {item.officail_contact_no2}
                              </p>
                            </div>
                            <div className="school-profile-contact-box">
                              <p>
                                <strong>Email:</strong> {item.officail_email}
                              </p>
                              <p>
                                <strong>Website:</strong> {item.website}
                              </p>
                            </div>
                          </div>

                          <div className="school-profile-actions">
                            <Link
                              to={{
                                pathname:
                                  "/AdminMain/UpdateSchoolDetails/" +
                                  item.school_name +
                                  "/" +
                                  item.school_address +
                                  "/" +
                                  item.school_tag_line +
                                  "/" +
                                  item.udise_no +
                                  "/" +
                                  item.school_reg_no +
                                  "/" +
                                  item.school_estb_year +
                                  "/" +
                                  item.officail_contact_no1 +
                                  "/" +
                                  item.officail_contact_no1 +
                                  "/" +
                                  item.officail_email +
                                  "/" +
                                  item.website,
                              }}
                            >
                              <button className="school-profile-button">
                                Edit School Profile
                              </button>
                            </Link>
                            <button className="school-profile-button">
                              Share School Profile
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                );
              }}
            />
            <Route
              path="/AdminMain/UpdateSchoolDetails/:school_name/:school_address/:school_tag_line/:udise_no/:school_reg_no/:school_estb_year/:officail_contact_no1/:officail_contact_no2/:officail_email/:website/"
              component={UpdateSchoolDetails}
            />
          </switch>
        </Router>
      );
    }
  }
}
