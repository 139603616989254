import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Alert } from "react-bootstrap";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import CustomAlert from "./CustomAlert";
import { toast } from "react-toastify";

const MdlAddPaper = ({
  actionClose,
  actionAdd,
  exam_id,
  exam_name,
  session_id,
  class_id,
  section_id,
  subject_id,
  subject_name,
  class_section_name,
}) => {
  const [loggedIn] = useState(localStorage.getItem("token") !== null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [ExamId, setExamId] = useState(exam_id);
  const [ExamName, setExamName] = useState(exam_name);
  const [SessionId, setSessionId] = useState(session_id);
  const [ClassId, setClassId] = useState(class_id);
  const [SectionId, setSectionId] = useState(section_id);
  const [SubjectId, setSubjectId] = useState(subject_id);
  const [paperName, setPaperName] = useState("");
  const [Ismarksystem, setIsmarksystem] = useState("1");
  const [Isgradesystem, setIsgradesystem] = useState("0");
  const [maxMarks, setMaxMarks] = useState();
  const [maxGrade, setMaxGrade] = useState();
  const [paperType, setPaperType] = useState("Select Paper");
  const [PaperCode, setPaperCode] = useState(0);

  useEffect(() => {
    //alert(JSON.stringify(subject_id));
    setPaperName(subject_name);
  }, []);

  const setMarkingSystem = (system) => {
    if (system === "marks") {
      setIsmarksystem("1");
      setIsgradesystem("0");
    } else {
      setIsmarksystem("0");
      setIsgradesystem("1");
    }
  };
  const setPCode = (value, paper_code) => {
    setPaperType(value);
    setPaperCode(paper_code);
  };

  const handleClose = () => {
    setShow(false);
    actionClose();
  };

  const handleSubmit = async () => {
    if (!paperName) {
      myToast.notify_error("Enter Valid Paper Name!");
      //alert("Enter Valid Paper Name");
      return;
    } else if (PaperCode == 0) {
      myToast.notify_error("Select Paper Type !");
      return;
    } else if (Ismarksystem === "1") {
      if (!maxMarks) {
        myToast.notify_error("Enter Max mark !");
        return;
      }
    } else if (Isgradesystem === "1") {
      if (!maxGrade) {
        myToast.notify_error("Enter Max grade !");
        return;
      }
    }
    AddNewExamPaper();
  };
  const AddNewExamPaper = async () => {
    setLoading(true);
    try {
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      var mStaffData = SessionManager.getStaffDetails();
      var STAFF_ID = mStaffData.staff_id;
      const FUNCTION_NAME = "AddNewExamPaper.php";
      const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

      const params = {
        school_code: SCHOOL_CODE,
        staff_id: STAFF_ID,
        exam_id: ExamId,
        session_id: SessionId,
        exam_name: ExamName,
        class_id: ClassId,
        section_id: SectionId,
        subject_id: SubjectId,
        paper_name: paperName,
        is_mark_system: Ismarksystem,
        is_grade_system: Isgradesystem,
        max_mark: maxMarks || " ",
        max_grade: maxGrade || " ",
        paper_type: paperType,
        paper_code: PaperCode,
      };

      const queryString = new URLSearchParams(params).toString();
      console.log("Full URL: ", `${URL}${queryString}`);
      const response = await axios.get(URL, {
        params: params,
      });

      if (response.data.api_status === "OK") {
        myToast.notify_success("Paper Added successfully!");
        actionAdd();
      } else {
        myToast.notify_warning(
          response.data.api_remark || "Rename failed. Please try again."
        );
      }
    } catch (error) {
      console.error("Rename error:", error);
      myToast.notify_error("An error occurred while renaming the class.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        className="mdladdpaper-modal-header d-flex justify-content-between align-items-center"
      >
        <div className="mdladdpaper-title">Create Exam Paper</div>
        <div className="mdladdpaper-subtitle ms-3">
          New Paper: {class_section_name}
        </div>
      </Modal.Header>

      <Modal.Body className="mdladdpaper-modal-body">
        <Form className="mdladdpaper-form">
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Exam Paper Name</Form.Label>
                <Form.Control
                  type="text"
                  value={paperName}
                  onChange={(e) => setPaperName(e.target.value)}
                  placeholder="Enter Paper Name"
                  className="mdladdpaper-input"
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Paper Type</Form.Label>
                <Form.Control
                  as="select"
                  value={paperType}
                  onChange={(e) => {
                    const selectedOption =
                      e.target.options[e.target.selectedIndex];
                    setPCode(e.target.value, selectedOption.dataset.paper_code);
                  }}
                  className="mdladdpaper-select"
                >
                  <option value="">Select Paper</option>
                  <option value="Written" data-paper_code="1">
                    Written
                  </option>
                  <option value="Oral" data-paper_code="2">
                    Oral
                  </option>
                  <option value="Theory" data-paper_code="3">
                    Theory
                  </option>
                  <option value="Practical" data-paper_code="4">
                    Practical
                  </option>
                  <option value="Project" data-paper_code="5">
                    Project
                  </option>
                  <option value="Test" data-paper_code="6">
                    Test
                  </option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Marking System</Form.Label>
                <div className="mdladdpaper-marking-system">
                  <Form.Check
                    type="radio"
                    label="Marks System"
                    name="markingSystem"
                    value="marks"
                    checked={Ismarksystem === "1"}
                    onChange={() => setMarkingSystem("marks")}
                    className="mdladdpaper-radio"
                  />
                  <Form.Check
                    type="radio"
                    label="Grade System"
                    name="markingSystem"
                    value="grade"
                    checked={Isgradesystem === "1"}
                    onChange={() => setMarkingSystem("grade")}
                    className="mdladdpaper-radio"
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {Ismarksystem === "1" ? (
                <Form.Group>
                  <Form.Label>Max Marks</Form.Label>
                  <Form.Control
                    type="text"
                    value={maxMarks}
                    onChange={(e) => setMaxMarks(e.target.value)}
                    placeholder="Enter Max Marks"
                    className="mdladdpaper-input"
                  />
                </Form.Group>
              ) : (
                <Form.Group>
                  <Form.Label>Max Grade</Form.Label>
                  <Form.Control
                    type="text"
                    value={maxGrade}
                    onChange={(e) => setMaxGrade(e.target.value)}
                    placeholder="Enter Max Grade"
                    className="mdladdpaper-input"
                  />
                </Form.Group>
              )}
            </Col>
          </Row>
        </Form>
      </Modal.Body>

      {/* Modal Footer */}
      <Modal.Footer className="mdladdpaper-modal-footer">
        <Button
          variant="primary"
          className="mdladdpaper-btn mdladdpaper-btn-primary"
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Button
          variant="secondary"
          className="mdladdpaper-btn mdladdpaper-btn-secondary"
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MdlAddPaper;
