import React, { Component } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import SendNotification from "./SendNotification";
import myToast from "../Utils/MyToast";
export default class Notification extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      mNotification: [],
    };
  }
  componentDidMount() {
    this.Notification();
  }
  Notification() {
    this.setState({
      Loading: true,
    });
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    var mSession = SessionManager.getCurrentSession();
    const SESSION_ID = mSession[0].session_id;
    const Lim = "30";
    const Offset = "0";
    const FUNCTION_NAME = "getAllNotifications.php";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    console.log("URL>>>>>" + URL);
    axios
      .get(URL, {
        params: {
          session_id: SESSION_ID,
          limit: Lim,
          offset: Offset,
          school_code: SCHOOL_CODE,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          Loading: false,
        });
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {
          this.setState({
            mNotification: response.notification,
          });
          console.log(
            "response>>>>>" + JSON.stringify(this.state.mNotification)
          );
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        console.log("error>>>>>" + error);
      });
  }
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to="/Login" />;
    }
    if (this.state.Loading) {
      return <MyLoader />;
    } else {
      return (
        <Router>
          <switch>
            <Route
              path="/AdminMain/Notification/:module_id"
              exact
              render={() => {
                return (
                  <div className="content">
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li>
                            <i className="fa fa-home" aria-hidden="true" />
                            <a href="#"> Notification </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="animated fadeInUpn bg_page_white_rounded box_shadow"
                      style={{ "--mMargin": "50px" }}
                    >
                      <div className="notification-container">
                        <p className="intro-text">
                          These Notifications are given from students' parents
                          as suggestions, queries, and feedback about the school
                          and information.
                        </p>

                        <div className="notifications">
                          {this.state.mNotification.map((item, index) => (
                            <div className="notification" key={index}>
                              <div className="notification-header">
                                <h4 className="notification-title">
                                  {item.notification_title}
                                </h4>
                                <span className="notification-date">
                                  {item.date_added}
                                </span>
                              </div>
                              <div className="notification-content">
                                <p>{item.notification}</p>
                              </div>
                              <div className="notification-footer">
                                <span className="department">
                                  Regards: {item.department}
                                </span>
                                <span className="recipient">
                                  Recipient: {item.recipient_id}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="send-message">
                          <Link to="/AdminMain/SendNotification">
                            <button className="notification_button">
                              Send Message
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            />

            <Route
              path="/AdminMain/SendNotification"
              component={SendNotification}
            />
          </switch>
        </Router>
      );
    }
  }
}
