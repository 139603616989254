import React from "react";
import Login from "./login";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AdminMain from "./Admin/AdminMain";
import StaffPrivilege from "./Admin/StaffPrivilege";
import "@fortawesome/fontawesome-free/css/all.min.css";

require("dotenv").config();

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/AdminMain" component={AdminMain} />
      </Switch>
    </Router>
  );
}

export default App;
