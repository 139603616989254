import React, { Component } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import CommentReply from "./CommentReply";
import myToast from "../Utils/MyToast";
export default class Comment extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      mComment: [],
      mReply: [],
    };
  }
  componentDidMount() {
    this.Comment();
  }
  Comment() {
    this.setState({
      Loading: true,
    });
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const Sess = "SSN0001";
    const Lim = "30";
    const Offset = "0";
    const FUNCTION_NAME = "getAllComments.php";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    console.log("URL>>>>>" + URL);
    axios
      .get(URL, {
        params: {
          session_id: Sess,
          limit: Lim,
          offset: Offset,
          school_code: SCHOOL_CODE,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          Loading: false,
        });
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {
          this.setState({
            mComment: response.comment,
          });
          console.log("response>>>>>" + JSON.stringify(this.state.mComment));
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        console.log("error>>>>>" + error);
      });
  }
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to="/Login" />;
    }
    if (this.state.Loading) {
      return <MyLoader />;
    } else {
      return (
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li>
                  <i className="fa fa-home" aria-hidden="true" />
                  <a href="#"> Parents Comment </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="Comment">
            <div className="Comment-header">
              <h1>Parents' Comments</h1>
            </div>
            <div className="Comment-description">
              These comments are suggestions, queries, and feedback from parents
              about the school.
            </div>
            <div className="Comment-list">
              {this.state.mComment.map((item) => (
                <div key={item.comment_id} className="Comment-item">
                  <div className="Comment-item-header">
                    <h5>{item.date_added}</h5>
                  </div>
                  <div className="Comment-item-title">{item.comment_title}</div>
                  <div className="Comment-item-body">{item.comment}</div>
                  <div className="Comment-item-footer">
                    <span>TO: {item.comment_for}</span>
                    <span>
                      FROM: {item.sender_name} ({item.sender_id})
                    </span>
                  </div>
                  <Link
                    to={{
                      pathname: "/SuperAdminDash/CommentReply",
                      data: { Cmtcur_id: item.comment_id },
                    }}
                    className="Comment-reply-button"
                  >
                    <img
                      src="https://www.materialui.co/materialIcons/content/reply_all_grey_192x192.png"
                      alt="Reply"
                    />
                  </Link>
                  {item.reply && (
                    <div className="Comment-recent-reply">
                      <h6>Recent Replies</h6>
                      {item.reply.map((reply) => (
                        <div
                          key={reply.reply_id}
                          className="Comment-reply-item"
                        >
                          <img src={reply.staff_image_path} alt="Staff" />
                          <div className="Comment-reply-details">
                            <h5>From: {reply.sender_name}</h5>
                            <h6>{reply.comment}</h6>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <Router>
            <switch>
              <Route path="/SuperAdminDash/Comment" render={() => {}} />

              <Route
                path="/SuperAdminDash/CommentReply"
                component={CommentReply}
              />
            </switch>
          </Router>
        </div>
      );
    }
  }
}
