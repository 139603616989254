import React, { useState, useEffect } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import ChangePassword from "./ChangePassword";
import myToast from "../Utils/MyToast";
import "../css/Admin_css/admin-customize.css";

const MyProfile = () => {
  const [loggedIn, setLoggedIn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [myProfile, setMyProfile] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token == null) {
      setLoggedIn(false);
    }
    fetchProfile();
  }, []);

  const fetchProfile = () => {
    setLoading(true);
    const staffId = "STF000111";
    const FUNCTION_NAME = "getStaffProfile.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    axios
      .get(URL, {
        params: {
          staff_id: staffId,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        if (response.api_status === "OK") {
          setMyProfile(response.data);
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("error>>>>>", error);
      });
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  if (loading) {
    return <MyLoader />;
  }

  return (
    <Router>
      <switch>
        <Route
          path="/AdminMain/MyProfile/:module_id"
          exact
          render={() => (
            <div className="content">
              <div className="content-header">
                <div className="leftside-content-header">
                  <ul className="breadcrumbs">
                    <li>
                      <i className="fa fa-home" aria-hidden="true" />
                      <a href="#"> My Profile </a>
                    </li>
                  </ul>
                </div>
              </div>
              {myProfile.map((item, index) => (
                <div className="profile-box" key={index}>
                  <div className="profile-image">
                    <img src={item.staff_image_path} alt="staff" />
                  </div>
                  <div className="profile-details">
                    <div className="staff-basic-info">
                      <div className="info-item">
                        <strong className="label_myprofile">Staff Name:</strong>{" "}
                        <span className="data">{item.staff_name}</span>
                      </div>
                      <div className="info-item">
                        <strong className="label_myprofile">Department:</strong>{" "}
                        <span className="data">{item.department_name}</span>
                      </div>
                      <div className="info-item">
                        <strong className="label_myprofile">Role:</strong>{" "}
                        <span className="data">{item.staff_role}</span>
                      </div>
                    </div>
                    <div className="staff-info-row">
                      <p>
                        <strong>Joining Date:</strong> {item.joining_date}
                      </p>
                      <p>
                        <strong>Staff Degree:</strong>{" "}
                        {item.qualification[0].staff_degree}
                      </p>
                      <p>
                        <strong>Staff Percentage:</strong>{" "}
                        {item.qualification[0].staff_percentage}
                      </p>
                    </div>
                    <div className="address-full">
                      <p>
                        <strong>Address:</strong> {item.staff_address}
                      </p>
                    </div>
                    <Link to={{ pathname: "/AdminMain/ChangePassword" }}>
                      <button className="btn btn-info change-password-btn">
                        Change Password
                      </button>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          )}
        />
        <Route path="/AdminMain/ChangePassword" component={ChangePassword} />
      </switch>
    </Router>
  );
};

export default MyProfile;
