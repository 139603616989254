import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import StudentList from "../cms/StudentList";
import myToast from "../Utils/MyToast";
import uploadIcon from "../resources/images/upload-icon.png";
import MdlSelectSession from "../dialog/MdlSelectSession";
import MdlAddNewClass from "../dialog/MdlAddNewClass";
import MdlAddSection from "../dialog/MdlAddSection";
import MdlAddClassTeacher from "../dialog/MdlAddClassTeacher";
import MdlRenameClass from "../dialog/MdlRenameClass";
import COLORS from "../resources/colors/COLORS";

const ClassSettings = (props) => {
  const [isOpenSessionDialog, setIsOpenSessionDialog] = useState(false);
  const [isOpenAddSection, setIsOpenAddSection] = useState(false);
  const [isOpenAddNewClass, setIsOpenAddNewClass] = useState(false);
  const [isOpenAddClassTeacher, setIsOpenAddClassTeacher] = useState(false);
  const [isOpenRenameClass, setIsOpenRenameClass] = useState(false);
  const [loggedIn, setLoggedIn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [sessionStatus, setSessionStatus] = useState("");
  const [mClassData, setMClassData] = useState([]);
  const [mClassBoard, setMClassBoard] = useState([]);
  const [mClassMedium, setMClassMedium] = useState([]);
  const [mFilteredClassSectionList, setMFilteredClassSectionList] = useState(
    []
  );
  const [mAllSection, setMAllSection] = useState([]);
  const [mStudentCount, setMStudentCount] = useState([]);
  const [selectedBoardId, setSelectedBoardId] = useState("");
  const [selectedMediumId, setSelectedMediumId] = useState("");
  const [selectedClass, setselectedClass] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      setLoggedIn(false);
      return;
    }

    const mSession = SessionManager.getCurrentSession();
    if (mSession && mSession.length > 0) {
      setSessionId(mSession[0].session_id);
      setSessionName(mSession[0].session_name);
      setSessionStatus(mSession[0].status);
    }
  }, []);

  useEffect(() => {
    if (sessionId) {
      getClass();
    }
  }, [sessionId]);

  const getClass = useCallback(async () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getClassWithStudentCount.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    try {
      const response = await axios.get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          session_id: sessionId,
        },
      });
      const data = response.data;
      setLoading(false);
      if (data.api_status === "OK") {
        setMClassData(data.data.class);
        setMClassBoard(data.data.board);
        setMClassMedium(data.data.medium);
        setMAllSection(data.data.all_section);
        setMStudentCount(data.data.student_count);
        if (data.data.board.length > 0) {
          setSelectedBoardId(data.data.board[0].board_id);
        }
        if (data.data.medium.length > 0) {
          setSelectedMediumId(data.data.medium[0].medium_id);
        }
      } else {
        myToast.notify_warning(data.api_remark);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching class data:", error);
    }
  }, [sessionId]);

  useEffect(() => {
    if (
      !selectedBoardId ||
      !selectedMediumId ||
      !mClassData.length ||
      !mAllSection.length
    )
      return;

    const filtered = mClassData
      .filter(
        (item) =>
          item.board_id === selectedBoardId &&
          item.medium_id === selectedMediumId
      )
      .flatMap((item) => {
        const sections = mAllSection.filter(
          (s) => s.class_id === item.class_id
        );
        return sections.length
          ? sections.map((s) => ({
              ...item,
              ...s,
              student:
                mStudentCount.find(
                  (sc) =>
                    sc.class_id === item.class_id &&
                    sc.section_id === s.section_id
                )?.total_student || 0,
            }))
          : [{ ...item, section_id: "No", student: 0 }];
      });

    setMFilteredClassSectionList(filtered);
    //alert(JSON.stringify(filtered));
  }, [
    selectedBoardId,
    selectedMediumId,
    mClassData,
    mAllSection,
    mStudentCount,
  ]);

  const handleSessionDialog = () =>
    setIsOpenSessionDialog(!isOpenSessionDialog);
  const handleAddNewClass = () => setIsOpenAddNewClass(!isOpenAddNewClass);
  const handleAddSection = () => setIsOpenAddSection(!isOpenAddSection);

  const handleAddClassTeacher = (item) => {
    setIsOpenAddClassTeacher(!isOpenAddClassTeacher);
    setselectedClass(item);
  };
  const handleRenameClass = (item) => {
    setIsOpenRenameClass(!isOpenRenameClass);
    setselectedClass(item);
    getClass();
  };

  if (!loggedIn) return <Redirect to="/Login" />;
  if (loading) return <MyLoader />;

  return (
    <Router>
      <Route
        path="/AdminMain/ClassSettings"
        exact
        render={() => (
          <div className="content">
            <div className="content-header">
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li>
                    <i className="fa fa-home" aria-hidden="true" />
                    <a href="#">Class List</a>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="bg_page_white_rounded box_shadow animated fadeInUp"
              style={{ "--mMargin": "30px" }}
            >
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <Link to="#" onClick={handleSessionDialog}>
                    <p className="bg_card_blue" style={sessionHeaderStyle}>
                      Session: {`${sessionName}(${sessionStatus})`}
                      <span style={{ float: "right" }}>
                        <img
                          src={uploadIcon}
                          alt="upload"
                          style={uploadIconStyle}
                        />
                      </span>
                    </p>
                  </Link>
                </div>
              </div>

              <div className="row m-0">
                <div className="col-sm-6 col-md-6">
                  <select
                    className="bg_card_blue width_match_parent h_35"
                    value={selectedBoardId}
                    onChange={(e) => setSelectedBoardId(e.target.value)}
                  >
                    {mClassBoard.map((item) => (
                      <option key={item.board_id} value={item.board_id}>
                        {item.board_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-6 col-md-6">
                  <select
                    className="bg_card_blue width_match_parent h_35"
                    value={selectedMediumId}
                    onChange={(e) => setSelectedMediumId(e.target.value)}
                  >
                    {mClassMedium.map((item) => (
                      <option key={item.medium_id} value={item.medium_id}>
                        {item.medium_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-sm-12 col-md-12">
                  {mFilteredClassSectionList.map((item, index) => (
                    <div className="static-class-box" key={index}>
                      <div className="class-header">
                        <span className="static-name">
                          {item.class_name}{" "}
                          {item.section_name ? `- ${item.section_name}` : ""}
                        </span>
                        <span className="student-count">
                          Students: {item.student}
                        </span>

                        <div className="static-header-buttons">
                          <button
                            className="btn btn-primary"
                            onClick={handleAddSection}
                          >
                            {" "}
                            Add Section
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={() => handleAddClassTeacher(item)}
                          >
                            Add Class Teacher
                          </button>
                          <button
                            className="btn btn-warning"
                            onClick={() => handleRenameClass(item)}
                          >
                            {" "}
                            Rename Class{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="">
                  <button
                    className="btn btn-success varun_btn"
                    onClick={handleAddNewClass}
                  >
                    Add New Class
                  </button>
                </div>
              </div>

              {isOpenSessionDialog && (
                <MdlSelectSession
                  action={handleSessionDialog}
                  action2={(id, name, status) => {
                    setSessionId(id);
                    setSessionName(name);
                    setSessionStatus(status);
                    handleSessionDialog();
                  }}
                />
              )}

              {isOpenAddSection && (
                <MdlAddSection
                  actionClose={handleAddSection}
                  action2={handleAddSection}
                />
              )}
              {isOpenAddNewClass && (
                <MdlAddNewClass
                  actionClose={handleAddNewClass}
                  action2={handleAddNewClass}
                />
              )}
              {isOpenAddClassTeacher && (
                <MdlAddClassTeacher
                  actionClose={handleAddClassTeacher}
                  action2={handleAddClassTeacher}
                  selectedClass={selectedClass}
                />
              )}
              {isOpenRenameClass && (
                <MdlRenameClass
                  actionClose={handleRenameClass}
                  action2={handleRenameClass}
                  selectedClass={selectedClass}
                />
              )}
            </div>
          </div>
        )}
      />
      <Route
        path="/AdminMain/StudentList/:class_id/:section_id"
        component={StudentList}
      />
    </Router>
  );
};

const sessionHeaderStyle = {
  padding: "1px 5px",
  fontSize: "18px",
  textAlign: "center",
  color: COLORS.white,
};

const uploadIconStyle = {
  height: "25px",
  width: "25px",
};

const addClassButtonStyle = {
  margin: "3% 0%",
  fontSize: "21px",
  width: "98%",
};

export default ClassSettings;
