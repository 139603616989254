import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import MyLoader from "../Spinner/MyLoader";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import axios from "axios";
import SessionManager from "../Utils/SessionManager";
import AddSessionSettings from "./AddSessionSettings";

const SessionSettings = () => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem("token"));

  useEffect(() => {
    getSessionList();
  }, []);

  const getSessionList = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getSessionList.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    axios
      .get(URL, {
        params: { school_code: SCHOOL_CODE },
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);

        if (response.api_status === "OK") {
          setSessions(response.data);
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  if (loading) {
    return <MyLoader />;
  }

  return (
    <Router>
      <Switch>
        <Route
          path="/AdminMain/SessionSettings"
          exact
          render={() => {
            return (
              <div className="content">
                <div className="content-header">
                  <div className="leftside-content-header">
                    <ul className="breadcrumbs">
                      <li>
                        <i className="fa fa-home" aria-hidden="true" />
                        <a href="#">Session Settings</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  className="bg_page_white_rounded box_shadow animated fadeInUp"
                  style={{ "--mMargin": "30px" }}
                >
                  <h2 className="session-heading">Session Details</h2>
                  {loading && <MyLoader />}
                  <div className="session-list">
                    {sessions.map((session) => (
                      <div className="session-card" key={session.session_id}>
                        <div className="session-row">
                          <div className="session-col">
                            <label>Session Name</label>
                            <div className="session-value">
                              {session.session_name}
                            </div>
                          </div>
                          <div className="session-col">
                            <label>Start Date</label>
                            <div className="session-value">
                              {session.start_date}
                            </div>
                          </div>
                          <div className="session-col">
                            <label>End Date</label>
                            <div className="session-value">
                              {session.end_date}
                            </div>
                          </div>
                          <div className="session-col">
                            <label>Session Status</label>
                            <div
                              className={`session-value SSettings-status ${session.status.toLowerCase()}`}
                            >
                              {session.status}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="submit-container">
                      <Link to="/AdminMain/AddSessionSettings">
                        <button className="btn btn-success varun_btn">
                          Add New Session
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        />
        <Route
          path="/AdminMain/AddSessionSettings"
          component={AddSessionSettings}
        />
      </Switch>
    </Router>
  );
};

export default SessionSettings;
