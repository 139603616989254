import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import myToast from "../Utils/MyToast";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";

const MdlEditPaper = ({
  actionClose,
  paper,
  exam_id,
  exam_name,
  actionUpdate,
}) => {
  const [loggedIn] = useState(localStorage.getItem("token") !== null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [paperName, setPaperName] = useState();
  const [paperNameNew, setPaperNameNew] = useState();
  const [paperType, setPaperType] = useState();
  const [PaperCode, setPaperCode] = useState(0);
  const [maxMarks, setMaxMarks] = useState();
  const [SessionId, setSessionId] = useState();
  const [ExamId, setExamId] = useState(exam_id);
  const [ExamName, setExamName] = useState(exam_name);
  const [ExamPaperId, setExamPaperId] = useState();
  const [IsmarkSystem, setIsmarkSystem] = useState();
  const [MaxmarkOrGrade, setMaxmarkOrGrade] = useState();
  const [MaxmarkOrGradeNew, setMaxmarkOrGradeNew] = useState();

  useEffect(() => {
    //alert(JSON.stringify(paper));
    //alert(JSON.stringify(exam_id));
    setSessionId(paper.session_id);
    setExamPaperId(paper.exam_paper_id);
    setPaperName(paper.exam_paper_name);
    setPaperNameNew(paper.exam_paper_name);
    setPaperType(paper.paper_type);
    setMaxMarks(paper.max_mark);
    setIsmarkSystem(paper.is_mark_system);

    if (paper.is_mark_system === "1") {
      //alert(JSON.stringify(paper.max_mark));
      setMaxmarkOrGrade(paper.max_mark);
      setMaxmarkOrGradeNew(paper.max_mark);
    } else {
      //alert(JSON.stringify(paper.max_grade));
      setMaxmarkOrGrade(paper.max_grade);
      setMaxmarkOrGradeNew(paper.max_grade);
    }
  }, []);

  const handleClose = () => {
    setShow(false);
    actionClose();
  };
  const setPCode = (value, paper_code) => {
    setPaperType(value);
    setPaperCode(paper_code);
  };
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      var mStaffData = SessionManager.getStaffDetails();
      var STAFF_ID = mStaffData.staff_id;
      const FUNCTION_NAME = "updateExamPaper.php";
      const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

      const params = {
        school_code: SCHOOL_CODE,
        staff_id: STAFF_ID,
        exam_id: ExamId,
        session_id: SessionId,
        exam_name: ExamName,
        exam_paper_id: ExamPaperId,
        is_mark_system: IsmarkSystem,
        max_mark_or_grade: MaxmarkOrGrade,
        max_mark_or_grade_new: MaxmarkOrGradeNew,
        paper_name: paperName,
        paper_name_new: paperNameNew,
      };

      const queryString = new URLSearchParams(params).toString();
      console.log("Full URL: ", `${URL}${queryString}`);
      const response = await axios.get(URL, {
        params: params,
      });

      if (response.data.api_status === "OK") {
        alert("Edit Paper  Update successfully!");
        actionUpdate();
      } else {
        myToast.notify_warning(
          response.data.api_remark || "Rename failed. Please try again."
        );
      }
    } catch (error) {
      console.error("Rename error:", error);
      myToast.notify_error("An error occurred while renaming the class.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Paper</Modal.Title>
      </Modal.Header>

      <Modal.Body className="MdlEditPaper-modal-body">
        <Form>
          <div className="row">
            <Form.Group className="col-md-6 MdlEditPaper-form-group">
              <Form.Label className="MdlEditPaper-form-label">
                Paper Name:
              </Form.Label>
              <Form.Control
                className="MdlEditPaper-form-control"
                type="text"
                value={paperNameNew}
                onChange={(e) => setPaperNameNew(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="col-md-6 MdlEditPaper-form-group">
              <Form.Label className="MdlEditPaper-form-label">
                Paper Type:
              </Form.Label>
              <Form.Control
                className="MdlEditPaper-form-control"
                as="select"
                value={paperType}
                onChange={(e) => {
                  const selectedOption =
                    e.target.options[e.target.selectedIndex];
                  setPCode(e.target.value, selectedOption.dataset.paper_code);
                }}
              >
                <option value="">Select Paper Type</option>
                <option value="Written" data-paper_code="1">
                  Written
                </option>
                <option value="Oral" data-paper_code="2">
                  Oral
                </option>
                <option value="Theory" data-paper_code="3">
                  Theory
                </option>
                <option value="Practical" data-paper_code="4">
                  Practical
                </option>
                <option value="Project" data-paper_code="5">
                  Project
                </option>
                <option value="Test" data-paper_code="6">
                  Test
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="col-md-6 MdlEditPaper-form-group">
              <Form.Label className="MdlEditPaper-form-label">
                {paper.is_mark_system === "1" ? "Max Mark" : "Max Grade"}:
              </Form.Label>
              <Form.Control
                className="MdlEditPaper-form-control"
                type="text"
                value={MaxmarkOrGradeNew}
                onChange={(e) => setMaxmarkOrGradeNew(e.target.value)}
              />
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
        <Button variant="secondary" onClick={actionClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MdlEditPaper;
