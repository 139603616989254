import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import myToast from "../Utils/MyToast";

const MdlDeletePaper = ({
  actionClose,
  paper,
  exam_id,
  exam_name,
  actionDelete,
}) => {
  const [show, setShow] = useState(true);
  const [loggedIn] = useState(localStorage.getItem("token") !== null);
  const [loading, setLoading] = useState(false);
  const [paperName, setPaperName] = useState();
  const [SessionId, setSessionId] = useState();
  const [ExamPaperId, setExamPaperId] = useState();
  const [ExamId, setExamId] = useState(exam_id);
  const [ExamName, setExamName] = useState(exam_name);

  useEffect(() => {
    //alert(JSON.stringify(paper));
    setSessionId(paper.session_id);
    setExamPaperId(paper.exam_paper_id);
    setPaperName(paper.exam_paper_name);
  }, []);

  const handleClose = () => {
    setShow(false);
    actionClose();
  };

  const handleDelete = async () => {
    //console.log(`Deleted Paper: ${paper.name}`);
    setLoading(true);
    try {
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      var mStaffData = SessionManager.getStaffDetails();
      var STAFF_ID = mStaffData.staff_id;
      const FUNCTION_NAME = "deleteExamPaper.php";
      const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

      const params = {
        school_code: SCHOOL_CODE,
        staff_id: STAFF_ID,
        session_id: SessionId,
        exam_id: ExamId,
        exam_paper_id: ExamPaperId,
        exam_paper_name: paperName,
      };

      const queryString = new URLSearchParams(params).toString();
      console.log("Full URL: ", `${URL}${queryString}`);
      const response = await axios.get(URL, {
        params: params,
      });

      if (response.data.api_status === "OK") {
        myToast.notify_success("Paper Delete successfully!");
        //window.location.reload();
        actionDelete();
      } else {
        myToast.notify_warning(
          response.data.api_remark || "Rename failed. Please try again."
        );
      }
    } catch (error) {
      console.error("Rename error:", error);
      myToast.notify_error("An error occurred while renaming the class.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Paper</Modal.Title>
      </Modal.Header>

      <Modal.Body className="MdlDeletePaper-modal-body">
        <p>
          Are you sure you want to delete <strong>{paper.name}</strong>?
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
        <Button variant="secondary" onClick={actionClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MdlDeletePaper;
