import React, { Component, useRef, createRef } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import COLORS from "../resources/colors/COLORS";
import cat from "../resources/images/cat.png";
import vender from "../resources/images/vender.png";
import Product from "../resources/images/Product.png";
import publication from "../resources/images/publication.png";
import fee_icon from "../resources/icons/icons_fee_80_white.png";
import students_icon from "../resources/icons/icons_students_100_white.png";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import AccessoriesCategory from "./AccessoriesCategory";
import AccessoriesAddCategory from "./AccessoriesAddCategory";
import AccessoriesList from "./AccessoriesList";
import IssueAccessories from "./IssueAccessories";
import PublicationList from "./PublicationList";
import MyVendor from "./MyVendor";
import AddProduct from "./AddProduct";
import myToast from "../Utils/MyToast";
import AccessoriesBeneficiary from "./AccessoriesBeneficiary";
import VendorRegistration from "./VendorRegistration";
export default class Accessories extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      mAccessoriesDashBoard: [],
    };
  }
  componentDidMount() {
    this.getAccessoriesDashBoard();
  }
  getAccessoriesDashBoard() {
    this.setState({
      Loading: true,
    });
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    var mSession = SessionManager.getCurrentSession();
    const SESSION_ID = mSession[0].session_id;
    const FUNCTION_NAME = "getAccessoriesDashBoard.php";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    console.log("URL>>>>>" + URL);
    axios
      .get(URL, {
        params: { session_id: SESSION_ID, school_code: SCHOOL_CODE },
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          Loading: false,
        });
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {
          this.setState({
            mAccessoriesDashBoard: response.dashboard,
          });
          console.log(
            "response>>>>>" + JSON.stringify(this.state.mAccessoriesDashBoard)
          );
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        console.log("error>>>>>" + error);
      });
  }
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to="/Login" />;
    }
    if (this.state.Loading) {
      return <MyLoader />;
    } else {
      return (
        <Router>
          <switch>
            <Route
              path={"/AdminMain/Accessories/:module_id"}
              exact
              render={() => {
                return (
                  <div
                    className="content"
                    style={{ background: COLORS.whitesmoke }}
                  >
                    <div
                      className="content-header"
                      style={{ background: COLORS.primary_color_shade6 }}
                    >
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li>
                            <i className="fa fa-home" aria-hidden="true" />
                            <a href="#"> Accessories </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      className="bg_page_grey_rounded box_shadow"
                      style={{ "--mMargin": "20px", padding: "0px" }}
                    >
                      <div className="totalaccessories-section">
                        {/* Top Section */}
                        <div className="topaccessorie">
                          {[
                            {
                              title: "Vendor",
                              icon: vender,
                              total:
                                this.state.mAccessoriesDashBoard.total_vendor,
                              color: "var(--primary)",
                              addLink: "/AdminMain/VendorRegistration",
                              viewLink: "/AdminMain/MyVendor",
                            },
                            {
                              title: "Category",
                              icon: cat,
                              total:
                                this.state.mAccessoriesDashBoard.total_category,
                              color: "var(--blue)",
                              addLink: "/AdminMain/AccessoriesAddCategory",
                              viewLink: "/AdminMain/AccessoriesCategory",
                            },
                            {
                              title: "Product",
                              icon: Product,
                              total:
                                this.state.mAccessoriesDashBoard.total_product,
                              color: "var(--orange)",
                              addLink: "/AdminMain/AddProduct",
                              viewLink: "/AdminMain/AccessoriesList",
                            },
                            {
                              title: "In Stock",
                              icon: Product,
                              total:
                                this.state.mAccessoriesDashBoard.total_in_stock,
                              color: "var(--green)",
                              addLink: "/AdminMain/AddStock",
                              viewLink: "/AdminMain/ViewStock",
                            },
                            {
                              title: "Out of Stock Accessories",
                              icon: Product,
                              total:
                                this.state.mAccessoriesDashBoard
                                  .total_out_of_stock,
                              color: "var(--red)",
                              addLink: null, // No "Add" link for this section
                              viewLink: "/AdminMain/ViewOutOfStock",
                            },
                            {
                              title: "Publication",
                              icon: publication,
                              total:
                                this.state.mAccessoriesDashBoard
                                  .total_publication,
                              color: "var(--purple)",
                              addLink: "/AdminMain/AddPublication",
                              viewLink: "/AdminMain/PublicationList",
                            },
                          ].map((item, index) => (
                            <div
                              className="bg_card_blue_outline topaccessorie-box"
                              key={index}
                            >
                              <div className="topaccessorie-icon-container">
                                <div
                                  className="topaccessorie-icon"
                                  style={{ backgroundColor: item.color }}
                                >
                                  <img src={item.icon} alt={item.title} />
                                </div>
                                <div className="topaccessorie-text-container">
                                  <h4 className="topaccessorie-title">
                                    {item.title}
                                  </h4>
                                  <p className="topaccessorie-count">
                                    Total: {item.total}
                                  </p>
                                </div>
                              </div>
                              <div className="topaccessorie-buttons">
                                {item.addLink && (
                                  <Link to={item.addLink}>
                                    <button>{`Add ${item.title}`}</button>
                                  </Link>
                                )}
                                <Link to={item.viewLink}>
                                  <button>{`View ${item.title}`}</button>
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>

                        {/* Middle Section */}
                        <div className="middleaccessorie">
                          {[
                            {
                              color: "green",
                              icon: fee_icon,
                              link: "/AdminMain/",
                              title: "Received Amount Rs:",
                              value: "",
                            },
                            {
                              color: "red",
                              icon: fee_icon,
                              link: "/AdminMain/",
                              title: "Due Amount Rs:",
                              value: "10000",
                            },
                            {
                              color: "blue",
                              icon: students_icon,
                              link: "/AdminMain/AccessoriesBeneficiary",
                              title: "Student (Provided):",
                              value:
                                this.state.mAccessoriesDashBoard
                                  .accessories_provided_student,
                            },
                            {
                              color: "orange",
                              icon: students_icon,
                              link: "/AdminMain/IssueAccessories",
                              title: "Student (Not Provided):",
                              value:
                                this.state.mAccessoriesDashBoard
                                  .accessories_not_provided_student,
                            },
                          ].map((item, index) => (
                            <div className="middleaccessorie-box" key={index}>
                              <Link to={{ pathname: item.link }}>
                                <div
                                  className={`middleaccessorie-card ${item.color}`}
                                >
                                  <div className="middleaccessorie-icon-container">
                                    {/* Icon for the middle section */}
                                    <img
                                      src={item.icon}
                                      alt={item.title}
                                      className="middleaccessorie-icon"
                                    />
                                  </div>
                                  <p>{item.title}</p>
                                  <p>{item.value}</p>
                                </div>
                              </Link>
                            </div>
                          ))}
                        </div>

                        {/* Bottom Section */}
                        <div className="bottomaccessorie">
                          <Link to={"/AdminMain/IssueAccessories"}>
                            <button>Issue Accessories</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            />
            <Route
              path="/AdminMain/VendorRegistration"
              component={VendorRegistration}
            />
            <Route
              path="/AdminMain/AccessoriesCategory"
              component={AccessoriesCategory}
            />
            <Route
              path="/AdminMain/AccessoriesAddCategory"
              component={AccessoriesAddCategory}
            />
            <Route
              path="/AdminMain/AccessoriesList"
              component={AccessoriesList}
            />
            <Route
              path="/AdminMain/PublicationList"
              component={PublicationList}
            />
            <Route path="/AdminMain/MyVendor" component={MyVendor} />
            <Route path="/AdminMain/AddProduct" component={AddProduct} />
            <Route
              path="/AdminMain/IssueAccessories"
              component={IssueAccessories}
            />
            <Route
              path="/AdminMain/AccessoriesBeneficiary"
              component={AccessoriesBeneficiary}
            />
          </switch>
        </Router>
      );
    }
  }
}
