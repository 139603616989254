import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEdit,
  faTrash,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import "../css/Admin_css/admin-customize.css"; // Ensure this is linked correctly
import COLORS from "../resources/colors/COLORS";

const AssignHomework = ({ match }) => {
  const [loggedIn, setLoggedIn] = useState(true);
  const [homework, setHomework] = useState([]);
  const [newHomework, setNewHomework] = useState("");
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // Track button state

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token === null) {
      setLoggedIn(false);
    }
  }, [match.params]);

  const handleAddHomework = () => {
    if (newHomework.trim() === "") {
      alert("Please enter homework");
      return;
    }
    setHomework([...homework, newHomework]);
    setNewHomework("");
  };

  const handleEditHomework = (index) => {
    const updatedHomework = prompt("Edit homework:", homework[index]);
    if (updatedHomework !== null && updatedHomework.trim() !== "") {
      const newHomeworkList = [...homework];
      newHomeworkList[index] = updatedHomework;
      setHomework(newHomeworkList);
    }
  };

  const handleDeleteHomework = (index) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this homework?"
    );
    if (confirmDelete) {
      const newHomeworkList = homework.filter((_, i) => i !== index);
      setHomework(newHomeworkList);
    }
  };

  const handleImageSelect = (e) => {
    const files = Array.from(e.target.files);
    console.log("Selected files:", files);

    if (files.length > 0) {
      setIsButtonDisabled(true); // Disable button once files are selected
    }

    const previewImages = files.map((file) => URL.createObjectURL(file));
    console.log("Generated image URLs:", previewImages);
    setImages((prevImages) => [...prevImages, ...previewImages]);
  };

  const handleImageClick = (image) => {
    alert("hii");
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  return (
    <Router>
      <Route
        path={"/AdminMain/AssignHomework/"}
        exact
        render={() => (
          <div className="content" style={{ background: COLORS.white }}>
            <div className="content-header">
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li>
                    <i className="fa fa-home" aria-hidden="true" />
                    <a href="#">Transport Student</a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="bg_page_grey_rounded box_shadow"
              style={{ "--mMargin": "50px" }}
            >
              <div className="AssignHomework-container">
                {/* Header Section */}
                <div className="AssignHomework-header">
                  <div className="AssignHomework-header-content">
                    <div className="AssignHomework-class-date">
                      <div className="class-item">
                        <h4>
                          Class: <span>Play Group (English)</span>
                        </h4>
                      </div>
                      <div className="date-item">
                        <h4>
                          Date: <span>22/03/2023</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Add Homework Section */}
                <div className="AssignHomework-add-box">
                  <label htmlFor="homeworkTextarea">Add Homework</label>
                  <textarea
                    id="homeworkTextarea"
                    className="AssignHomework-textarea"
                    value={newHomework}
                    onChange={(e) => setNewHomework(e.target.value)}
                    placeholder="Add Homework here"
                  ></textarea>
                  <div
                    className="AssignHomework-add-button"
                    onClick={handleAddHomework}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                    <span>Add</span>
                  </div>
                </div>

                {/* Image Upload Section */}
                <div className="AssignHomework-image-box">
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleImageSelect}
                    className="AssignHomework-image-input"
                  />
                  <div
                    className="AssignHomework-add-button"
                    style={{ opacity: isButtonDisabled ? 0.5 : 1 }}
                  >
                    <FontAwesomeIcon icon={faImage} />
                    <span>Select Images</span>
                  </div>
                </div>

                {/* Display Uploaded Images */}
                {images.length > 0 && (
                  <div className="AssignHomework-assigned-box">
                    <h3>Images Uploaded</h3>
                    <div className="AssignHomework-images-container">
                      {images.map((image, index) => (
                        <div
                          key={index}
                          className="AssignHomework-image-item"
                          onClick={() => handleImageClick(image)}
                        >
                          <img src={image} alt={`Homework ${index}`} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Display Assigned Homework */}
                {homework.length > 0 ? (
                  <div className="AssignHomework-assigned-box">
                    <h3>Homework Assigned</h3>
                    {homework.map((item, index) => (
                      <div key={index} className="AssignHomework-homework-item">
                        <div className="AssignHomework-info">
                          <span className="AssignHomework-number">
                            {index + 1}.
                          </span>
                          <span className="AssignHomework-description">
                            {item}
                          </span>
                        </div>
                        <div className="AssignHomework-actions">
                          <FontAwesomeIcon
                            icon={faEye}
                            className="AssignHomework-icon"
                            onClick={() => alert(`Viewing: ${item}`)}
                          />
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="AssignHomework-icon"
                            onClick={() => handleEditHomework(index)}
                          />
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="AssignHomework-icon"
                            onClick={() => handleDeleteHomework(index)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="AssignHomework-not-assigned-box">
                    <h3>Homework Not Assigned</h3>
                  </div>
                )}

                {/* Modal for Viewing Full-Size Image */}
                {selectedImage && (
                  <div
                    className="AssignHomework-image-modal"
                    onClick={handleCloseModal}
                  >
                    <img
                      src={selectedImage}
                      alt="Full Size"
                      className="AssignHomework-image-full"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      />
    </Router>
  );
};

export default AssignHomework;
