import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  useParams,
  Link,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import COLORS from "../resources/colors/COLORS";
import ExamDateSheet from "./ExamDateSheet";
import ExamAdmitCard from "./ExamAdmitCard";
import ExamHallTicket from "./ExamHallTicket";
import ExamGuideline from "./ExamGuideline";
import ExamMarkingSystem from "./ExamMarkingSystem";
import ExamAddMarkStudentWise from "./ExamAddMarkStudentWise";
import ExamPaperSettings from "./ExamPaperSettings";
import ExamAddMarkSubjectWise from "./ExamAddMarkSubjectWise";

const ExamDetails = () => {
  const { exam_id, session_id, exam_name, exam_date, exam_status } =
    useParams();

  const [loggedIn, setLoggedIn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [EXAM_ID, setExamId] = useState(exam_id || "");
  const [SESSION_ID, setSessionId] = useState(session_id || "");
  const [EXAM_NAME, setExamName] = useState(
    decodeURIComponent(exam_name || "")
  );
  const [START_DATE, setStartDate] = useState(exam_date || "");
  const [EXAM_STATUS, setExamStatus] = useState(exam_status || "");
  const [CLASS_ID, setClassId] = useState("");
  const [SECTION_ID, setSectionId] = useState("");
  const [mClassList, setClassList] = useState([]);
  const [CLASS_SELECTED_POSITION, setClassSelectedPosition] = useState("");
  const [SUJECT_SELECTED_POSITION, setSubjectSelectedPosition] = useState(0);
  const [mSubject, setSubject] = useState([]);
  const [mSubjectPaper, setSubjectPaper] = useState([]);

  // Check if the user is logged in
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      setLoggedIn(false);
    }
  }, []);

  // Fetch exam participating classes when component mounts
  useEffect(() => {
    getExamParticipatingClass();
  }, [EXAM_ID, SESSION_ID]); // Runs when EXAM_ID or SESSION_ID changes

  const getExamParticipatingClass = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getClassWithSubjectsPartAndPaper.php?";
    const URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;

    console.log("Fetching data from URL: " + URL);

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          exam_id: EXAM_ID,
          session_id: SESSION_ID,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        if (response.api_status === "OK") {
          setClassList(response.data);
          setClassSelectedPosition(0);
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    if (CLASS_SELECTED_POSITION >= 0) {
      handleSelectClass(CLASS_SELECTED_POSITION);
    }
  }, [CLASS_SELECTED_POSITION]);

  const handleSelectClass = (selected_position) => {
    if (mClassList.length > 0) {
      setClassId(mClassList[selected_position]?.class_id || "");
      setSectionId(mClassList[selected_position]?.section_id || "No");

      const selectedSubjects =
        mClassList[CLASS_SELECTED_POSITION]?.subject || [];
      //alert(JSON.stringify(selectedSubjects));
      setSubject(selectedSubjects);
      setSubjectSelectedPosition(0);
    }
  };

  useEffect(() => {
    const selectedSubjects = mSubject[SUJECT_SELECTED_POSITION];
    if (selectedSubjects) {
      const selectedSubjectId = selectedSubjects.subject_id;
      getSubjectPaper(selectedSubjectId);
    } else {
      setSubjectPaper([]);
    }
  }, [SUJECT_SELECTED_POSITION, mSubject]);

  const getSubjectPaper = (selected_subject_id) => {
    const allSubjectPapers =
      mClassList[CLASS_SELECTED_POSITION]?.subject_paper || [];
    const filteredPapers = allSubjectPapers.filter(
      (paper) => paper.subject_id === selected_subject_id
    );
    setSubjectPaper(filteredPapers);
  };

  if (!loggedIn) return <Redirect to="/Login" />;
  if (loading) return <MyLoader />;

  return (
    <Router>
      <Switch>
        <Route
          path="/AdminMain/ExamDetails/:exam_id/:session_id/:exam_name/:exam_status"
          exact
        >
          <div className="content">
            <div className="content-header">
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li>
                    <i className="fa fa-home" aria-hidden="true" />
                    <a href="#"> Exam Details</a>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="animated fadeInUpn bg_page_white_rounded box_shadow"
              style={{ margin: "50px", padding: "20px" }}
            >
              {/* Header Section */}
              <div
                className="row bg_card_primary"
                style={{ padding: "10px", margin: "0px" }}
              >
                <div className="col-sm-6 col-md-6 gravity_center">
                  <h3
                    style={{ fontSize: "20px" }}
                  >{`Exam Name: ${exam_name} (${exam_status})`}</h3>
                </div>
              </div>

              {/* Participating Classes */}

              <div
                className="row bg_card_primary"
                style={{ padding: "5px", margin: "0px" }}
              >
                {mClassList.map((classItem, index) => (
                  <div
                    key={index}
                    className="col-sm-1 col-md-1"
                    style={{ padding: "0px 2px 5px 2px" }}
                  >
                    <div
                      className={`gravity_center ${
                        CLASS_SELECTED_POSITION === index
                          ? "bg_class_select"
                          : "bg_class_deselect"
                      }`}
                      onClick={() => setClassSelectedPosition(index)}
                      style={{ cursor: "pointer" }}
                    >
                      <p style={{ fontSize: 14 }}>
                        {classItem.section_id === "No"
                          ? classItem.class_name
                          : `${classItem.class_name}: ${classItem.section_name}`}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              {/* Participating Subjects */}
              <div
                className="bg_card_blue_outline"
                style={{ margin: "10px 0px 5px 0px" }}
              >
                <div style={{ padding: "5px" }}>
                  <h5>Participating Subject</h5>
                </div>
                <div className="row" style={{ padding: "0px 5px 5px 5px" }}>
                  {mSubject.map((subject, index) => (
                    <div
                      key={index}
                      className="col-sm-2 col-md-2"
                      style={{ padding: "0px 2px 5px 2px" }}
                    >
                      <div
                        className={`gravity_center ${
                          SUJECT_SELECTED_POSITION === index
                            ? "bg_class_select"
                            : "bg_white_outline_grey"
                        }`}
                        onClick={() => setSubjectSelectedPosition(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <p style={{ fontSize: 18 }}>{subject.subject_name}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Subject Papers */}
              <div
                className="bg_card_blue_outline"
                style={{ margin: "10px 0px 5px 0px" }}
              >
                <div style={{ padding: "5px" }}>
                  <h5>Subject Paper</h5>
                </div>
                <div className="row" style={{ padding: "0px 5px 5px 5px" }}>
                  {mSubjectPaper.map((paper, index) => (
                    <div
                      key={index}
                      className="col-sm-6 col-md-6"
                      style={{ padding: "0px 2px 5px 2px" }}
                    >
                      <div className="bg_corner_round_grey gravity_center_vertical">
                        <p style={{ fontSize: 18 }}>{paper.exam_paper_name}</p>
                        <p style={{ fontSize: 15 }}>
                          {paper.is_mark_system === "1"
                            ? `Mark System (Max Mark: ${paper.max_mark})`
                            : `Grade System (Max Grade: ${paper.max_grade})`}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Quick Links */}
              <div style={{ padding: "0px" }}>
                <h5>Quick Links</h5>
              </div>
              <QuickLinks
                examId={EXAM_ID}
                sessionId={SESSION_ID}
                examName={EXAM_NAME}
                examStatus={EXAM_STATUS}
              />
            </div>
          </div>
        </Route>

        {/* Nested Routes */}
        <Route
          path="/AdminMain/ExamDateSheet/:exam_id/:session_id/:exam_name/:exam_status"
          component={ExamDateSheet}
        />
        <Route
          path="/AdminMain/ExamGuideline/:exam_id/:session_id/:exam_name/:exam_status"
          component={ExamGuideline}
        />
        <Route
          path="/AdminMain/ExamPaperSettings/:exam_id/:session_id/:exam_name/:exam_status"
          component={ExamPaperSettings}
        />
        <Route
          path="/AdminMain/ExamHallTicket/:exam_id/:session_id/:exam_name/:exam_status"
          component={ExamHallTicket}
        />
        <Route
          path="/AdminMain/ExamMarkingSystem/:exam_id/:session_id"
          component={ExamMarkingSystem}
        />
        <Route
          path="/AdminMain/ExamAddMarkStudentWise/:exam_id/:session_id/:exam_name/:exam_status"
          component={ExamAddMarkStudentWise}
        />
        <Route
          path="/AdminMain/ExamAddMarkSubjectWise/:exam_id/:session_id/:exam_name/:exam_status"
          component={ExamAddMarkSubjectWise}
        />
      </Switch>
    </Router>
  );
};

const QuickLinks = ({ examId, sessionId, examName, examStatus }) => (
  <>
    <div className="row bg_card_blue_outline gravity_center">
      {[
        {
          path: "ExamPaperSettings",
          label: "Max Mark\nSettings",
          color: COLORS.light_blue4,
        },
        {
          path: "ExamAddMarkSubjectWise",
          label: "Mark Entry\nSubjectWise",
          color: COLORS.light_blue4,
        },
        {
          path: "ExamAddMarkStudentWise",
          label: "Mark Entry\nStudentWise",
          color: COLORS.light_blue4,
        },
        {
          path: "ExamMarkingSystem",
          label: "View\nMark",
          color: COLORS.light_blue4,
        },
        {
          path: "ExamDateSheet",
          label: "Date\nSheet",
          color: COLORS.light_blue4,
        },
        {
          path: "ExamGuideline",
          label: "Exam\nGuideline",
          color: COLORS.light_blue4,
        },
        {
          path: "ExamHallTicket",
          label: "Hall\nTicket",
          color: COLORS.light_blue4,
        },
      ].map((link, index) => (
        <div key={index} style={styles.container}>
          <Link
            to={`/AdminMain/${link.path}/${examId}/${sessionId}/${examName}/${examStatus}`}
            style={styles.link}
          >
            <QuickLinkButton label={link.label} color={link.color} />
          </Link>
        </div>
      ))}
    </div>

    <div className="row bg_card_blue_outline gravity_center">
      {[
        {
          path: "ExamAdmitCard",
          label: "Admit\nCard",
          color: COLORS.Deep_Purple4,
        },
        {
          path: "ExamResult",
          label: "Exam\nResult",
          color: COLORS.Deep_Purple4,
        },
        {
          path: "ExamMarkingSystem",
          label: "Report\nCard",
          color: COLORS.Deep_Purple4,
        },
      ].map((link, index) => (
        <div key={index} className="col-sm-2 col-md-2">
          <Link
            to={`/AdminMain/${link.path}/${examId}/${sessionId}/${examName}`}
          >
            <QuickLinkButton label={link.label} color={link.color} />
          </Link>
        </div>
      ))}
    </div>
  </>
);

const QuickLinkButton = ({ label, color }) => (
  <div style={{ margin: "5px 5px", padding: "0px" }}>
    <div className="gravity_center">
      <div
        style={{
          padding: "3px",
          margin: "2px",
          height: "105px",
          width: "105px",
        }}
      >
        <div
          className="circular_image_custom gravity_center"
          style={{
            "--myFillColor": color,
            "--myBorderColor": COLORS.whitesmoke,
            "--myBorder": "3px",
          }}
        >
          <p
            className="text-center"
            style={{ color: COLORS.white, fontSize: "15px" }}
          >
            {label.split("\n").map((line, i) => (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </p>
        </div>
      </div>
    </div>
  </div>
);

const styles = {
  container: {
    width: "110px", // Fixed width for consistency
    margin: "10px", // Spacing between items
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    textDecoration: "none", // Removes underline from the link
  },
};
export default ExamDetails;
