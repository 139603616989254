import React from "react";
import "../css/Admin_css/admin-customize.css";

const DepartmentSettings = () => {
  return (
    <div className="content">
      <div className="content-header">
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li>
              <i className="fa fa-home" aria-hidden="true" />
              <a href="#">Department Settings</a>
            </li>
          </ul>
        </div>
      </div>

      <div
        className="bg_page_white_rounded box_shadow animated fadeInUp"
        style={{ "--mMargin": "30px" }}
      >
        <div className="">
          {/* First Department Box */}
          <div className="DepartmentSettings-box">
            <div className="DepartmentSettings-content">
              <span className="DepartmentSettings-label">Department:</span>
              <span className="DepartmentSettings-value">Admin</span>
            </div>
            <div className="DepartmentSettings-content">
              <span className="DepartmentSettings-label">Department Head:</span>
              <span className="DepartmentSettings-value">Clerk</span>
            </div>
          </div>

          {/* Second Department Box */}
          <div className="DepartmentSettings-box">
            <div className="DepartmentSettings-content">
              <span className="DepartmentSettings-label">Department:</span>
              <span className="DepartmentSettings-value">HR</span>
            </div>
            <div className="DepartmentSettings-content">
              <span className="DepartmentSettings-label">Department Head:</span>
              <span className="DepartmentSettings-value">Manager</span>
            </div>
          </div>

          {/* Third Department Box */}
          <div className="DepartmentSettings-box">
            <div className="DepartmentSettings-content">
              <span className="DepartmentSettings-label">Department:</span>
              <span className="DepartmentSettings-value">Finance</span>
            </div>
            <div className="DepartmentSettings-content">
              <span className="DepartmentSettings-label">Department Head:</span>
              <span className="DepartmentSettings-value">Supervisor</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepartmentSettings;
