import React, { useState, useEffect } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import MyLoader from "../Spinner/MyLoader";
import COLORS from "../resources/colors/COLORS";
import oBook from "../resources/images/o-book.png";
import SessionManager from "../Utils/SessionManager";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";

const ExamCreation = () => {
  // Check if user is logged in (based on token)
  const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const [getClass, setGetClass] = useState([]);
  const [classBoard, setClassBoard] = useState([]);
  const [classMedium, setClassMedium] = useState([]);
  const [classSection, setClassSection] = useState([]);

  const subjects = [
    "English",
    "Hindi",
    "Maths",
    "Science",
    "Art",
    "History",
    "Games",
    "G.K.",
    "Computer",
    "SST",
  ];
  const [markSystems, setMarkSystems] = useState(
    subjects.reduce((acc, subject) => ({ ...acc, [subject]: "" }), {})
  );

  const handleMarkSystemChange = (subject, value) => {
    setMarkSystems((prev) => ({ ...prev, [subject]: value }));
  };
  useEffect(() => {
    // If token not found, set loggedIn to false (this will trigger redirect)
    if (!localStorage.getItem("token")) {
      setLoggedIn(false);
    }
    // Fetch class data from API
    fetchClassData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchClassData = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getClass.php";
    const URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    console.log("URL>>>>>" + URL);

    axios
      .get(URL, { params: { school_code: SCHOOL_CODE } })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        console.log("Response>>>>", response);
        if (response.api_status === "OK") {
          setGetClass(response.response.class);
          setClassBoard(response.response.board);
          setClassMedium(response.response.medium);
          // If available, store the section from the first class
          setClassSection(response.response.class[0]?.section || []);
          console.log("getClass>>>>", response.response.class);
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("API Error>>>>", error);
      });
  };

  const handleDropdownChange = (e) => {
    alert("Dropdown changed: " + e.target.value);
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  if (loading) {
    return <MyLoader />;
  }

  return (
    <div className="content">
      {/* Header Section */}
      <div
        className="content-header"
        style={{ background: COLORS.primary_color_shade5 }}
      >
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li>
              <i
                className="fa fa-home"
                aria-hidden="true"
                style={{ color: COLORS.white }}
              />
              <a href="#"> Exam Creation </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="bg_page_white_rounded" style={{ "--mMargin": "50px" }}>
        <div className="row bg_card_orange">
          <div className="col-md-6 mar_top1">
            <label className="col-md-4 col-form-label">
              <h4 className="subject_3"> Select Boards:</h4>
            </label>
            <div className="col-md-8">
              <select className="select-field" onChange={handleDropdownChange}>
                {classBoard.map((item, index) => (
                  <option key={index}>{item.board_name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-md-6 mar_top1">
            <label className="col-md-4 col-form-label padding_0">
              <h4 className="subject_3"> Select Medium:</h4>
            </label>
            <div className="col-md-8 padding_0">
              <select className="select-field">
                {classMedium.map((item, index) => (
                  <option key={index}>{item.medium_name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-md-6 mar_top1">
            <label className="col-md-4 col-form-label">
              <h4 className="subject_3"> Exam name</h4>
            </label>
            <div className="col-md-8">
              <div className="input-group">
                <input
                  id="ExamName"
                  type="text"
                  defaultValue="Enter Exam name"
                  className="select-field"
                />
              </div>
            </div>
          </div>

          <div className="col-md-6 mar_top1">
            <label className="col-md-4 col-form-label padding_0">
              <h4 className="subject_3"> Select Start Date </h4>
            </label>
            <div className="col-md-8 padding_0">
              <div className="input-group">
                <input id="date" type="date" className="select-field" />
              </div>
            </div>
          </div>
        </div>

        <div className="mar_top1">
          <h4 className="subject_2">
            {" "}
            Select Participating Class And Subject{" "}
          </h4>
        </div>

        <section className="exam_section3 mar_top1">
          <div className="row class-li">
            <div className="col-sm-4 col-md-4">
              <div className="chek">
                <input type="checkbox" className="chek-in" />
              </div>
            </div>
            <div className="col-sm-8 col-md-8">
              <h4> Nursery: B1 </h4>
            </div>
          </div>

          <div className="ExamCreationNew-container">
            <div className="ExamCreationNew-row">
              {subjects.map((subject, index) => (
                <div className="ExamCreationNew-col" key={index}>
                  <div className="ExamCreationNew-card">
                    <div className="ExamCreationNew-card-body">
                      <div className="ExamCreationNew-header">
                        <h5 className="ExamCreationNew-title">{subject}</h5>
                        <div className="ExamCreationNew-checkbox-wrapper">
                          <input
                            type="checkbox"
                            className="ExamCreationNew-checkbox"
                            id={`check-${index}`}
                          />
                        </div>
                      </div>
                      <div className="ExamCreationNew-dropdown-group">
                        <select
                          className="ExamCreationNew-dropdown"
                          value={markSystems[subject]}
                          onChange={(e) =>
                            handleMarkSystemChange(subject, e.target.value)
                          }
                        >
                          <option value="">Mark System</option>
                          <option value="Percentage">Percentage</option>
                          <option value="Grade">Grade</option>
                        </select>

                        {markSystems[subject] === "Percentage" && (
                          <select className="ExamCreationNew-dropdown">
                            <option value="">Max Mark</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        )}

                        {markSystems[subject] === "Grade" && (
                          <select className="ExamCreationNew-dropdown">
                            <option value="">Select Grade</option>
                            <option value="A+">A+</option>
                            <option value="A">A</option>
                            <option value="B+">B+</option>
                            <option value="B">B</option>
                          </select>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Submit Button */}
        <div className="row">
          <div className="col-sm-12 col-md-12 padding_0">
            <button
              className="btn btn-success subject_2"
              style={{
                width: "100%",
                fontSize: "24px",
                margin: "30px 0px 15px 0px",
                background: COLORS.primary_color_shade5,
                border: COLORS.primary_color_shade5,
              }}
            >
              Submit Exam Creation
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamCreation;
