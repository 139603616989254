import React, { useEffect, useState } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import COLORS from "../resources/colors/COLORS";
import SessionManager from "../Utils/SessionManager";
import { Link, Redirect } from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import MdlSelectSingleStudent from "../dialog/MdlSelectSingleStudent";

const AddOldDue = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState( localStorage.getItem("token") !== null);
  const [loading, setLoading] = useState(false);
  const [sessionName, setSessionName] = useState("");
  const [dueAmount, setDueAmount] = useState("");
  const [remark, setRemark] = useState("");
  const [student_id, setStudent_id] = useState("");
  const [mStudentDetails, setSelectedStudent] = useState();
  const handleClose = () => setIsOpen(false);

  const handleSelectedStudent = ( student_id,selected_item,session_id,session_name) => {
    setIsOpen(false);
    setSelectedStudent(selected_item);
    setStudent_id(student_id)
  };
  useEffect(() => { }, [mStudentDetails]);

  const handleShow = () => {
    setIsOpen(true);
  };

  const isValidInput = () => {
    if (dueAmount === "") {
      alert("Your Due Amount has to be numeric!");
      return false;
    } else if (sessionName === "") {
      alert("Enter Session Name!");
      return false;
    } else if (remark === "") {
      alert("Enter Remark!");
      return false;
    }
    return true;
  };

  const regSubmit = () => {
    if (isValidInput()) {
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      const FUNCTION_NAME = "AddOldDue.php";
      const URL =
        process.env.REACT_APP_SERVER_NAME +
        process.env.REACT_APP_TARGET_DIR +
        FUNCTION_NAME;

      setLoading(true);

      axios
        .get(URL, {
          params: {
            school_code: SCHOOL_CODE,
            student_id: student_id,
            due_amount: dueAmount,
            session_name: sessionName,
            remark: remark,
          },
        })
        .then((res) => {
          setLoading(false);
          alert("Successfully Updated!");
          window.location.reload();
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error updating due:", error);
        });
    }
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  if (loading) {
    return <MyLoader />;
  }

  return (
    <div className="content">
      <div
        className="content-header"
        style={{ background: COLORS.primary_color_shade5 }}
      >
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li> <i className="fa fa-home" aria-hidden="true" style={{ color: COLORS.white }} /><a href="#"> Add Old Due </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        className="bg_page_white_rounded box_shadow animated fadeInUp" style={{ "--mMargin": "50px" }}>
        <div className="row m-0 select-students-container">
          <div className="col-12 text-center select-students-header" onClick={handleShow}>
            <h3 className="arrow select-students-title"> Select Students </h3>
          </div>
        </div>
        {isOpen === true ? (<MdlSelectSingleStudent action={handleClose} action2={handleSelectedStudent}></MdlSelectSingleStudent>) : ("")}
        
        <section className="deuold">
          <div className="deuold-container1">
            <div className="deuold-row">
              <div className="deuold-profile">
                <div className="deuold-circle">
                  <img src={mStudentDetails ? mStudentDetails.student_img_path : ""} alt="Student" />
                  <span className="deuold-enrollment">
                    {mStudentDetails ? mStudentDetails.student_enrollment_no : ""}
                  </span>
                </div>
              </div>

              <div className="deuold-details">
                <h5>{mStudentDetails ? mStudentDetails.stu_name : ""}</h5>
                <p>
                  Father:
                  <span>
                    {mStudentDetails ? mStudentDetails.father_name : ""}
                  </span>
                  &nbsp;&nbsp; Board:
                  <span>
                    {mStudentDetails ? mStudentDetails.board_name : ""}
                  </span>
                  &nbsp;&nbsp; Medium:
                  <small>
                    {mStudentDetails ? mStudentDetails.medium_name : ""}
                  </small>
                </p>
              </div>

              <div className="deuold-roll">
                <p>Roll No.</p>
                <h5>
                  {mStudentDetails ? mStudentDetails.student_roll_no : ""}
                </h5>
                <p>{mStudentDetails ? mStudentDetails.class_name : ""}</p>
              </div>
            </div>
          </div>
        </section>

        <section className="AddOldDue-formSection">
          <div className="AddOldDue-inputContainer">
            <div className="AddOldDue-inputGroup">
              <label>Due Amount</label>
              <input type="text" id="DueAmount" value={dueAmount} onChange={(e) => setDueAmount(e.target.value)} placeholder="Due Amount:" className="form-control AddOldDue-input" />
            </div>

            <div className="AddOldDue-inputGroup">
              <label>Session Name</label>
              <input
                type="text"
                id="SessionName"
                value={sessionName}
                onChange={(e) => setSessionName(e.target.value)}
                placeholder="Session Name:"
                className="form-control AddOldDue-input"
              />
            </div>
            <div className="AddOldDue-inputGroup">
              <label>Remark</label>
              <input
                type="text"
                id="Remark"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                placeholder="Remarks (Optional):"
                className="form-control AddOldDue-input"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mp1_0">
              <button
                className="btn btn-primary AddOldDue-submitButton"
                onClick={regSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AddOldDue;