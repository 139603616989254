import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import { useParams, Link, Redirect, Route, Switch } from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import COLORS from "../resources/colors/COLORS";
import ExamDateSheet from "./ExamDateSheet";
import ExamAdmitCard from "./ExamAdmitCard";
import ExamHallTicket from "./ExamHallTicket";
import ExamGuideline from "./ExamGuideline";
import ExamMarkingSystem from "./ExamMarkingSystem";
import ExamAddMarkStudentWise from "./ExamAddMarkStudentWise";
import ExamAddMarkSubjectWise from "./ExamAddMarkSubjectWise";
import MdlEditPaper from "../dialog/MdlEditPaper";
import MdlDeletePaper from "../dialog/MdlDeletePaper";
import MdlAddNewPaper from "../dialog/MdlAddNewPaper";

const ExamPaperSettings = () => {
  const { exam_id, session_id, exam_name } = useParams();
  const [loggedIn, setLoggedIn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [EXAM_ID, setExamId] = useState(exam_id || "");
  const [SESSION_ID, setSessionId] = useState(session_id || "");
  const [EXAM_NAME, setExamName] = useState(
    decodeURIComponent(exam_name || "")
  );
  const [CLASS_ID, setClassId] = useState("");
  const [SECTION_ID, setSectionId] = useState("");
  const [class_section_name, setClassSectionName] = useState("");
  const [mClassList, setClassList] = useState([]);
  const [CLASS_SELECTED_POSITION, setClassSelectedPosition] = useState(0);
  const [SUJECT_SELECTED_POSITION, setSubjectSelectedPosition] = useState(0);
  const [mSubject, setSubject] = useState([]);
  const [SelectedSubjectid, setSelectedSubjectid] = useState();
  const [SelectedSubjectName, setSelectedSubjectName] = useState();
  const [mSubjectPaper, setSubjectPaper] = useState([]);
  const [mSelectPaper, setSelectPaper] = useState();
  const [isOpenEditPaper, setIsOpenEditPaper] = useState(false);
  const [isOpenDeletePaper, setIsOpenDeletePaper] = useState(false);
  const [isOpenAddNewPaper, setIsOpenAddNewPaper] = useState(false);
  const [updateKey, setUpdateKey] = useState(0);

  // Check if the user is logged in
  useEffect(() => {
    //alert("okkk" + class_section_name);
    const token = localStorage.getItem("token");
    if (!token) {
      setLoggedIn(false);
    }
    getExamParticipatingClass();
  }, []);

  const getExamParticipatingClass = () => {
    setClassList([]);
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getClassWithSubjectsPartAndPaper.php?";
    const URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    const params = {
      school_code: SCHOOL_CODE,
      exam_id: EXAM_ID,
      session_id: SESSION_ID,
    };

    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);

    axios
      .get(URL, {
        params: params,
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        if (response.api_status === "OK") {
          setClassList(response.data);
          setClassSelectedPosition(CLASS_SELECTED_POSITION);
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    if (CLASS_SELECTED_POSITION >= 0) {
      handleSelectClass(CLASS_SELECTED_POSITION);
    }
  }, [CLASS_SELECTED_POSITION, mClassList]);

  const handleSelectClass = (selected_position) => {
    if (mClassList.length > 0) {
      setClassId(mClassList[selected_position]?.class_id || "");
      setSectionId(mClassList[selected_position]?.section_id || "No");
      var class_section_name =
        mClassList[selected_position]?.class_name +
          ":" +
          mClassList[selected_position]?.section_name || " ";
      setClassSectionName(class_section_name);
      const selectedSubjects =
        mClassList[CLASS_SELECTED_POSITION]?.subject || [];
      //alert(JSON.stringify(class_section_name));
      setSubject(selectedSubjects);
      setSubjectSelectedPosition(0);
    }
  };

  useEffect(() => {
    const selectedSubjects = mSubject[SUJECT_SELECTED_POSITION];
    if (selectedSubjects) {
      const selectedSubjectId = selectedSubjects.subject_id;
      setSelectedSubjectid(selectedSubjectId);
      setSelectedSubjectName(selectedSubjects.subject_name);
      getSubjectPaper(selectedSubjectId);
    } else {
      setSubjectPaper([]);
    }
  }, [SUJECT_SELECTED_POSITION, mSubject]);

  const getSubjectPaper = (selected_subject_id) => {
    const allSubjectPapers =
      mClassList[CLASS_SELECTED_POSITION]?.subject_paper || [];
    const filteredPapers = allSubjectPapers.filter(
      (paper) => paper.subject_id === selected_subject_id
    );
    setSubjectPaper(filteredPapers);
  };

  const toggleEditPaper = (paper) => {
    setSelectPaper(paper);
    setIsOpenEditPaper(!isOpenEditPaper);
  };
  const toggleDeletePaper = (paper) => {
    setSelectPaper(paper);
    setIsOpenDeletePaper(!isOpenDeletePaper);
  };

  const toggleAddNewPaper = () => {
    setIsOpenAddNewPaper(!isOpenAddNewPaper);
  };

  const CallBackEditPaper = () => {
    setIsOpenEditPaper(!isOpenEditPaper);
    getExamParticipatingClass();

    // window.location.reload();
  };
  const CallBackAddPaper = () => {
    //alert("hii")
    setIsOpenAddNewPaper(!isOpenAddNewPaper);
    getExamParticipatingClass();
  };

  const CallBackDeletePaper = () => {
    //alert("hii")
    setIsOpenDeletePaper(!isOpenDeletePaper);
    getExamParticipatingClass();
  };

  if (!loggedIn) return <Redirect to="/Login" />;
  if (loading) return <MyLoader />;

  return (
    <Switch>
      <Route
        path="/AdminMain/ExamPaperSettings/:exam_id/:session_id/:exam_name/:exam_status"
        exact
      >
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li>
                  <i className="fa fa-home" aria-hidden="true" />
                  <a href="#"> Exam Paper Settings </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="animated fadeInUpn bg_page_white_rounded box_shadow">
            {/* Participating Classes */}
            <div
              className="row bg_card_primary"
              style={{ padding: "5px", margin: "0px" }}
            >
              {mClassList.map((classItem, index) => (
                <div
                  key={index}
                  className="col-sm-1 col-md-1"
                  style={{ padding: "0px 2px 5px 2px" }}
                >
                  <div
                    className={`gravity_center ${
                      CLASS_SELECTED_POSITION === index
                        ? "bg_class_select"
                        : "bg_class_deselect"
                    }`}
                    onClick={() => setClassSelectedPosition(index)}
                    style={{ cursor: "pointer" }}
                  >
                    <p style={{ fontSize: 14 }}>
                      {classItem.section_id === "No"
                        ? classItem.class_name
                        : `${classItem.class_name}: ${classItem.section_name}`}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            {/* Participating Subjects */}
            <div className="bg_white_outline_blue1">
              <div style={{ padding: "5px" }}>
                <h4 className="subject_2">Select Subject</h4>
              </div>

              <div className="row">
                {mSubject.map((subject, index) => (
                  <div
                    key={index}
                    className="col-sm-2 col-md-2"
                    style={{ padding: "5px" }}
                  >
                    <div
                      className={`${
                        SUJECT_SELECTED_POSITION === index
                          ? "bg_class_select1 gravity_center1"
                          : "bg_class_deselect1 gravity_center1"
                      }`}
                      onClick={() => setSubjectSelectedPosition(index)}
                      style={{ cursor: "pointer" }}
                    >
                      <p style={{ fontSize: 16 }}>{subject.subject_name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 padding_0 mar_top">
                <div className="bg_card_primary_outline padding_5">
                  <h4>Subject Paper</h4>
                  {mSubjectPaper &&
                    mSubjectPaper.map((paper, index) => (
                      <div key={index} className="row bg_class_deselect1">
                        <div className="col-md-3 subject_4 mar_top">
                          Paper Name: {paper.exam_paper_name}
                        </div>
                        <div className="col-md-3 subject_4 mar_top">
                          (Paper Type - {paper.paper_type})
                        </div>
                        <div className="col-md-2 subject_4 mar_top">
                          (
                          {paper.is_mark_system === "1"
                            ? "Max Mark"
                            : "Max Grade"}{" "}
                          -{" "}
                          {paper.is_mark_system === "1"
                            ? paper.max_mark
                            : paper.max_grade}
                          )
                        </div>
                        <div className="col-md-2">
                          <button
                            className="btn_font_size1 btn-success"
                            style={{
                              height: "40px",
                              width: "100%",
                              margin: "10px 0px 15px 0px",
                              background: COLORS.primary_color_shade5,
                              border: "none",
                            }}
                            onClick={() => toggleEditPaper(paper)}
                          >
                            Edit Paper
                          </button>
                        </div>
                        <div className="col-md-2">
                          <button
                            className="btn_font_size1 btn-danger"
                            style={{
                              height: "40px",
                              width: "100%",
                              margin: "10px 0px 15px 0px",
                              background: "red",
                              border: "none",
                            }}
                            onClick={() => toggleDeletePaper(paper)}
                          >
                            Delete Paper
                          </button>
                        </div>
                      </div>
                    ))}
                  <div className="row">
                    <div className="col-sm-12 col-md-12 padding_0">
                      <button
                        className="btn_font_size btn-success"
                        style={{
                          height: "40px",
                          width: "100%",
                          margin: "10px 0px 15px 0px",
                          background: COLORS.primary_color_shade5,
                          border: "none",
                        }}
                        onClick={toggleAddNewPaper}
                      >
                        Create New Paper
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isOpenAddNewPaper && (
              <MdlAddNewPaper
                actionClose={toggleAddNewPaper}
                actionAdd={CallBackAddPaper}
                exam_id={EXAM_ID}
                exam_name={EXAM_NAME}
                session_id={SESSION_ID}
                section_id={SECTION_ID}
                class_id={CLASS_ID}
                subject_id={SelectedSubjectid}
                subject_name={SelectedSubjectName}
                class_section_name={class_section_name}
              />
            )}
            {isOpenEditPaper && (
              <MdlEditPaper
                actionClose={toggleEditPaper}
                actionUpdate={CallBackEditPaper}
                paper={mSelectPaper}
                exam_id={EXAM_ID}
                exam_name={EXAM_NAME}
              />
            )}
            {isOpenDeletePaper && (
              <MdlDeletePaper
                actionClose={toggleDeletePaper}
                actionDelete={CallBackDeletePaper}
                paper={mSelectPaper}
                exam_id={EXAM_ID}
                exam_name={EXAM_NAME}
              />
            )}
          </div>
        </div>
      </Route>

      {/* Nested Routes */}
      <Route
        path="/AdminMain/ExamDateSheet/:exam_id/:session_id/:exam_name"
        component={ExamDateSheet}
      />
      <Route
        path="/AdminMain/ExamGuideline/:exam_id/:session_id/:exam_name"
        component={ExamGuideline}
      />
      <Route
        path="/AdminMain/ExamPaperSettings/:exam_id/:session_id/:exam_name/:exam_status"
        component={ExamPaperSettings}
      />
      <Route
        path="/AdminMain/ExamHallTicket/:exam_id/:session_id/:exam_name/:exam_status"
        component={ExamHallTicket}
      />
      <Route
        path="/AdminMain/ExamMarkingSystem/:exam_id/:session_id"
        component={ExamMarkingSystem}
      />
      <Route
        path="/AdminMain/ExamAddMarkStudentWise/:exam_id/:session_id/:exam_name/"
        component={ExamAddMarkStudentWise}
      />
      <Route
        path="/AdminMain/ExamAddMarkSubjectWise/:exam_id/:session_id/:exam_name/"
        component={ExamAddMarkSubjectWise}
      />
    </Switch>
  );
};

export default ExamPaperSettings;
