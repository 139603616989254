import React, { useState, useEffect } from "react";
import MyLoader from "../Spinner/MyLoader";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import boyImage from "../img/boy.jpg";
import { FaUser, FaChalkboardTeacher, FaClipboardList } from "react-icons/fa";

const AccessoriesDetails = () => {
  const [loggedIn, setLoggedIn] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const checkLoginStatus = async () => {};
    checkLoginStatus();
  }, []);

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  } else if (loading) {
    return <MyLoader />;
  } else {
    return (
      <Router>
        <Switch>
          <Route
            path="/AdminMain/AccessoriesDetails"
            exact
            render={() => (
              <div className="content">
                <div className="content-header">
                  <div className="leftside-content-header">
                    <ul className="breadcrumbs">
                      <li>
                        <i className="fa fa-home" aria-hidden="true" />
                        <a href="#"> Accessories Details </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="bg_page_grey_rounded box_shadow"
                  style={{ "--mMargin": "50px" }}
                >
                  <div className="AccessoriesDetails-profile-container">
                    <div className="accessories-collection-user-info">
                      <div className="accessories-collection-profile-icon">
                        <div className="accessories-collection-profile-circle">
                          <img
                            src={boyImage}
                            alt="User"
                            className="accessories-collection-profile-image"
                          />
                        </div>
                        <div className="accessories-collection-profile-id">
                          S055311
                        </div>
                      </div>
                      <div className="accessories-collection-user-details">
                        <p className="accessories-collection-user-name">
                          AMRITA PANDEY
                        </p>
                        <p className="accessories-collection-user-parent">
                          D/O: OM PRAKASH
                        </p>
                        <p className="accessories-collection-user-location">
                          Vill/Town: null
                        </p>
                        <p className="accessories-collection-user-srn">
                          SRN: 1
                        </p>
                      </div>
                      <div className="accessories-collection-roll-info">
                        <p>Roll No</p>
                        <div className="accessories-collection-roll-number">
                          1
                        </div>
                        <p>Play Group: A</p>
                      </div>
                    </div>
                    <div className="AccessoriesDetails-amount-info">
                      <div className="AccessoriesDetails-amount-card">
                        Total Amount ₹9000
                      </div>
                      <div className="AccessoriesDetails-amount-card AccessoriesDetails-amount-card-gray">
                        Total Discount ₹0
                      </div>
                      <div className="AccessoriesDetails-amount-card">
                        Total Payable ₹9000
                      </div>
                      <div className="AccessoriesDetails-amount-card AccessoriesDetails-amount-card-green">
                        Total Paid ₹0
                      </div>
                      <div className="AccessoriesDetails-amount-card AccessoriesDetails-amount-card-red">
                        Total Due ₹9000
                      </div>
                    </div>

                    <h3>Accessories Details</h3>
                    <div className="AccessoriesDetails-details">
                      <div className="AccessoriesDetails-date">
                        Date: 2024-11-09
                      </div>
                      <table className="AccessoriesDetails-table">
                        <thead>
                          <tr>
                            <th>Particular</th>
                            <th>Rate</th>
                            <th>Qty</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>adm kit</td>
                            <td>Rs: 4500</td>
                            <td>1</td>
                            <td>Rs: 4500</td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="AccessoriesDetails-amount-info">
                        <div className="">Amount ₹9000</div>
                        <div className=" -gray">Discount ₹0</div>
                        <div className="">Payable ₹9000</div>
                        <div className=" -green">Paid ₹0</div>
                        <div className="">Due ₹9000</div>
                      </div>
                      <button className="AccessoriesDetails-remove-btn">
                        REMOVE
                      </button>
                    </div>
                  </div>
                  <button className="AccessoriesDetails-collect-btn">
                    COLLECT NOW
                  </button>
                </div>
              </div>
            )}
          />
        </Switch>
      </Router>
    );
  }
};

export default AccessoriesDetails;
