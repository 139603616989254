import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import COLORS from "../resources/colors/COLORS";
import SessionManager from "../Utils/SessionManager";
import { Redirect } from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import Iframe from "react-iframe";
import SelectClass from "../ChildComponent/SelectClass";

const StudentDueList = (props) => {
  const token = localStorage.getItem("token");
  const [loggedIn, setLoggedIn] = useState(token !== null);
  const [loading, setLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const [fileURL, setFileURL] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileDirectory, setFileDirectory] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [classId, setClassId] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [selectedClassPosition, setSelectedClassPosition] = useState(-1);
  const [printableSamples, setPrintableSamples] = useState([]);
  const [selectedSampleId, setSelectedSampleId] = useState("");
  const [selectedSamplePosition, setSelectedSamplePosition] = useState(-1);
  const [classSectionName, setClassSectionName] = useState("");
  const [selectedMonth, setSelectedOption] = useState("");
  const [mOptions, setmOptions] = useState([]);
  const [TypeCode, setTypeCode] = useState("1");
  const [mOptionsType, setmOptionsType] = useState([
    { value: "All List", type_code: "1" },
    { value: "Current Due List", type_code: "2" },
    { value: "No Due List", type_code: "3" },
  ]);
  const [height, setHeight] = useState("300px");
  const iframeRef = useRef(null);

  useEffect(() => {
    var selectedOption = getCurrentYearMonth();
    setSelectedOption(selectedOption);

    const mSession = SessionManager.getCurrentSession();
    const start_date = new Date(mSession[0].start_date);
    const end_date = new Date(mSession[0].end_date);

    const mYearMonth = [];
    while (start_date <= end_date) {
      const year = start_date.getFullYear();
      const month = String(start_date.getMonth() + 1).padStart(2, "0");
      mYearMonth.push(`${year}-${month}`);
      start_date.setMonth(start_date.getMonth() + 1);
    }
    setmOptions(mYearMonth);

    const handleMessage = (event) => {
      if (event.data && event.data.iframeHeight) {
        setHeight(`${event.data.iframeHeight}px`);
      }
    };
    window.addEventListener("message", handleMessage, false);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setDocumentType(props.match.params.document_type);
  }, [props.match.params.document_type]);

  useEffect(() => {
    if (selectedClassPosition >= 0) {
      if (printableSamples.length === 0) {
        getPrintableSamples();
      } else {
        setDocumentFileURL();
      }
    }
  }, [selectedClassPosition]);

  useEffect(() => {
    if (selectedClassPosition >= 0) {
      setDocumentFileURL();
    }
  }, [selectedSamplePosition]);

  useEffect(() => {
    if (selectedMonth) {
      setDocumentFileURL();
    }
  }, [selectedMonth]);
  useEffect(() => {
    if (TypeCode) {
      setDocumentFileURL();
    }
  }, [TypeCode]);

  const getCurrentYearMonth = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Add leading zero to month
    return `${year}-${month}`;
  };
  const callBackClassSelect = (
    class_id,
    section_id,
    session_id,
    selected_position,
    class_name,
    section_name
  ) => {
    setClassId(class_id);
    setSectionId(section_id);
    setSessionId(session_id);
    setSelectedClassPosition(selected_position);
    //alert("call" + class_id);

    const classSection =
      section_id === "No" ? class_name : `${class_name}:${section_name}`;
    setClassSectionName(classSection);
  };

  const getPrintableSamples = async () => {
    setLoading(true);

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getPrintableSample.php?";
    const URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    const params = {
      school_code: SCHOOL_CODE,
      document_type: documentType,
    };
    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);

    try {
      const response = await axios.get(URL, {
        params: params,
      });

      const data = response.data;
      setLoading(false);

      if (data.api_status === "OK") {
        //alert(JSON.stringify(data.data));
        setPrintableSamples(data.data);
        setSample(data.data);
      } else {
        myToast.notify_warning(data.api_remark);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching printable samples:", error);
    }
  };

  const setSample = (samples) => {
    const sample = samples[0];
    setFileName(sample.file_name);
    setFileDirectory(sample.file_directory);
    setSelectedSampleId(sample.sample_id);
    setSelectedSamplePosition(0);
  };

  const setDocumentFileURL = () => {
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const url =
      process.env.REACT_APP_SERVER_NAME +
      fileDirectory +
      fileName +
      `?&class_id=${classId}&section_id=${sectionId}&class_section_name=${classSectionName}&session_id=${sessionId}&selected_month=${selectedMonth}&type_code=${TypeCode}&sample_id=${selectedSampleId}&system_code=${process.env.REACT_APP_SYSTEM_CODE}&school_code=${SCHOOL_CODE}`;

    console.log("FILE_URL", url);
    setFileURL(url);
    setShowPreview(true);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
  };
  const handleChangeList = (event) => {
    const value = event.target.value;
    setTypeCode(value);
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  return (
    <div className="content">
      <div
        className="content-header"
        style={{ background: COLORS.primary_color_shade5 }}
      >
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li>
              <i
                className="fa fa-home"
                aria-hidden="true"
                style={{ color: COLORS.white }}
              />
              <a href="#">Report Management </a>
            </li>
          </ul>
        </div>
      </div>

      <div
        className="animated fadeInUpn bg_page_white_rounded box_shadow"
        style={{ "--mMargin": "50px" }}
      >
        {loading && <MyLoader />}
        <SelectClass
          action={callBackClassSelect}
          selected_position={selectedClassPosition}
        />
        <div className="row">
          <div className="col-sm-6 col-lg-6 padding_0 ">
            <div
              className=""
              style={{
                padding: "2px",
                margin: "0px 5px 0px 0px",
              }}
            >
              <div className="dropdown-container-flex">
                <h3>Select Month</h3>
                <select
                  className="styled-select"
                  onChange={handleChange}
                  value={selectedMonth}
                  style={{ height: "35px", padding: "0px 10px 0px 10px" }}
                >
                  {mOptions.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-6 padding_0 ">
            <div
              className=""
              style={{
                padding: "2px",
                margin: "0px 5px 0px 0px",
              }}
            >
              <div className="dropdown-container-flex">
                <h3>Select List</h3>
                <select
                  className="styled-select"
                  onChange={handleChangeList}
                  value={TypeCode}
                  style={{ height: "35px", padding: "0px 10px 0px 10px" }}
                >
                  {mOptionsType.map((item, index) => (
                    <option key={index} value={item.type_code}>
                      {item.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: "0px 10px 0px 10px" }}>
          {showPreview ? (
            <div>
              <MyLoader />
            </div>
          ) : (
            ""
          )}
          <Iframe
            ref={iframeRef}
            src={fileURL}
            width="100%"
            height={height}
            style={{ border: "none", overflow: "hidden" }}
            id="iFrame1"
            scrolling="no"
            onLoad={() => setShowPreview(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default StudentDueList;
