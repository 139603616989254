import React, { useState, useEffect } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import { Redirect } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import myToast from "../Utils/MyToast";

const MdlRenameClass = ({ actionClose, selectedClass }) => {
  const token = localStorage.getItem("token");
  const [loggedIn, setLoggedIn] = useState(!!token);
  const [loading, setLoading] = useState(false);
  const [className, setClassName] = useState();
  const [classNameOld, setclassNameOld] = useState();

  useEffect(() => {
    if (selectedClass) {
      setClassName(selectedClass.class_name);
      setclassNameOld(selectedClass.class_name);
    }
  }, []);

  const handleRenameSubmit = async () => {
    setLoading(true);
    try {
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      var mStaffData = SessionManager.getStaffDetails();
      var STAFF_ID = mStaffData.staff_id;
      const FUNCTION_NAME = "updateClassName.php";
      const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

      const params = {
        school_code: SCHOOL_CODE,
        staff_id: STAFF_ID,
        class_id: selectedClass.class_id,
        class_name_old: classNameOld,
        class_name_new: className,
        session_id: selectedClass.session_id,
      };

      const response = await axios.get(URL, { params });

      if (response.data.api_status === "OK") {
        myToast.notify_success("Class Renamed successfully!");
        //window.location.reload();
        actionClose();
      } else {
        myToast.notify_warning(
          response.data.api_remark || "Rename failed. Please try again."
        );
      }
    } catch (error) {
      console.error("Rename error:", error);
      myToast.notify_error("An error occurred while renaming the class.");
    } finally {
      setLoading(false);
    }
  };

  if (!loggedIn) return <Redirect to="/Login" />;

  return (
    <Modal
      show={true}
      size="lg"
      onHide={actionClose}
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Rename Class</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <MyLoader />
        ) : (
          <div className="RenameClassSettings">
            <div className="label-container">
              <label>Rename Class</label>
            </div>
            <div className="input-container">
              <div className="input-group">
                <input
                  type="text"
                  required
                  placeholder="Enter new class name"
                  value={className}
                  onChange={(e) => setClassName(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          size="lg"
          onClick={handleRenameSubmit}
          disabled={loading}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MdlRenameClass;
