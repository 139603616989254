import React, { Component } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import COLORS from "../resources/colors/COLORS";
import Table from "react-bootstrap/Table";
export default class ExamGuideline extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      mClassList: [],
      mExamGuideline: [],
      EXAM_ID: "",
      SESSION_ID: "",
      EXAM_NAME: "",
      CLASS_ID: "",
      SECTION_ID: "",
      input_exam_guideline: "",
      CLASS_SELECTED_POSITION: 0,
      LoadingGuideline: false,
      GUIDELINE_ID: "",
    };
    this.onEdtChange = this.onEdtChange.bind(this);
  }
  onEdtChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  componentDidMount() {
    this.state.EXAM_ID = this.props.match.params.exam_id;
    this.state.SESSION_ID = this.props.match.params.session_id;
    this.state.EXAM_NAME = this.props.match.params.exam_name;

    this.getExamParticipatingClass();
  }

  getExamParticipatingClass() {
    this.setState({
      Loading: true,
    });

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getExamParticipatingClass.php?";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;

    const params = {
      school_code: SCHOOL_CODE,
      exam_id: this.state.EXAM_ID,
      session_id: this.state.SESSION_ID,
    };

    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);

    axios
      .get(URL, {
        params: params,
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          Loading: false,
        });
        // console.log("Response2>>>>>"+response);
        let api_status = response.api_status;
        //alert(JSON.stringify(response));

        if (api_status == "OK") {
          this.state.mClassList = response.data;
          this.setClassList(0);
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        //console.log("error>>>>>"+error);
      });
  }
  selectClass = (position) => {
    this.setClassList(position);
  };
  setClassList(selected_position) {
    if (this.state.mClassList.length !== 0) {
      this.state.CLASS_ID = this.state.mClassList[selected_position].class_id;
      this.state.SECTION_ID =
        this.state.mClassList[selected_position].section_id;

      if (this.state.SECTION_ID == "null") {
        this.state.SECTION_ID = "No";
      }
      this.state.CLASS_SELECTED_POSITION = selected_position;
    }
    this.getExamGuideline();
  }

  getExamGuideline() {
    this.setState({
      LoadingGuideline: true,
    });

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getExamGuideline.php?";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    const params = {
      school_code: SCHOOL_CODE,
      exam_id: this.state.EXAM_ID,
      session_id: this.state.SESSION_ID,
      class_id: this.state.CLASS_ID,
      section_id: this.state.SECTION_ID,
    };
    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);

    axios
      .get(URL, {
        params: params,
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          LoadingGuideline: false,
        });
        // console.log("Response2>>>>>"+response);
        let api_status = response.api_status;
        //alert(JSON.stringify(response));

        if (api_status === "OK") {
          this.setState({
            mExamGuideline: response.data,
          });
        } else {
          this.setState({
            mExamGuideline: [],
          });
          let api_remark = response.api_remark;
          // myToast.notify_warning(api_remark);
        }
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        //console.log("error>>>>>"+error);
      });
  }
  selectClass = (position) => {
    this.setClassList(position);
  };
  submitGuideline() {
    if (this.isValidInput()) {
      this.AddExamGuideline();
    }
  }
  AddExamGuideline() {
    this.setState({
      LoadingGuideline: true,
    });

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "AddExamGuideline.php?";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    //console.log("URL>>>>>"+URL);

    const params = {
      session_id: this.state.SESSION_ID,
      exam_id: this.state.EXAM_ID,
      class_id: this.state.CLASS_ID,
      section_id: this.state.SECTION_ID,
      guideline: this.state.input_exam_guideline,
      school_code: SCHOOL_CODE,
    };
    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);
    axios
      .get(URL, {
        params: params,
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          LoadingGuideline: false,
        });
        // console.log("Response2>>>>>"+response);
        let api_status = response.api_status;
        //alert(JSON.stringify(response));

        if (api_status === "OK") {
          myToast.notify_success("Exam Guideline Added Successfully");
          // this.props.history.push('/AdminMain/ExamDateSheet/'+this.state.EXAM_ID+"/"+this.state.SESSION_ID+"/"+this.state.EXAM_NAME+"/"+this.state.START_DATE);
          this.getExamGuideline();
        } else {
          let api_remark = response.api_remark;
          // myToast.notify_warning(api_remark);
        }
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        //console.log("error>>>>>"+error);
      });
  }
  isValidInput() {
    if (!this.state.input_exam_guideline) {
      alert("Enter Exam Guideline!!!");
      return false;
    }

    return true;
  }
  deleteGuideline = (item) => {
    //alert(JSON.stringify(item2));
    this.state.GUIDELINE_ID = item.guideline_id;
    this.deleteExamGuideline();
  };
  deleteExamGuideline() {
    this.setState({
      LoadingGuideline: true,
    });

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    var mStaffData = SessionManager.getStaffDetails();
    var STAFF_ID = mStaffData.staff_id;
    const FUNCTION_NAME = "deleteExamGuideline.php?";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;

    const params = {
      school_code: SCHOOL_CODE,
      exam_id: this.state.EXAM_ID,
      guideline_id: this.state.GUIDELINE_ID,
      staff_id: STAFF_ID,
    };

    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);

    axios
      .get(URL, {
        params: params,
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          LoadingGuideline: false,
        });
        //console.log("Response2>>>>>"+response);
        let api_status = response.api_status;
        //alert(JSON.stringify(response));

        if (api_status === "OK") {
          myToast.notify_success("Exam Guideline Deleted Successfully");
          this.getExamGuideline();
        } else {
          this.setState({});
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        //console.log("error>>>>>"+error);
      });
  }

  render() {
    if (this.state.loggedIn === false) {
      return <Redirect to="/Login" />;
    }
    if (this.state.Loading) {
      return <MyLoader />;
    } else {
      return (
        <Router>
          <switch>
            <Route
              path={
                "/AdminMain/ExamGuideline/:exam_id/:session_id/:exam_name/:exam_status"
              }
              exact
              render={() => {
                return (
                  <div className="content">
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li>
                            <i className="fa fa-home" aria-hidden="true" />
                            <a href="#">
                              {"Exam Guideline( " + this.state.EXAM_NAME + " )"}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="animated fadeInUpn bg_page_white_rounded box_shadow"
                      style={{ "--mMargin": "50px" }}
                    >
                      <div style={{ padding: "0px 15px 0px 15px" }}>
                        <h5>Participating Class</h5>
                      </div>
                      <div
                        className="row bg_card_primary"
                        style={{ padding: "5px", margin: "0px 15px 0px 15px" }}
                      >
                        {this.state.mClassList.map((item, index) => (
                          <div
                            className="col-sm-1 col-md-1"
                            style={{ padding: "0px 2px 5px 2px" }}
                          >
                            <div
                              className={
                                this.state.CLASS_SELECTED_POSITION === index
                                  ? "bg_class_select gravity_center"
                                  : "bg_class_deselect gravity_center"
                              }
                              onClick={(e) => this.selectClass(index)}
                              style={{ cursor: "pointer" }}
                            >
                              <p style={{ fontSize: 14 }}>
                                {" "}
                                {item.section_id === "No"
                                  ? item.class_name
                                  : item.class_name +
                                    ":" +
                                    item.section_name}{" "}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div style={{ padding: "0px 15px 0px 15px" }}>
                        <h5>Add New Guideline</h5>
                      </div>

                      <div
                        className="row bg_card_primary_outline"
                        style={{ padding: "10px", margin: "0px 15px 0px 15px" }}
                      >
                        <div
                          className="col-sm-10 col-md-10 bg_grid"
                          style={{ padding: "0px 5px 5px 5px" }}
                        >
                          <h5>Guideline:</h5>
                          <input
                            type="text"
                            id="input_exam_guideline"
                            value={this.state.input_exam_guideline}
                            onChange={this.onEdtChange}
                            style={{
                              width: "100%",
                              height: "80px",
                              fontSize: "18px",
                            }}
                          />
                        </div>
                        <div
                          className="col-sm-2 col-md-2 bg_grid gravity_center"
                          style={{ padding: "0px 2px 0px 2px" }}
                        >
                          <button
                            className="btn btn-success"
                            onClick={() => this.submitGuideline()}
                            style={{
                              fontSize: "16px",
                              margin: "5px",
                              width: "80px",
                              height: "30px",
                              background: COLORS.primary_color_shade5,
                            }}
                          >
                            {" "}
                            Add{" "}
                          </button>
                        </div>
                      </div>

                      {this.state.LoadingGuideline ? (
                        <div>
                          <MyLoader />
                        </div>
                      ) : (
                        <>
                          <div style={{ padding: "0px 15px 0px 15px" }}>
                            <h5>Exam Guideline Preview</h5>
                          </div>

                          <div
                            className="row bg_card_primary_outline"
                            style={{
                              padding: "5px",
                              margin: "0px 15px 0px 15px",
                            }}
                          >
                            <Table
                              striped
                              bordered
                              hover
                              style={{ width: "100%", border: "3px" }}
                            >
                              <tr>
                                <th
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                    fontSize: 14,
                                    width: "100px",
                                  }}
                                >
                                  Sr.
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                    fontSize: 14,
                                  }}
                                >
                                  Guidelines
                                </th>

                                <th
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                    fontSize: 14,
                                    width: "100px",
                                  }}
                                >
                                  Action
                                </th>
                              </tr>
                              {this.state.mExamGuideline &&
                                this.state.mExamGuideline.map(
                                  (item2, index2) => (
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                          fontSize: 16,
                                        }}
                                      >
                                        {index2 + 1}
                                      </td>

                                      <td
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                          fontSize: 16,
                                        }}
                                      >
                                        {item2.guideline}
                                      </td>
                                      <td className="gravity_center">
                                        <button
                                          className="bg_card_primary btn btn-success gravity_center"
                                          style={{ height: "24px" }}
                                          onClick={() =>
                                            this.deleteGuideline(item2)
                                          }
                                        >
                                          Delete
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                )}
                            </Table>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                );
              }}
            />
          </switch>
        </Router>
      );
    }
  }
}
