import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import myToast from "../Utils/MyToast";
import COLORS from "../resources/colors/COLORS";
import uploadIcon from "../resources/images/upload-icon.png";
import MdlSelectSession from "../dialog/MdlSelectSession";
import ExamPaperSettings from "./ExamPaperSettings";
import ExamClassResultStatus from "./ExamClassResultStatus";
import ExamDetails from "./ExamDetails";
import ExamCreation from "./ExamCreation";
import MdlEditExam from "../dialog/MdlEditExam";
import MdlUpdateStatus from "../dialog/MdlUpdateStatus";

const ExamList = () => {
  const [isOpenSessionDialog, setIsOpenSessionDialog] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedExam, setSelectedExam] = useState(null);
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("token") !== null
  );
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [examList, setExamList] = useState([]);
  const [isUpdateStatusOpen, setIsUpdateStatusOpen] = useState(false);

  const EXAM_STATUS = {
    UPCOMING: "1",
    OVER: "3",
    LIVE: "2",
  };

  useEffect(() => {
    const mSession = SessionManager.getCurrentSession();
    setSessionId(mSession[0].session_id);
    setSessionName(mSession[0].session_name);
    window.scrollTo(0, 0);
    getExamList(mSession[0].session_id);
  }, []);

  const getExamList = (session_id) => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getExamList.php";
    const URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;

    const params = { school_code: SCHOOL_CODE, session_id: session_id };
    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);

    axios
      .get(URL, {
        params: params,
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        if (response.api_status === "OK") {
          setExamList(response.data);
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching exam list:", error);
      });
  };

  const handleSessionDialogToggle = () => {
    setIsOpenSessionDialog((prev) => !prev);
  };

  const handleSelectedSession = (session_id, session_name) => {
    setSessionId(session_id);
    setSessionName(session_name);
    setIsOpenSessionDialog(false);
    getExamList(session_id);
  };

  const handleEditClick = (exam) => {
    setSelectedExam(exam);
    setIsEditModalOpen(true);
  };

  const handleEditSave = (SessionId) => {
    setIsEditModalOpen(false);
    getExamList(SessionId);
  };

  const handleUpdateStatusOpen = (exam) => {
    setSelectedExam(exam);
    setIsUpdateStatusOpen(true);
  };

  const handleCloseModals = (SessionId) => {
    setIsUpdateStatusOpen(false);
    setSelectedExam(null);
    getExamList(SessionId);
  };

  const getIcons = (status) => {
    switch (status) {
      case EXAM_STATUS.UPCOMING:
        return uploadIcon;
      case EXAM_STATUS.LIVE:
        return uploadIcon;
      default:
        return uploadIcon;
    }
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  if (loading) {
    return <MyLoader />;
  }

  return (
    <Router>
      <Switch>
        <Route path="/AdminMain/ExamList" exact>
          <div className="content">
            <div className="content-header">
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li>
                    <i className="fa fa-home" aria-hidden="true" />
                    <a href="#">Exam List</a>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="animated fadeInUpn bg_page_white_rounded box_shadow"
              style={{ margin: "10px 80px 10px 80px", padding: "25px" }}
            >
              <div className="row">
                <Link to="#" onClick={handleSessionDialogToggle}>
                  <p
                    className="bg_card_primary"
                    style={{
                      padding: "1px 5px",
                      fontSize: "18px",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    Session: {sessionName}
                    <span style={{ float: "right" }}>
                      <img
                        src={uploadIcon}
                        alt="upload"
                        className="bg_card_blue"
                        style={{ height: "25px", width: "25px" }}
                      />
                    </span>
                  </p>
                </Link>
              </div>

              <div className="ExamList mar_top1">
                <div className="ExamList__container">
                  {examList.map((item, index) => (
                    <div className="ExamList__row" key={index}>
                      <div className="ExamList__topRow">
                        <img
                          className="ExamList__icon"
                          src={getIcons(item.status)}
                          alt="Exam Icon"
                        />
                        <span className="ExamList__status">
                          {item.status_name}
                        </span>
                        <div className="ExamList__buttons">
                          <button onClick={() => handleEditClick(item)}>
                            Edit Exam
                          </button>
                          <button onClick={() => handleUpdateStatusOpen(item)}>
                            Update Status
                          </button>

                          {item.status === EXAM_STATUS.OVER && (
                            <div className="row viewShow">
                              <Link
                                to={`/AdminMain/ExamClassResultStatus/${item.exam_id}/${item.session_id}/${item.exam_name}/${item.start_date}`}
                              >
                                <button>Result</button>
                              </Link>
                              <Link
                                to={`/AdminMain/ExamDetails/${item.exam_id}/${item.session_id}/${item.exam_name}/${item.status_name}`}
                              >
                                <button>View Details</button>
                              </Link>
                            </div>
                          )}

                          {item.status === EXAM_STATUS.LIVE && (
                            <div className="row viewShow">
                              <Link
                                to={`/AdminMain/ExamDetails/${item.exam_id}/${item.session_id}/${item.exam_name}/${item.status_name}`}
                              >
                                <button>View Details</button>
                              </Link>
                            </div>
                          )}

                          {item.status === EXAM_STATUS.UPCOMING && (
                            <div className="row viewShow">
                              <Link
                                to={`/AdminMain/ExamPaperSettings/${item.exam_id}/${item.session_id}/${item.exam_name}/${item.status_name}`}
                              >
                                <button>Paper Settings</button>
                              </Link>
                              <Link
                                to={`/AdminMain/ExamDetails/${item.exam_id}/${item.session_id}/${item.exam_name}/${item.status_name}`}
                              >
                                <button>View Exam Details</button>
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="ExamList__details">
                        <p>
                          <strong>Exam Name :</strong>{" "}
                          <span className="ExamList__status">
                            {item.exam_name}
                          </span>
                        </p>
                        <p>
                          <strong>Participating Class:</strong>
                          {item.participating_class}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 padding_0">
                    <Link to={"/AdminMain/ExamCreation/"}>
                      <button
                        className="btn_font_size btn-success"
                        style={{
                          height: "40px",
                          width: "100%",
                          margin: "10px 0px 15px 0px",
                          background: COLORS.primary_color_shade5,
                          border: "none",
                        }}
                      >
                        Create Exam
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              {isUpdateStatusOpen && (
                <MdlUpdateStatus
                  item={selectedExam}
                  onClose={handleCloseModals}
                />
              )}
              {isEditModalOpen && selectedExam && (
                <MdlEditExam
                  onClose={() => setIsEditModalOpen(false)}
                  examName={selectedExam}
                  onSave={handleEditSave}
                />
              )}
              {isOpenSessionDialog && (
                <MdlSelectSession
                  action={handleSessionDialogToggle}
                  action2={handleSelectedSession}
                />
              )}
            </div>
          </div>
        </Route>
        <Route
          path="/AdminMain/ExamPaperSettings/:exam_id/:session_id/:exam_name/:exam_status"
          component={ExamPaperSettings}
        />
        <Route
          path="/AdminMain/ExamClassResultStatus/:exam_id/:session_id/:exam_name/:exam_date"
          component={ExamClassResultStatus}
        />
        <Route
          path="/AdminMain/ExamDetails/:exam_id/:session_id/:exam_name/:exam_status"
          component={ExamDetails}
        />
        <Route path="/AdminMain/ExamCreation" component={ExamCreation} />
      </Switch>
    </Router>
  );
};

export default ExamList;
