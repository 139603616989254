import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import myToast from '../Utils/MyToast';
import SessionManager from '../Utils/SessionManager';
import '../css/Admin_css/admin-customize.css';

const AddSessionSettings = () => {
    const [loggedIn] = useState(!!localStorage.getItem("token"));
    const [loading, setLoading] = useState(false);
    const [sessionName, setSessionName] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const history = useHistory(); // ✅ useHistory hook initialize kiya

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const SCHOOL_CODE = SessionManager.getSchoolCode();
            const FUNCTION_NAME = 'AddNewUpcomingSession.php';
            const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

            const params = {
                school_code: SCHOOL_CODE,
                session_name: sessionName,
                start_date: startDate,
                end_date: endDate,
            };

            const response = await axios.get(URL, { params });

            if (response.data.api_status === 'OK') {
                myToast.notify_success("Session added successfully!");
                //history.replace("/AdminMain/SessionSettings"); 
                window.location.reload();
            } else {
                myToast.notify_warning(response.data.api_remark || 'Rename failed. Please try again.');
            }
        } catch (error) {
            console.error('Rename error:', error);
            myToast.notify_error('An error occurred while renaming the class.');
        } finally {
            setLoading(false);
        }
    };

    if (!loggedIn) {
        return <Redirect to="/Login" />;
    }

    if (loading) {
        return <MyLoader />;
    }

    return (
        <div className="content">
            <div className="content-header">
                <div className="leftside-content-header">
                    <ul className="breadcrumbs">
                        <li>
                            <i className="fa fa-home" aria-hidden="true" />
                            <a href="#">Session Settings</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="bg_page_white_rounded box_shadow animated fadeInUp" style={{ '--mMargin': '30px' }}>
                <h2 className="session-heading">Add New Session</h2>
                <form className="addSession-form" onSubmit={handleSubmit}>
                    <div className="addSession-form-row">
                        {/* Session Name */}
                        <div className="addSession-form-group">
                            <label>Session Name</label>
                            <input
                                type="text"
                                value={sessionName}
                                onChange={(e) => setSessionName(e.target.value)}
                                placeholder="e.g. 2025-2026"
                                required
                            />
                        </div>

                        {/* Start Date (Date Picker) */}
                        <div className="addSession-form-group">
                            <label>Start Date</label>
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                required
                            />
                        </div>

                        {/* End Date (Date Picker) */}
                        <div className="addSession-form-group">
                            <label>End Date</label>
                            <input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                required
                            />
                        </div>
                    </div>

                    <div className="addSession-form-actions">
                        <button type="submit" className="addSession-btn addSession-btn-success">
                            Add Session
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddSessionSettings;
