import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import myToast from "../Utils/MyToast";
import SessionManager from "../Utils/SessionManager";
import { useHistory } from "react-router-dom";

const MdlEditClassSubject = ({
  actionClose,
  subject,
  session_id,
  section_id,
}) => {
  const [show, setShow] = useState(true);
  const [subjectType, setSubjectType] = useState("");
  const [loading, setLoading] = useState(false);
  const [SessionId, setSessionId] = useState(session_id);
  const [SectionId, setSectionId] = useState(section_id);
  const history = useHistory();

  const handleClose = () => {
    setShow(false);
    actionClose();
  };

  useEffect(() => {
    if (subject) {
      setSubjectType(subject.subject_type);
    }
  }, [subject]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      var mStaffData = SessionManager.getStaffDetails();
      var STAFF_ID = mStaffData.staff_id;
      const FUNCTION_NAME = "updateSubjectType.php";
      const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

      const params = {
        subject_id: subject.subject_id,
        session_id: SessionId,
        class_id: subject.class_id,
        section_id: SectionId,
        subject_type: subjectType,
        school_code: SCHOOL_CODE,
      };

      const queryString = new URLSearchParams(params).toString();
      console.log("Full URL: ", `${URL}${queryString}`);
      const response = await axios.get(URL, {
        params: params,
      });

      if (response.data.api_status === "OK") {
        myToast.notify_success("Edit Paper  Update successfully!");
        handleClose();
        history.push("/AdminMain/ClassSubject");
        window.location.reload();
      } else {
        myToast.notify_warning(
          response.data.api_remark || "Rename failed. Please try again."
        );
      }
    } catch (error) {
      console.error("Rename error:", error);
      myToast.notify_error("An error occurred while renaming the class.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Class Subject</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Subject Type</Form.Label>
            <Form.Select
              value={subjectType}
              onChange={(e) => setSubjectType(e.target.value)}
            >
              <option value="main">Main Subject</option>
              <option value="alternate">Alternate Subject</option>
            </Form.Select>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={actionClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={loading}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MdlEditClassSubject;
