import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";

const MdlEditExam = ({ onClose, examName, onSave }) => {
  const [loggedIn] = useState(localStorage.getItem("token") !== null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [ExamId, setExamId] = useState();
  const [ExamNameOld, setExamNameOld] = useState();
  const [ExamNameNew, setExamNameNew] = useState();
  const [SessionId, setSessionId] = useState();

  useEffect(() => {
    setExamId(examName.exam_id);
    setExamNameOld(examName.exam_name);
    setExamNameNew(examName.exam_name);
    setSessionId(examName.session_id);
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      var mStaffData = SessionManager.getStaffDetails();
      var STAFF_ID = mStaffData.staff_id;
      const FUNCTION_NAME = "updateExamName.php";
      const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

      const params = {
        school_code: SCHOOL_CODE,
        staff_id: STAFF_ID,
        exam_id: ExamId,
        exam_name_old: ExamNameOld,
        exam_name_new: ExamNameNew,
        session_id: SessionId,
      };

      const queryString = new URLSearchParams(params).toString();
      console.log("Full URL: ", `${URL}${queryString}`);
      const response = await axios.get(URL, {
        params: params,
      });

      if (response.data.api_status === "OK") {
        myToast.notify_success("Exam Name  Update successfully!");
        //window.location.reload();
        //handleClose();
        onSave(SessionId);
      } else {
        myToast.notify_warning(
          response.data.api_remark || "Rename failed. Please try again."
        );
      }
    } catch (error) {
      console.error("Rename error:", error);
      myToast.notify_error("An error occurred while renaming the class.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        className="mdladdpaper-modal-header  align-items-center"
      >
        <div className="mdladdpaper-title">Edit Exam</div>
      </Modal.Header>

      <Modal.Body className="MdlEditPaper-modal-body">
        <Form>
          <div className="row">
            <Form.Group className="col-md-12 MdlEditPaper-form-group">
              <Form.Label className="MdlEditPaper-form-label">
                Exam Name
              </Form.Label>
              <Form.Control
                type="text"
                value={ExamNameNew}
                onChange={(e) => setExamNameNew(e.target.value)}
                placeholder="Enter Exam Name"
                className="MdlEditPaper-form-control"
              />
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>

      <Modal.Footer className="mdleditexam-modal-footer">
        <Button
          variant="primary"
          className="mdleditexam-btn"
          onClick={handleSubmit}
        >
          Save Changes
        </Button>
        <Button
          variant="secondary"
          className="mdleditexam-btn"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MdlEditExam;
