import React, { useState, useEffect } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import { Modal, Button, Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import myToast from "../Utils/MyToast";

const MdlAddSubjectMasterList = ({ actionClose }) => {
  const token = localStorage.getItem("token");
  const [loggedIn, setLoggedIn] = useState(!!token);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [SubjectName, setSubjectName] = useState();

  const handleClose = () => {
    setShow(false);
    actionClose();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      const FUNCTION_NAME = "AddNewMasterSubject.php";
      const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

      const params = new URLSearchParams();
      params.append("subject_name", SubjectName);
      params.append("school_code", SCHOOL_CODE);

      const queryString = new URLSearchParams(params).toString();
      console.log("Full URL: ", `${URL}${queryString}`);
      const response = await axios.post(URL, params);
      if (response.data.api_status === "OK") {
        myToast.notify_success("Subject Added successfully!");
        handleClose();
      } else {
        myToast.notify_warning(
          response.data.api_remark || "Rename failed. Please try again."
        );
      }
    } catch (error) {
      console.error("Rename error:", error);
      myToast.notify_error("An error occurred while renaming the class.");
    } finally {
      setLoading(false);
    }
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Subject
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <MyLoader />
        ) : (
          <div className="row align-items-center">
            <div className="col-md-4 text-end">
              <Form.Label className="subject-list-label">
                Subject Name
              </Form.Label>
            </div>
            <div className="col-md-8">
              <Form.Control
                placeholder="Add New Subject"
                style={{ margin: "3% 0%", fontSize: "18px" }}
                value={SubjectName}
                onChange={(e) => setSubjectName(e.target.value)}
              />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end w-100">
          <Button variant="primary" className="me-2" onClick={handleSubmit}>
            Done
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default MdlAddSubjectMasterList;
