import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import SessionManager from '../Utils/SessionManager';
import MyLoader from '../Spinner/MyLoader';

const FeeReceiptSettings = () => {
  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getFeeReceiptSettingsData();
  }, []);

  const getFeeReceiptSettingsData = async () => {
    try {
      setLoading(true);
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      const FUNCTION_NAME = 'getFeeReceiptSettingsData.php';
      const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

      const { data: response } = await axios.get(URL, {
        params: { school_code: SCHOOL_CODE },
      });

      setLoading(false);
      if (response.api_status === 'OK') {
        setSettings(response.data);
        //alert(JSON.stringify(response.data))
      } else {
        myToast.notify_warning(response.api_remark);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const toggleSetting = async (setting_id, setting_value) => {
    setLoading(true);
   
    if(setting_value=='1')
    {
      setting_value=0;
    }
    else{
      setting_value=1;
    }


    try {
        const SCHOOL_CODE = SessionManager.getSchoolCode();
        const FUNCTION_NAME = 'updateFeeReceiptSetting.php';
        const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

        const params = {
            school_code: SCHOOL_CODE,
            setting_id:setting_id,
            status:setting_value
        };

        const queryString = new URLSearchParams(params).toString();
        console.log("Full URL: ", `${URL}${queryString}`);
        const response = await axios.get(URL, {
            params: params
        });

        if (response.data.api_status === 'OK') {
            alert('Fee Receipt Settings Update successfully!');
            window.location.reload();
        } else {
            myToast.notify_warning(response.data.api_remark || 'Rename failed. Please try again.');
        }
    } catch (error) {
        console.error('Rename error:', error);
        myToast.notify_error('An error occurred while renaming the class.');
    } finally {
        setLoading(false);
    }
  };

  return (
    <div className="content">
      <div className="content-header">
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li>
              <i className="fa fa-home" aria-hidden="true" />
              <a href="#">Fee Receipt Settings</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="bg_page_white_rounded box_shadow animated fadeInUp" style={{ '--mMargin': '30px' }}>
        <div className="container FeeRSettings-container">
          <h2 className="FeeRSettings-title">Fee Receipt Settings</h2>
          {loading?<MyLoader></MyLoader>:''}
          <div className="row g-3">
            {settings.length > 0 &&
              settings.map((item, index) => (
                <div className="col-6" key={index}>
                  <div className="FeeRSettings-box">
                    <label className="FeeRSettings-label">{item.setting_name}</label>
                    <input
                      type="checkbox"
                      className="FeeRSettings-checkbox"
                      checked={item.setting_value == '1' ? true : false}
                      onChange={() => toggleSetting(item.setting_id, item.setting_value)}
                    />
                  </div>
                </div>
              ))
            }
          </div>

        </div>

      </div>
    </div>
  );
};

export default FeeReceiptSettings;
