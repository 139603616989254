import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import icons_school_bus from "../resources/icons/icons_school_bus.jpeg";
import "../css/Admin_css/admin-customize.css";
import MdlAddTransportVehicles from "../dialog/MdlAddTransportVehicles";
import MdlEditTransportVehicles from "../dialog/MdlEditTransportVehicles";
import myToast from "../Utils/MyToast";
import axios from "axios";
import SessionManager from "../Utils/SessionManager";
import MyLoader from "../Spinner/MyLoader";

const TransportVehicles = () => {
  const [isOpenAddTransportVehicles, setIsOpenAddTransportVehicles] =
    useState(false);
  const [isOpenEditTransportVehicles, setIsOpenEditTransportVehicles] =
    useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [loggedIn] = useState(!!localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    getAllVehicles();
  }, []);

  const getAllVehicles = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getAllVehicles.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    axios
      .get(URL, { params: { school_code: SCHOOL_CODE } })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);

        if (response.api_status === "OK") {
          setVehicles(response.data);
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
        myToast.notify_error("Failed to load vehicles.");
      });
  };

  const handleDeleteVehicle = (vehicleId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this vehicle?"
    );
    if (!confirmDelete) return;

    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "deleteVehicle.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    axios
      .post(URL, { vehicle_id: vehicleId, school_code: SCHOOL_CODE })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        if (response.api_status === "OK") {
          myToast.notify_success("Vehicle deleted successfully.");
          setVehicles(
            vehicles.filter((vehicle) => vehicle.vehicle_id !== vehicleId)
          );
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error deleting vehicle:", error);
        myToast.notify_error("Failed to delete vehicle.");
      });
  };

  const toggleAddTransportVehicles = () => {
    setIsOpenAddTransportVehicles(!isOpenAddTransportVehicles);
    if (isOpenAddTransportVehicles) {
      getAllVehicles();
    }
  };

  const toggleEditTransportVehicles = (vehicle = null) => {
    setSelectedVehicle(vehicle);
    setIsOpenEditTransportVehicles(!isOpenEditTransportVehicles);
    getAllVehicles();
  };

  if (!loggedIn) return <Redirect to="/Login" />;
  if (loading) return <MyLoader />;

  return (
    <Switch>
      <Route path={"/AdminMain/TransportVehicles"} exact>
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li>
                  <i className="fa fa-home" aria-hidden="true" />
                  <a href="#">Transport Vehicles</a>
                </li>
              </ul>
            </div>
          </div>

          <div
            className="animated fadeInUp bg_page_white_rounded box_shadow"
            style={{ "--mMargin": "40px" }}
          >
            <div className="Vehicles_Transport-container">
              <div className="row Vehicles_Transport-staffcenter">
                <div className="col-md-9">
                  <h4 className="mar_top1">Vehicles List</h4>
                </div>
                <div className="col-md-3">
                  <button
                    className="Vehicles_Transport-add-button"
                    onClick={toggleAddTransportVehicles}
                  >
                    Add New Vehicle
                  </button>
                </div>
              </div>

              <div className="row mp-4">
                {vehicles.map((vehicle) => (
                  <div key={vehicle.vehicle_id} className="col-md-6 pt-2">
                    <div className="Vehicles_Transport-vehicle-card">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="Vehicles_Transport-vehicle-image-container">
                            <img
                              src={vehicle.vehicle_image || icons_school_bus}
                              alt="Vehicle"
                            />
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="Vehicles_Transport-vehicle-details">
                            <p>
                              <strong>Vehicle Type:</strong>{" "}
                              {vehicle.vehicle_type}
                            </p>
                            <hr />
                            <p>
                              <strong>Vehicle Name:</strong>{" "}
                              {vehicle.vehicle_name}
                            </p>
                            <p>
                              <strong>Vehicle Number:</strong>{" "}
                              {vehicle.vehicle_number}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-3">
                          <button
                            className="edit_button"
                            onClick={() => toggleEditTransportVehicles(vehicle)}
                          >
                            Edit
                          </button>
                        </div>
                        <div className="col-md-3">
                          <button
                            className="Vehicles_Transport-delete-button"
                            onClick={() =>
                              handleDeleteVehicle(vehicle.vehicle_id)
                            }
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {isOpenAddTransportVehicles && (
              <MdlAddTransportVehicles
                actionClose={toggleAddTransportVehicles}
              />
            )}
            {isOpenEditTransportVehicles && selectedVehicle && (
              <MdlEditTransportVehicles
                actionClose={toggleEditTransportVehicles}
                vehicle={selectedVehicle}
              />
            )}
          </div>
        </div>
      </Route>
    </Switch>
  );
};

export default TransportVehicles;
