import React, { useState, useEffect } from "react";
import MyLoader from "../Spinner/MyLoader";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import boyImage from "../img/boy.jpg";
import { FaUser, FaChalkboardTeacher, FaClipboardList } from "react-icons/fa";
import AccessoriesCollection from "./AccessoriesCollection";
import AccessoriesDetails from "./AccessoriesDetails";
import IssueAccessories from "./IssueAccessories";

const AccessoriesBeneficiary = () => {
  const [loggedIn, setLoggedIn] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Example of fetching session status or other initialization logic
    const checkLoginStatus = async () => {
      // Session check or loading logic can go here
      // Example: setLoggedIn(SessionManager.isLoggedIn());
    };
    checkLoginStatus();
  }, []);

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  } else if (loading) {
    return <MyLoader />;
  } else {
    return (
      <Router>
        <Switch>
          <Route
            path="/AdminMain/AccessoriesBeneficiary"
            exact
            render={() => (
              <div className="content">
                <div className="content-header">
                  <div className="leftside-content-header">
                    <ul className="breadcrumbs">
                      <li>
                        <i className="fa fa-home" aria-hidden="true" />
                        <a href="#"> Accessories Beneficiary </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="bg_page_grey_rounded box_shadow"
                  style={{ "--mMargin": "50px" }}
                >
                  <div
                    className="AccessoriesBeneficiary-reduced-width"
                    style={{ "--mMargin": "80px" }}
                  >
                    <div className="IssueAccessories-header-section">
                      <button className="IssueAccessories-select-student-button">
                        Select Student{" "}
                        <span className="IssueAccessories-arrow"> &gt; </span>{" "}
                      </button>
                    </div>
                    <div className="bg_card_burnt_orange_outline AccessoriesBeneficiary-content">
                      <div className="AccessoriesBeneficiary-profileContainer">
                        <div className="AccessoriesBeneficiary-profile">
                          <div className="AccessoriesBeneficiary-avatar">
                            <img src={boyImage} alt="staff" />
                          </div>
                          <div className="AccessoriesBeneficiary-nameContainer">
                            <h5 className="AccessoriesBeneficiary-name">
                              AMRITA PANDEY
                            </h5>
                            <p className="AccessoriesBeneficiary-guardian">
                              OMPRAKASH
                            </p>
                          </div>
                        </div>
                        <div className="AccessoriesBeneficiary-actions">
                          <Link
                            to={{ pathname: "/AdminMain/IssueAccessories" }}
                          >
                            <button className="AccessoriesBeneficiary-button AccessoriesBeneficiary-collectNow">
                              ISSUE ACCESSORIES
                            </button>
                          </Link>

                          <Link
                            to={{
                              pathname: "/AdminMain/AccessoriesCollection",
                            }}
                          >
                            <button className="AccessoriesBeneficiary-button AccessoriesBeneficiary-collectNow">
                              COLLECT NOW
                            </button>
                          </Link>

                          <Link
                            to={{ pathname: "/AdminMain/AccessoriesDetails" }}
                          >
                            <button className="AccessoriesBeneficiary-button AccessoriesBeneficiary-details">
                              DETAILS
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="AccessoriesBeneficiary-divider"></div>
                      <div className="AccessoriesBeneficiary-info">
                        <div className="AccessoriesBeneficiary-box AccessoriesBeneficiary-amount">
                          <span>Amount</span>
                          <span>Rs:9000</span>
                        </div>
                        <div className="AccessoriesBeneficiary-box AccessoriesBeneficiary-discount">
                          <span>Discount</span>
                          <span>Rs:0</span>
                        </div>
                        <div className="AccessoriesBeneficiary-box AccessoriesBeneficiary-payable">
                          <span>Payable</span>
                          <span>Rs:9000</span>
                        </div>
                        <div className="AccessoriesBeneficiary-box AccessoriesBeneficiary-paid">
                          <span>Paid</span>
                          <span>Rs:0</span>
                        </div>
                        <div className="AccessoriesBeneficiary-box AccessoriesBeneficiary-due">
                          <span>Due</span>
                          <span>Rs:9000</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          />
        </Switch>
        <Route
          path="/AdminMain/AccessoriesCollection"
          component={AccessoriesCollection}
        />
        <Route
          path="/AdminMain/AccessoriesDetails"
          component={AccessoriesDetails}
        />
        <Route
          path="/AdminMain/IssueAccessories"
          component={IssueAccessories}
        />
      </Router>
    );
  }
};

export default AccessoriesBeneficiary;
