import React, { useState, useEffect } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import COLORS from "../resources/colors/COLORS";
import SessionManager from "../Utils/SessionManager";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import SelectClass from "../ChildComponent/SelectClass";
import boyImage from "../img/boy.jpg";
import { BsFacebook, BsTwitter, BsLinkedin, BsGoogle } from "react-icons/bs";

const StudentPassword = () => {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("token") !== null
  );
  const [Loading, setLoading] = useState(false);
  const [LoadClass, setLoadClass] = useState(false);
  const [SESSION_ID, setSESSION_ID] = useState("");
  const [CLASS_ID, setCLASS_ID] = useState("");
  const [SECTION_ID, setSECTION_ID] = useState("");
  const [SELECTED_CLASS_POSITION, setSELECTED_CLASS_POSITION] = useState(0);
  const [CLASS_SECTION_NAME, setCLASS_SECTION_NAME] = useState("");
  const [mStudentList, setMStudentList] = useState([]);
  const [Inputs, setInputs] = useState([]);
  const [showShareIcons, setShowShareIcons] = useState(false);
  const eduokLink = "https://eduokapp.com/download";

  useEffect(() => {
    setLoadClass(true);
  }, []);

  useEffect(() => {
    StudentList();
  }, [CLASS_ID, SECTION_ID]);

  const handleShareClick = () => {
    setShowShareIcons(!showShareIcons);
  };

  const CallBackClassSelect = (
    class_id,
    section_id,
    session_id,
    selected_position,
    class_name,
    section_name
  ) => {
    const classSectionName =
      section_id === "No" ? class_name : class_name + ":" + section_name;
    setSECTION_ID(section_id);
    setSESSION_ID(session_id);
    setCLASS_SECTION_NAME(classSectionName);
    setSELECTED_CLASS_POSITION(selected_position);
    setCLASS_ID(class_id);
  };

  const StudentList = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getStudentAuthCredentials.php?";
    // const URL = ${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME};
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          class_id: CLASS_ID,
          section_id: SECTION_ID,
          session_id: SESSION_ID,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        // alert(JSON.stringify(response));
        if (response.api_status === "OK") {
          setMStudentList(response.data);
          const formattedData = response.data.map((item) => ({
            id: item.id,
            student_roll_no: item.student_roll_no,
            sr_admission_no: item.sr_admission_no,
            disable_roll_no: true,
            disable_adm_no: true,
          }));
          setInputs(formattedData);
        } else {
          // myToast.notify_warning(response.api_remark);
          setMStudentList([]);
        }
      })
      .catch((error) => {
        console.log("error>>>>>" + error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  return (
    <Router>
      <Route
        path="/AdminMain/StudentPassword"
        exact
        render={() => (
          <div className="content" style={{ background: COLORS.whitesmoke }}>
            <div
              className="content-header"
              style={{ background: COLORS.primary_color_shade6 }}
            >
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li>
                    <i className="fa fa-home" aria-hidden="true" />
                    <a href="#"> Student Password </a>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="animated fadeInUp bg_page_white_rounded box_shadow"
              style={{
                "--mMargin": "50px",
                marginTop: "20px",
                padding: "20px",
              }}
            >
              {LoadClass && (
                <SelectClass
                  value={LoadClass}
                  action={CallBackClassSelect}
                  selected_position={SELECTED_CLASS_POSITION}
                />
              )}

              {Loading ? (
                <MyLoader />
              ) : Inputs.length > 0 ? (
                <div className="StudentLoginNew_container">
                  {mStudentList.map((item, index) => (
                    <div key={index} style={{ width: "100%" }}>
                      <div className="Sln_container">
                        <div className="Sln_box">
                          <div className="Sln_mainRow">
                            <div className="Sln_imageWrapper">
                              <img
                                src={boyImage}
                                alt="Student"
                                className="Sln_image"
                              />
                            </div>
                            <p className="Sln_detail">
                              <span className="Sln_label">Student:</span>{" "}
                              {item.stu_name}
                            </p>
                            <p className="Sln_detail">
                              <span className="Sln_label">Father:</span>{" "}
                              {item.father_name}
                            </p>
                            <p className="Sln_detail">
                              <span className="Sln_label">Village:</span>{" "}
                              {item.village_town}
                            </p>
                            <p className="Sln_detail">
                              <span className="Sln_label">Class:</span>{" "}
                              {item.class[0].class_name}
                            </p>

                            <div className="Sln_rollBox">
                              <p className="Sln_label">Roll No.</p>
                              <div className="Sln_roll">
                                {item.student_roll_no}
                              </div>
                            </div>
                          </div>
                          <div className="Sln_additionalInfo1 my-4">
                            {/* User Info Section (Side-by-side) */}
                            <div className="Sln_userInfo">
                              <p>
                                <strong>User Id:</strong>{" "}
                                {item.student_enrollment_no}
                              </p>
                              <p>
                                <strong>Password:</strong> {item.password}
                              </p>
                              <p>
                                <strong>Mobile Number:</strong>{" "}
                                {item.contact_details &&
                                  item.contact_details[0].contact_number}
                              </p>
                            </div>

                            {/* WhatsApp Share Button */}
                            <a
                              href={`https://wa.me/${item.contact_number}?text=Hello%20${item.stu_name},%20Your%20User%20ID%20is:%20${item.student_enrollment_no}%20and%20Password%20is:%20${item.password}.%20Please%20login%20using%20the%20Eduok%20App.`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn btn-success"
                            >
                              Share on WhatsApp
                            </a>

                            {/* Share Button */}
                            <button
                              onClick={handleShareClick}
                              className="btn btn-info"
                            >
                              Share
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>No students found</div>
              )}
            </div>
          </div>
        )}
      />
    </Router>
  );
};

export default StudentPassword;
