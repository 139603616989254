import React, { useState } from 'react'
import '../css/Admin_css/admin-customize.css';
import MyLoader from '../Spinner/MyLoader';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import COLORS from '../resources/colors/COLORS';
import MdlSelectExamClassAndStudent from '../dialog/MdlSelectExamClassAndStudent';
import ExamAddMarkSubjectWise from './ExamAddMarkSubjectWise';
import boyImage from "../img/boy.jpg";

const ExamAddMarkStudentWise = (props) => {
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(true);
  const [selectedSubjectPosition, setSelectedSubjectPosition] = useState(null);
  const [SelectedStudentId, setSelectedStudentId] = useState();
  const [SelectedStudent, setSelectedStudent] = useState();
  const EXAM_ID = props.match.params.exam_id;
  const SESSION_ID = props.match.params.session_id;
  const EXAM_NAME = props.match.params.exam_name;
  const [isOpenExamAddMarkStudent, setExamAddMarkStudent] = useState(false);

  const staticSubjects = ["Mathematics", "Science", "English", "Hindi", "Social Studies"];

  const handlePrev = () => { };

  const handleNext = () => { };


  const toggleExamAddMarkStudent = () => {
    setExamAddMarkStudent(!isOpenExamAddMarkStudent);
  }

  const handelStudentDetails = (student_id, selected_item) => {
    alert(`${student_id} ${selected_item}`);
    setSelectedStudentId(student_id);
    setSelectedStudent(selected_item);
    setExamAddMarkStudent(false);
  }

  if (!loggedIn) return <Redirect to='/Login' />;
  if (loading) return <MyLoader />;

  return (
    <Router>
      <Switch>
        <Route exact path='/AdminMain/ExamAddMarkStudentWise/:exam_id/:session_id/:exam_name/'>
          <div className="content">
            <div className="content-header">
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Add Mark Student Wise</a></li>
                </ul>
              </div>
            </div>

            <div className="animated fadeInUp bg_page_white_rounded box_shadow p-4" style={{ '--mMargin': '50px' }}>

              {/* Header Box */}
              <div className="text-center mb-4">
                <div style={{ padding: '6px', color: '#fff', borderRadius: '8px', background: COLORS.primary_color_shade5, display: 'inline-block', cursor: 'pointer' }}>
                  <h4 className="arrow" onClick={toggleExamAddMarkStudent}>Add Mark Student Wise</h4>
                </div>
              </div>

              {/* Student Information Box */}
              <div className="SettlementNew-border d-flex align-items-center justify-content-between p-3 mt-3" style={{ border: '1px solid #0bb5f4', borderRadius: '10px' }}>
                <div className="d-flex align-items-center">
                  <img src={boyImage} alt="user" className="rounded-circle" style={{ width: '50px', height: '50px' }} />
                  <div className="ml-3">
                    <h5 className="mb-0">Ram Parkash S/D/O Shyam Parkash</h5>
                    <p className="mb-0">Ram Nagar</p>
                  </div>
                </div>

                <div className="text-center">
                  <p className="mb-1"><strong>Roll No:</strong> 12</p>
                  <p><strong>Class:</strong> Play Group</p>
                </div>

                <div>
                  <button className="btn btn-primary mx-2" onClick={handlePrev}>Previous</button>
                  <button className="btn btn-primary mx-2" onClick={handleNext}>Next</button>
                </div>
              </div>

              {/* Subject Selection Box */}
              <div className="bg_card_blue_outline mt-4 p-3" style={{ borderRadius: "10px", border: "1px solid #0bb5f4" }}>
                <h5 className="mb-3">Select Subject</h5>
                <div className="d-flex flex-wrap justify-content-center">
                  {staticSubjects.map((subject, index) => (
                    <div key={index} className="mx-2 mb-2">
                      <div
                        className={`gravity_center ${selectedSubjectPosition === index ? "bg_class_select" : "bg_white_outline_grey"}`}
                        onClick={() => setSelectedSubjectPosition(index)}
                        style={{
                          cursor: "pointer",
                          padding: "10px 20px",
                          borderRadius: "8px",
                          border: selectedSubjectPosition === index ? "2px solid red" : "1px solid grey",
                          backgroundColor: selectedSubjectPosition === index ? "yellow" : "white",
                          color: selectedSubjectPosition === index ? "red" : "grey",
                          transition: "all 0.3s ease",
                        }}
                      >
                        <p style={{ fontSize: "16px", margin: "0" }}>{subject}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Subject Marks Box */}
              <div className="Stu_mark mt-4">
                <div className="ExamAddMarkSubjectWise-info">
                  <div className="ExamAddMarkSubjectWise-box2 d-flex justify-content-between align-items-center">
                    <p className="ExamAddMarkSubjectWise-text mb-0">
                      <strong className="ExamAddMarkSubjectWise-label">Subject Paper:</strong> Mathematics
                    </p>
                    <p className="ExamAddMarkSubjectWise-text mb-0">
                      <strong className="ExamAddMarkSubjectWise-label">Max Marks:</strong> 100
                    </p>
                    <p className="ExamAddMarkSubjectWise-text mb-0 d-flex align-items-center">
                      <strong className="ExamAddMarkSubjectWise-label me-2">Obt Marks:</strong>
                      <input
                        type="text"
                        className="ExamAddMarkSubjectWise-field"
                        value="75"
                        readOnly
                        style={{ width: '60px', textAlign: 'center' }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {isOpenExamAddMarkStudent && (
              <MdlSelectExamClassAndStudent
                actionClose={toggleExamAddMarkStudent}
                actionSelect={handelStudentDetails}
                exam_id={EXAM_ID}
                session_id={SESSION_ID}
              />
            )}
          </div>
        </Route>
        <Route path='/AdminMain/ExamAddMarkSubjectWise/:exam_id/:session_id/:exam_name/' component={ExamAddMarkSubjectWise} />
      </Switch>
    </Router>
  );
};

export default ExamAddMarkStudentWise;
