import React, { useState, useEffect } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import { Modal, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import myToast from "../Utils/MyToast";

const MdlAddClassSubjectList = ({
  class_id,
  class_name,
  class_subject,
  actionClose,
  section_id,
  session_id,
}) => {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("token") !== null
  );
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [freshSubjectList, setFreshSubjectList] = useState([]);

  useEffect(() => {
    getSubjectMasterList();
  }, []);

  const handleClose = () => {
    setShow(false);
    actionClose();
  };

  const handleSubmit = () => {
    const selectedSubjects = getSelectedSubjects();
    // console.log("Selected Subjects:", selectedSubjects);

    if (selectedSubjects.length > 0) {
      submitSubject(selectedSubjects);
      setShow(false);
      actionClose();
    } else {
      alert("Select Subject");
    }
  };

  const submitSubject = async (selectedSubjects) => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const subject_list = JSON.stringify(selectedSubjects);
    const API_NAME = "ApiAddClassSubject.php";
    const URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      API_NAME;
    const params = {
      school_code: SCHOOL_CODE,
      class_id: class_id,
      section_id: section_id,
      session_id: session_id,
      subject_list: subject_list,
    };
    const queryString = new URLSearchParams(params).toString();
    //console.log("Full URL: ", `${URL}${queryString}`);

    try {
      const response = await axios.get(URL, {
        params: params,
      });
      setLoading(false);

      if (response.data.api_status === "OK") {
        myToast.notify_success("Updated Successfully");
        actionClose();
      } else {
        myToast.notify_warning(response.data.api_remark);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching subjects:", error);
    }
  };

  const getSubjectMasterList = async () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const API_NAME = "getSubjectMasterList.php";
    const URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      API_NAME;

    try {
      const response = await axios.get(URL, {
        params: { school_code: SCHOOL_CODE },
      });
      setLoading(false);

      if (response.data.api_status === "OK") {
        getNotAddedSubject(response.data.data);
      } else {
        myToast.notify_warning(response.data.api_remark);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching subjects:", error);
    }
  };

  const getNotAddedSubject = (subjectMasterList) => {
    const newSubjects = subjectMasterList
      .filter(
        (item) =>
          !class_subject.some(
            (subject) => subject.subject_id === item.subject_id
          )
      )
      .map((item) => ({
        ...item,
        main_subject: true,
        alt_subject: false,
        selected: false, // Default: not selected
      }));

    setFreshSubjectList(newSubjects);
  };

  const handleSubjectTypeChange = (index, value) => {
    setFreshSubjectList((prevList) =>
      prevList.map((subject, i) =>
        i === index
          ? {
              ...subject,
              main_subject: value === "main",
              alt_subject: value === "alternate",
            }
          : subject
      )
    );
  };

  const handleCheckboxChange = (index) => {
    setFreshSubjectList((prevList) =>
      prevList.map((subject, i) =>
        i === index ? { ...subject, selected: !subject.selected } : subject
      )
    );
  };

  const getSelectedSubjects = () => {
    return freshSubjectList
      .filter((subject) => subject.selected) // Only include selected subjects
      .map((subject) => ({
        subject_id: subject.subject_id,
        subject_type: subject.main_subject ? "MAIN" : "ALTERNATIVE",
      }));
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Add Subject To Class: <strong>{class_name}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <MyLoader />
        ) : (
          <div className="row">
            {freshSubjectList.map((item, index) => (
              <div key={index} className="col-md-6" style={{ padding: "5px" }}>
                <div
                  style={{
                    border: "1px solid #ddd",
                    padding: "10px",
                    borderRadius: "8px",
                    backgroundColor: "#f9f9f9",
                    boxShadow: "2px 2px 6px rgba(0,0,0,0.1)",
                  }}
                >
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <input
                        type="checkbox"
                        id={`checkbox_id_${index}`}
                        checked={item.selected}
                        onChange={() => handleCheckboxChange(index)}
                        style={{ transform: "scale(2)" }}
                      />
                      <label
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          marginLeft: "10px",
                        }}
                      >
                        {item.subject_name}
                      </label>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label style={{ display: "flex", alignItems: "center" }}>
                        <input
                          type="radio"
                          name={`subject_type_${index}`}
                          value="main"
                          checked={item.main_subject}
                          onChange={() =>
                            handleSubjectTypeChange(index, "main")
                          }
                          style={{
                            transform: "scale(1.5)",
                            marginRight: "8px",
                          }}
                          disabled={!item.selected} // Disable if not selected
                        />{" "}
                        Main Subject
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label style={{ display: "flex", alignItems: "center" }}>
                        <input
                          type="radio"
                          name={`subject_type_${index}`}
                          value="alternate"
                          checked={item.alt_subject}
                          onChange={() =>
                            handleSubjectTypeChange(index, "alternate")
                          }
                          style={{
                            transform: "scale(1.5)",
                            marginRight: "8px",
                          }}
                          disabled={!item.selected} // Disable if not selected
                        />{" "}
                        Alternate Subject
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Submit Subject
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MdlAddClassSubjectList;
