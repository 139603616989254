import React, { useState } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import myToast from "../Utils/MyToast";
import { useLocation } from "react-router-dom";
import "../css/Admin_css/admin-customize.css";

const AddQualification = () => {
  const location = useLocation();
  const AddStaff_id = location.state?.AddStaffQly;
  const [loading, setLoading] = useState(false);
  const [degree, setDegree] = useState("");
  const [university, setUniversity] = useState("");
  const [year, setYear] = useState("");
  const [percentage, setPercentage] = useState("");
  const [qualifications, setQualifications] = useState([]);

  const isValidInput = () => {
    if (!degree) return alert("Please Enter your Qualification!");
    if (!university) return alert("Please Enter your University!");
    if (!year) return alert("Enter your Passing Year!");
    if (!percentage) return alert("Please Enter your Percentage!");
    return true;
  };

  const regSubmit = async () => {
    if (!isValidInput()) return;

    const newQualification = {
      degree,
      university,
      passing_year: year,
      percentage,
    };
    setQualifications([...qualifications, newQualification]);

    const qualify = JSON.stringify([...qualifications, newQualification]);
    alert(`Hi this is test: ${qualify}`);

    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "AddStaffNewQualification.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    try {
      const response = await axios.get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          staff_id: AddStaff_id,
          qualification: qualify,
        },
      });

      setLoading(false);
      if (response.data.api_status === "OK") {
        alert("Successfully Updated!");
        window.location.reload();
      } else {
        myToast.notify_warning(response.data.api_remark);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  if (loading) return <MyLoader />;

  return (
    <div className="content">
      <div className="content-header">
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li>
              <i className="fa fa-home" aria-hidden="true" />{" "}
              <a href="#"> Add Qualification </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        className="bg_page_white_rounded box_shadow animated fadeInUp"
        style={{ "--mMargin": "30px" }}
      >
        <h2 className="qualification-heading">Add Qualification</h2>
        <div className="heading-divider"></div>
        {/* Input Fields in Grid Format */}
        <div className="row">
          <div className="col-md-6">
            <label className="col-form-label">Degree/Diploma:</label>
            <input
              type="text"
              value={degree}
              onChange={(e) => setDegree(e.target.value)}
              className="form-control"
            />
          </div>

          <div className="col-md-6">
            <label className="col-form-label">University/Institute:</label>
            <input
              type="text"
              value={university}
              onChange={(e) => setUniversity(e.target.value)}
              className="form-control"
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-6">
            <label className="col-form-label">Passing Year:</label>
            <input
              type="text"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              className="form-control"
            />
          </div>

          <div className="col-md-6">
            <label className="col-form-label">Percentage:</label>
            <input
              type="text"
              value={percentage}
              onChange={(e) => setPercentage(e.target.value)}
              className="form-control"
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12 text-center">
            <button
              className="btn btn-success btn-lg w-100"
              onClick={regSubmit}
            >
              Submit Qualification
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddQualification;
