import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import axios from "axios";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Form from "react-bootstrap/Form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MyLoader from "../Spinner/MyLoader";
import myToast from "../Utils/MyToast";

const EditStaff = () => {
  const location = useLocation();
  const history = useHistory();
  const { staffData } = location.state || {};
  const { qualifications } = location.state || [];
  const [formData, setFormData] = useState({
    Loading: true,
    staffName: "",
    mDepartment: [],
    mstaffRole: [],
    department: "",
    staffRole: "",
    loginType: "",
    phone: "",
    email: "",
    doj: "",
    dob: "",
    gender: "",
    religion: "",
    AdharNo: "",
    nationality: "",
    Village: "",
    degree: "",
    university: "",
    passingYear: "",
    percentage: "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (staffData) {
      setFormData((prev) => ({
        ...prev,
        Loading: false,
        staffName: staffData.staff_name || "",
        department: staffData.department || "",
        staffRole: staffData.staffRole || "",
        loginType: staffData.loginType || "",
        phone: staffData.staff_phone || "",
        email: staffData.staff_email || "",
        doj: staffData.joining_date || "",
        dob: staffData.staff_dob || "",
        gender: staffData.staff_gender || "",
        religion: staffData.religion || "",
        AdharNo: staffData.aadhar_no || "",
        nationality: staffData.nationality || "",
        Village: staffData.staff_address || "",
        degree: qualifications ? qualifications.staff_degree : "",
        university: qualifications ? qualifications.staff_university : "",
        passingYear: qualifications ? qualifications.staff_passing_year : "",
        percentage: qualifications ? qualifications.staff_percentage : "",
      }));
    }
  }, [staffData, qualifications]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleChangeDepartment = (e) => {
    setFormData((prev) => ({
      ...prev,
      department: e.target.value,
    }));
  };

  const handleChangeStaffRole = (e) => {
    setFormData((prev) => ({
      ...prev,
      staffRole: e.target.value,
    }));
  };

  const handleChangeloginType = (e) => {
    setFormData((prev) => ({
      ...prev,
      loginType: e.target.value,
    }));
  };

  const handleChangeGender = (e) => {
    setFormData((prev) => ({
      ...prev,
      gender: e.target.value,
    }));
  };

  const handleChangeReligion = (e) => {
    setFormData((prev) => ({
      ...prev,
      religion: e.target.value,
    }));
  };

  const isValidInput = () => {
    // Add validation logic here
    return true;
  };

  const handleSubmit = async () => {
    if (!isValidInput()) return;

    setLoading(true);
    const staffId = staffData.id;
    const FUNCTION_NAME = "updateStaffQualification.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    try {
      const response = await axios.post(URL, {
        id: staffId,
        qualification: formData.degree,
        university: formData.university,
        passing_year: formData.passingYear,
        percentage: formData.percentage,
      });

      setLoading(false);
      if (response.data.api_status === "OK") {
        myToast.notify_success("Successfully Updated!");
        history.goBack();
      } else {
        myToast.notify_warning(response.data.api_remark || "Update failed");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating qualification:", error);
      myToast.notify_error("Error updating qualification");
    }
  };

  if (formData.Loading) {
    return <MyLoader />;
  }

  return (
    <div className="content">
      <div className="content-header">
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li>
              <i className="fa fa-home" aria-hidden="true" />
              <a href="#">Edit Staff</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="row animated fadeInUp">
        <div className="col-sm-12 col-lg-12">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="panel">
                <div
                  className="panel-content text-center"
                  style={{ minHeight: "400px" }}
                >
                  <div className="panel-body">
                    <div className="pnlSubHedingBorder">
                      <div className="pnlSubHeding heading-with-bg-w">
                        Personal Details
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 bg-white mar_bottom mar_top1">
                        <div className="circular_image1">
                          <img
                            src="https://clevelandpolicefoundation.org/wp-content/uploads/2012/09/female-placeholder.png"
                            className="img_staff1"
                            alt="student list image"
                          />
                        </div>
                        <div className="photo-row-bottom posi-rel mar_top1">
                          <input
                            type="file"
                            name="upload photo"
                            style={{ width: "168px" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div className="row">
                          {/* Staff Name */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Staff Name</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input
                                    type="text"
                                    required
                                    id="staffName"
                                    name="staff name"
                                    placeholder="Staff Name"
                                    value={formData.staffName}
                                    onChange={handleChange}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Department */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Department</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="admission_form2">
                                  <Form.Select
                                    className="staff_role staff_mar"
                                    onChange={handleChangeDepartment}
                                    value={formData.department}
                                  >
                                    <option>Select Department</option>
                                    {formData.mDepartment.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item.department_name}
                                        department_id={item.department_id}
                                      >
                                        {item.department_name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Staff Role */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Staff Role</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="admission_form2">
                                  <Form.Select
                                    className="staff_role staff_mar"
                                    onChange={handleChangeStaffRole}
                                    value={formData.staffRole}
                                  >
                                    <option>Select Role</option>
                                    {formData.mstaffRole.map((item, index) => (
                                      <option key={index} value={item}>
                                        {item}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Login Type */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Login Type</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="admission_form2">
                                  <Form.Select
                                    className="staff_role staff_mar"
                                    onChange={handleChangeloginType}
                                    value={formData.loginType}
                                  >
                                    <option>login Type</option>
                                    <option value="Admin">Admin</option>
                                    <option value="Teacher">Teacher</option>
                                    <option value="Clerk">Clerk</option>
                                    <option value="None">None</option>
                                  </Form.Select>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Phone Number */}
                          <div className="col-md-6 mar_top1">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Phone Number</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input
                                    type="tel"
                                    required
                                    id="phone"
                                    placeholder="Phone Number"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Email Address */}
                          <div className="col-md-6 mar_top1">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Email Address</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input
                                    type="email"
                                    required
                                    id="email"
                                    placeholder="Email id"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Date of Joining */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Date of Joining</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input
                                    type="date"
                                    required
                                    id="doj"
                                    placeholder="Date of Joining"
                                    value={formData.doj}
                                    onChange={handleChange}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Date of Birth */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Date of Birth</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input
                                    type="date"
                                    required
                                    id="dob"
                                    placeholder="Date of Birth"
                                    value={formData.dob}
                                    onChange={handleChange}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Gender */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0 mar_top1">
                                <label> Gender </label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="d-flex">
                                  <RadioGroup
                                    aria-label="position"
                                    name="radio_css_inline"
                                    id="inlineCssRadio"
                                    value={formData.gender}
                                    onChange={handleChangeGender}
                                    row
                                  >
                                    <FormControlLabel
                                      value="Male"
                                      control={<Radio color="primary" />}
                                      label="Male"
                                      labelPlacement="end"
                                    />
                                    <FormControlLabel
                                      value="Female"
                                      control={<Radio color="primary" />}
                                      label="Female"
                                      labelPlacement="end"
                                    />
                                  </RadioGroup>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Religion */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Religion</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="admission_form2">
                                  <Form.Select
                                    className="staff_role staff_mar"
                                    onChange={handleChangeReligion}
                                    value={formData.religion}
                                  >
                                    <option>Select Religion</option>
                                    <option value="Hindu">Hindu</option>
                                    <option value="Muslim">Muslim</option>
                                    <option value="Christian">Christian</option>
                                    <option value="Sikh">Sikh</option>
                                    <option value="Buddhist">Buddhist</option>
                                    <option value="Jain">Jain</option>
                                    <option value="Other">Other</option>
                                  </Form.Select>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Adhar Number */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Adhar Number</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input
                                    type="text"
                                    required
                                    id="AdharNo"
                                    placeholder="Adhar Number"
                                    value={formData.AdharNo}
                                    onChange={handleChange}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Nationality */}
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-4 padding_0">
                                <label>Nationality</label>
                              </div>
                              <div className="col-md-8 padding_0">
                                <div className="input-group admission_form2">
                                  <input
                                    type="text"
                                    required
                                    id="nationality"
                                    placeholder="Nationality"
                                    value={formData.nationality}
                                    onChange={handleChange}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Address Section */}
                    <div style={{ float: "left", width: "100%" }}>
                      <div className="pnlSubHedingBorder headMarginTop">
                        <div className="pnlSubHeding heading-with-bg-w">
                          Address
                        </div>
                      </div>
                      <div className="form-horizontal form-bordered">
                        <div className="form-group row">
                          <label className="col-md-2 col-form-label">
                            {" "}
                            Village/Town{" "}
                          </label>
                          <div className="col-md-10">
                            <div className="input-group">
                              <input
                                id="Village"
                                value={formData.Village}
                                onChange={handleChange}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Degree Section */}
                    <div style={{ float: "left", width: "100%" }}>
                      <div className="pnlSubHedingBorder headMarginTop">
                        <div className="pnlSubHeding heading-with-bg-w">
                          Degree
                        </div>
                      </div>
                    </div>
                    {/* Degree & University Fields */}

                    <div className="col-md-6">
                      <label className="col-form-label">Degree/Diploma:</label>
                      <input
                        type="text"
                        id="degree"
                        value={formData.degree}
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="col-form-label">
                        University/Institute:
                      </label>
                      <input
                        type="text"
                        id="university"
                        value={formData.university}
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="col-form-label">Passing Year:</label>
                      <input
                        type="text"
                        id="passingYear"
                        value={formData.passingYear}
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="col-form-label">Percentage:</label>
                      <input
                        type="text"
                        id="percentage"
                        value={formData.percentage}
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>

                    <div style={{ float: "left", width: "100%" }}>
                      <div className="row">
                        <div className="col-md-12 padding_0">
                          <button
                            className="btn btn-success"
                            onClick={handleSubmit}
                            style={{
                              margin: "2% 0px",
                              fontSize: "20px",
                              width: "100%",
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditStaff;
