import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import COLORS from '../resources/colors/COLORS';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';



export default class OldDueCollection extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {

      loggedIn: true,
      Loading: false,
      selected_student: [],
      mStudentDetails: [],
      mDueList: [],
      total_amount: 0
    }
  }
  componentDidMount() {

    const SESSION_ID = this.props.match.params.session_id;
    const STUDENT_ID = this.props.match.params.student_id;
    //alert(SESSION_ID);
    //alert(STUDENT_ID);

    this.StudentDetails();

    //this.state.selected_student[0] = JSON.parse(localStorage.getItem("selected_student"));
    //alert(this.state.selected_student[0].class[0].board_name);

    // if (this.state.selected_student.length !== 0) {
    //   this.getdueList();
    // }

  }
  getdueList() {
    var mDueData = [];
    mDueData = this.state.selected_student[0].old_due;
    for (let index = 0; index < mDueData.length; index++) {
      var amt = mDueData[index].amount - mDueData[index].amount_paid;
      if (amt != 0) {
        this.state.mDueList.push(mDueData[index]);
      }
    }
    this.setState({

    })

  }

  StudentDetails() {

    this.setState({
      Loading: true
    })
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    var mSession = SessionManager.getCurrentSession();
    const SESSION_ID = this.props.match.params.session_id;
    const STUDENT_ID = this.props.match.params.student_id;

    const Adhar_ID = "";
    const FUNCTION_NAME = 'searchStudentDetails.php'
    URL = process.env.REACT_APP_SERVER_NAME + process.env.REACT_APP_TARGET_DIR + FUNCTION_NAME
    console.log("URL>>>>>" + URL)
    axios.get(URL, {
      params: { school_code: SCHOOL_CODE, enrollment_no: STUDENT_ID, session_id: SESSION_ID, aadhar_no: Adhar_ID }
    }).then(res => res.data)
      .then((response) => {

        this.setState({
          Loading: false
        })
        console.log("Response2>>>>>" + response);
        //console.log(JSON.stringify(response));
        let api_status = response.api_status;
        // alert(api_status);

        if (api_status == "OK") {

          this.setState({

            mStudentDetails: response.data,
            mclass: response.data[0].class,
            // mContactDetails: response.data[0].contact_details,
            // mFamilyDetails: response.data[0].family_details
          })

          //console.log("response>>>>>" + this.state.mStudentDetails);

        }
        else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      }).catch((error) => {
        this.setState({
          Loading: false
        })
        console.log("error>>>>>" + error);
      });
  }
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to='/Login' />;
    }
    if (this.state.Loading) {
      return <MyLoader />
    } else {

      return (
        <div className="content">
          <div className="content-header" style={{ background: COLORS.primary_color_shade5 }}>
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" style={{ color: COLORS.white }} /><a href="#"> Old Due Collection  </a></li>
              </ul>
            </div>
          </div>
          <div className="bg_page_white_rounded box_shadow animated fadeInUp " style={{ '--mMargin': '30px' }}>
            <section className="deuold">
              <div className="deuold-container">
                <div className="deuold-row">

                  <div className="deuold-profile">
                    <div className="deuold-circle">
                      <img
                        src={
                          this.state.mStudentDetails.length !== 0
                            ? this.state.mStudentDetails[0].student_img_path
                            : ""
                        }
                        alt="Student"
                      />
                      <span className="deuold-enrollment">
                        {this.state.mStudentDetails.length !== 0
                          ? this.state.mStudentDetails[0].student_enrollment_no
                          : ""}
                      </span>
                    </div>
                  </div>


                  <div className="deuold-details">
                    <h5>
                      {this.state.mStudentDetails.length !== 0
                        ? this.state.mStudentDetails[0].stu_name
                        : ""}
                    </h5>
                    <p>
                      Father:{" "}
                      <span>
                        {this.state.mStudentDetails.length !== 0
                          ? this.state.mStudentDetails[0].father_name
                          : ""}
                      </span>
                      &nbsp;&nbsp; Board:{" "}
                      <span>
                        {this.state.mStudentDetails.length !== 0
                          ? this.state.mStudentDetails[0].class[0].board_name
                          : ""}
                      </span>
                      &nbsp;&nbsp; Medium:{" "}
                      <small>
                        {this.state.mStudentDetails.length !== 0
                          ? this.state.mStudentDetails[0].class[0].medium_name
                          : ""}
                      </small>
                    </p>
                  </div>


                  <div className="deuold-roll">
                    <p>Roll No.</p>
                    <h5>
                      {this.state.mStudentDetails.length !== 0
                        ? this.state.mStudentDetails[0].class[0].student_roll_no
                        : ""}
                    </h5>
                    <p>
                      {this.state.mStudentDetails.length !== 0
                        ? this.state.mStudentDetails[0].class[0].class_name
                        : ""}
                    </p>
                  </div>
                </div>


                <div className="deuold-due">
                  <span>Due Amount Rs: 2500</span>
                  <button className="deuold-edit">
                    <i className="fa fa-edit"></i>
                  </button>
                </div>
              </div>
            </section>

            <section className="deuold_Collection">
              <div className="row ">
                <div className="col-md-12 mb-3 mp1_0">
                  <div className="deuold_Collection-due-card">
                    <div className="deuold_Collection-due-checkbox">
                      <input
                        type="checkbox"
                        id="DueAmount1"
                        name="Due Amount"
                        value="1500"
                      />
                    </div>
                    <div className="deuold_Collection-due-details">
                      <h5><strong>Rs: <span>1500</span></strong></h5>
                      <h5>2019-2020</h5>
                      <h5 className="deuold_Collection-text-success">Fee</h5>
                    </div>
                    <div className="deuold_Collection-delete-icon">
                      <button className="btn btn-danger btn-sm">
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
               
              </div>
              <div className="row">
                <div className="col-12 collection-container">
                  <h3 className="deuold_Collection-title">Collect By Other Amount</h3>
                  <input type="text" className="collection-input" placeholder="Enter amount..."  disabled />
                </div>

              </div>
              <div className="row ">
                <div className="col-12 mp1_0">
                  <button className="btn btn-primary deuold_Collection-collect-button">
                    Collect Now (Rs: 2500)
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
      )
    }
  }
}
