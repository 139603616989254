import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import MyLoader from "../Spinner/MyLoader";
import MdlViewLeaveDetails from "../dialog/MdlViewLeaveDetails";
import boyImage from "../img/boy.jpg";

const StudentLeaveDetails = () => {
  const token = localStorage.getItem("token");
  const [loggedIn] = useState(token !== null);
  const [loading, setLoading] = useState(true);
  const [leaveStudents, setLeaveStudents] = useState([]);
  const [isOpenViewLeaveDetails, setViewLeaveDetails] = useState(false);

  const toggleViewLeaveDetails = () => {
    setViewLeaveDetails(!isOpenViewLeaveDetails);
  };

  useEffect(() => {
    // fetchLeaveStudents();
    setLoading(false); // Dummy data fetching simulation
  }, []);

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  if (loading) {
    return <MyLoader />;
  }

  return (
    <Router>
      <Switch>
        <Route
          path="/AdminMain/StudentLeaveDetails"
          render={() => (
            <div className="content">
              <div className="content-header">
                <div className="leftside-content-header">
                  <ul className="breadcrumbs">
                    <li>
                      <i className="fa fa-home" aria-hidden="true" />
                      <a href="#">Student Leave Details </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="animated fadeInUp bg_page_white_rounded box_shadow"
                style={{ "--mMargin": "50px" }}
              >
                <h2 className="page-title1">Student Leave Details </h2>
                <div className="bg_card_burnt_orange_outline margin_staff">
                  <div className="LeaveAdmin">
                    <div className="leave-container">
                      <div className="leave-box">
                        <div className="top-row">
                          <p style={{ fontWeight: "bold", color: "#222" }}>
                            Date: 10/12/2024
                          </p>
                          <h3 style={{ fontWeight: "bold", color: "#222" }}>
                            Student Name: Varun Yadav
                          </h3>
                          <p style={{ fontWeight: "bold", color: "#222" }}>
                            Class: Play Group-A
                          </p>
                          <div
                            className="status"
                            style={{ fontWeight: "bold", color: "#222" }}
                          >
                            Status: Pending
                          </div>
                        </div>
                        <div className="bottom-row">
                          <div
                            className="subject"
                            style={{ fontWeight: "bold", color: "#222" }}
                          >
                            Subject: Application for Leave Due to Medical
                            Reasons
                          </div>
                          <button
                            className="btn-view"
                            onClick={() => toggleViewLeaveDetails()}
                          >
                            View Details{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg_card_burnt_orange_outline margin_staff">
                  <div className="LeaveAdmin">
                    <div className="leave-container">
                      <div className="leave-box">
                        <div className="top-row">
                          <p style={{ fontWeight: "bold", color: "#222" }}>
                            Date: 10/12/2024
                          </p>
                          <h3 style={{ fontWeight: "bold", color: "#222" }}>
                            Student Name: Varun Yadav
                          </h3>
                          <p style={{ fontWeight: "bold", color: "#222" }}>
                            Class: Play Group-A
                          </p>
                          <div
                            className="status"
                            style={{ fontWeight: "bold", color: "#222" }}
                          >
                            Status: Pending
                          </div>
                        </div>
                        <div className="bottom-row">
                          <div
                            className="subject"
                            style={{ fontWeight: "bold", color: "#222" }}
                          >
                            Subject: Application for Leave Due to Medical
                            Reasons
                          </div>
                          <button
                            className="btn-view"
                            onClick={() => toggleViewLeaveDetails()}
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {isOpenViewLeaveDetails && (
                  <MdlViewLeaveDetails actionClose={toggleViewLeaveDetails} />
                )}
              </div>
            </div>
          )}
        />
      </Switch>
    </Router>
  );
};

export default StudentLeaveDetails;
