import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
  useParams,
} from "react-router-dom";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import myToast from "../Utils/MyToast";
import EditStaff from "./EditStaff";
import AddQualification from "./AddQualification";
import EditQualification from "./EditQualification";
import "../css/Admin_css/admin-customize.css";
import boyImage from "../img/boy.jpg";

const StaffProfile = () => {
  const { Staff_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [staffProfile, setStaffProfile] = useState([]);
  const [staffQualifications, setStaffQualifications] = useState([]);

  useEffect(() => {
    let isMounted = true; // flag to track if component is mounted

    const fetchStaffProfile = async () => {
      setLoading(true);
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      const FUNCTION_NAME = "getStaffDetails.php";
      const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

      try {
        const response = await axios.get(URL, {
          params: { staff_id: Staff_id, school_code: SCHOOL_CODE },
        });
        if (isMounted) {
          setLoading(false);
          if (response.data.api_status === "OK") {
            setStaffProfile(response.data.data);
            setStaffQualifications(response.data.data[0]?.qualification || []);
          } else {
            myToast.notify_warning(response.data.api_remark);
          }
        }
      } catch (error) {
        if (isMounted) {
          setLoading(false);
          console.error("Error fetching staff details:", error);
        }
      }
    };

    fetchStaffProfile();

    return () => {
      // cleanup: mark component as unmounted
      isMounted = false;
    };
  }, [Staff_id]);

  if (loading) return <MyLoader />;

  return (
    <Router>
      <Switch>
        <Route path="/AdminMain/StaffProfile/:Staff_id" exact>
          <div className="content">
            <div className="content-header">
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li>
                    <i className="fa fa-home" aria-hidden="true" />
                    <Link to="#">Staff Profile Details</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="bg_page_white_rounded box_shadow animated fadeInUp"
              style={{ "--mMargin": "30px" }}
            >
              <div className="NewSProfile-container">
                {staffProfile.map((item, index) => (
                  <div key={index} className="NewSProfile-card">
                    <div className="NewSProfile-top">
                      <img
                        src={boyImage}
                        className="NewSProfile-img"
                        alt="Staff"
                      />
                      <div className="NewSProfile-basic">
                        <h3>{item.staff_name}</h3>
                        <p>
                          {item.login_type} ({item.staff_role})
                        </p>
                      </div>
                      <Link
                        to={{
                          pathname: "/AdminMain/EditStaff",
                          state: { staffData: item },
                        }}
                        className="NewSProfile-edit1"
                      >
                        ✎ Edit
                      </Link>
                    </div>

                    <div className="NewSProfile-info">
                      <div className="NewSProfile-column">
                        <p>
                          <strong>Phone:</strong> {item.staff_phone}
                        </p>
                        <p>
                          <strong>Email:</strong> {item.staff_email}
                        </p>
                        <p>
                          <strong>Aadhar No.:</strong> {item.aadhar_no}
                        </p>
                      </div>
                      <div className="NewSProfile-column">
                        <p>
                          <strong>Joining Date:</strong> {item.joining_date}
                        </p>
                        <p>
                          <strong>DOB:</strong> {item.staff_dob}
                        </p>
                        <p>
                          <strong>Gender:</strong> {item.staff_gender}
                        </p>
                      </div>
                      <div className="NewSProfile-column">
                        <p>
                          <strong>Address:</strong> {item.staff_address}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="NewSProfile-qualifications">
                  <div className="NewSProfile-heading">
                    Qualification
                    <Link
                      to={`/AdminMain/AddQualification`}
                      className="NewSProfile-add"
                    >
                      + Add More
                    </Link>
                  </div>
                  {staffQualifications.map((item2, index2) => (
                    <div key={index2} className="NewSProfile-qual-card">
                      <p>
                        <strong>{item2.staff_degree}</strong> -{" "}
                        {item2.staff_university}
                      </p>
                      <p>Year: {item2.staff_passing_year}</p>
                      <p>Percentage: {item2.staff_percentage}</p>
                      <Link
                        to={{
                          pathname: `/AdminMain/EditQualification`,
                          state: { qualification: item2 },
                        }}
                        className="NewSProfile-edit"
                      >
                        ✎ Edit
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Route>
        <Route path="/AdminMain/EditStaff" component={EditStaff} />
        <Route
          path="/AdminMain/AddQualification"
          component={AddQualification}
        />
        <Route
          path="/AdminMain/EditQualification"
          component={EditQualification}
        />
      </Switch>
    </Router>
  );
};

export default StaffProfile;
