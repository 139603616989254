import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();
class MyToast {
  notify_success = (message) => {
    toast.success(message, { position: toast.POSITION.TOP_CENTER });
  };
  notify_warning = (message) => {
    toast.warning(message, { position: toast.POSITION.TOP_CENTER });
  };
  notify_info = (message) => {
    toast.info(message, { position: toast.POSITION.TOP_CENTER });
  };
  notify_error = (message) => {
    toast.error(message, { position: toast.POSITION.TOP_CENTER });
  };
  notify = (message) => {
    toast(message, { position: toast.POSITION.TOP_CENTER });
  };
}
const myToast = new MyToast();
export default myToast;
