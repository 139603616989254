import React, { Component } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import COLORS from "../resources/colors/COLORS";
import icon_staff from "../resources/icons/icons_staff_100_white.png";
import icons_students from "../resources/icons/icons_students_100_white.png";
import StaffPassword from "./StaffPassword";
import StudentPassword from "./StudentPassword";

export default class Security extends Component {
  render() {
    return (
      <Router>
        <switch>
          <Route
            path="/AdminMain/Security"
            render={() => {
              return (
                <div className="content">
                  <div className="content-header">
                    <div className="leftside-content-header">
                      <ul className="breadcrumbs">
                        <li>
                          <i className="fa fa-home" aria-hidden="true" />
                          <a href="#"> Security </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="bg_page_white_rounded box_shadow"
                    style={{ "--mMargin": "50px" }}
                  >
                    <div className="row class-li">
                      <div className="col-sm-6 col-md-6 ">
                        <Link to={{ pathname: "/AdminMain/StaffPassword" }}>
                          <div
                            className="row box_shadow bg_card_blue_outline"
                            style={{
                              padding: 2,
                              height: "80px",
                              margin: "10px 0px 10px 0px",
                            }}
                          >
                            <div
                              className="col-sm-4 gravity_center"
                              style={{
                                background: COLORS.card_blue,
                                height: "100%",
                                margin: 0,
                                padding: 0,
                                borderRadius: 10,
                              }}
                            >
                              <img
                                src={icon_staff}
                                style={{ height: "50px", width: "50px" }}
                              />
                            </div>
                            <div
                              className="col-sm-8 gravity_center"
                              style={{ height: "100%", margin: 0, padding: 0 }}
                            >
                              <h5> Staff Password </h5>
                            </div>
                          </div>
                        </Link>
                      </div>

                      <div className="col-sm-6 col-md-6 ">
                        <Link to={{ pathname: "/AdminMain/StudentPassword" }}>
                          <div
                            className="row box_shadow bg_card_blue_outline"
                            style={{
                              padding: 2,
                              height: "80px",
                              margin: "10px 0px 10px 0px",
                            }}
                          >
                            <div
                              className="col-sm-4 gravity_center"
                              style={{
                                background: COLORS.card_blue,
                                height: "100%",
                                margin: 0,
                                padding: 0,
                                borderRadius: 10,
                              }}
                            >
                              <img
                                src={icons_students}
                                style={{ height: "50px", width: "50px" }}
                              />
                            </div>
                            <div
                              className="col-sm-8 gravity_center"
                              style={{ height: "100%", margin: 0, padding: 0 }}
                            >
                              <h5> Student Password </h5>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          />
          <Route path="/AdminMain/StaffPassword" component={StaffPassword} />
          <Route
            path="/AdminMain/StudentPassword"
            component={StudentPassword}
          />
        </switch>
      </Router>
    );
  }
}
