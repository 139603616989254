import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import myToast from "../Utils/MyToast";
import "../css/Admin_css/admin-customize.css";

const EditQualification = () => {
  const location = useLocation();
  const history = useHistory();
  const qualificationData = location.state?.qualification; // ✅ Data received correctly

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    Degree: "",
    University: "",
    year: "",
    percentage: "",
  });

  useEffect(() => {
    if (qualificationData) {
      setFormData({
        Degree: qualificationData.staff_degree || "",
        University: qualificationData.staff_university || "",
        year: qualificationData.staff_passing_year || "",
        percentage: qualificationData.staff_percentage || "",
      });
    } else {
      myToast.notify_warning("No qualification data provided.");
      history.goBack(); // ✅ Corrected (navigate(-1) hata diya)
    }
  }, [qualificationData, history]); // ✅ Dependency updated

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!isValidInput()) return;

    setLoading(true);
    const staffId = qualificationData.id;
    const FUNCTION_NAME = "updateStaffQualification.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    try {
      const response = await axios.post(URL, {
        id: staffId,
        qualification: formData.Degree,
        university: formData.University,
        passing_year: formData.year,
        percentage: formData.percentage,
      });

      setLoading(false);
      if (response.data.api_status === "OK") {
        myToast.notify_success("Successfully Updated!");
        history.goBack(); // ✅ Corrected
      } else {
        myToast.notify_warning(response.data.api_remark || "Update failed");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating qualification:", error);
      myToast.notify_error("Error updating qualification");
    }
  };

  const isValidInput = () => {
    if (!formData.Degree.trim()) {
      myToast.notify_warning("Please enter your Qualification!");
      return false;
    }
    if (!formData.University.trim()) {
      myToast.notify_warning("Please enter your University!");
      return false;
    }
    if (!formData.year.trim()) {
      myToast.notify_warning("Enter your Passing Year!");
      return false;
    }
    if (!formData.percentage.trim()) {
      myToast.notify_warning("Please enter your Percentage!");
      return false;
    }
    return true;
  };

  if (loading) return <MyLoader />;

  return (
    <div className="content">
      <div className="content-header">
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li>
              <i className="fa fa-home" aria-hidden="true" />
              <span> Edit Qualification </span>
            </li>
          </ul>
        </div>
      </div>

      <div
        className="bg_page_white_rounded box_shadow animated fadeInUp"
        style={{ "--mMargin": "30px" }}
      >
        <h2 className="qualification-heading"> Edit Qualification </h2>
        <div className="heading-divider"></div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label className="col-form-label"> Degree: </label>
            <input
              name="Degree"
              type="text"
              value={formData.Degree}
              onChange={handleChange}
              className="form-control"
            />
          </div>

          <div className="col-md-6 mb-3">
            <label className="col-form-label"> University: </label>
            <input
              name="University"
              type="text"
              value={formData.University}
              onChange={handleChange}
              className="form-control"
            />
          </div>

          <div className="col-md-6 mb-3">
            <label className="col-form-label"> Year: </label>
            <input
              name="year"
              type="text"
              value={formData.year}
              onChange={handleChange}
              className="form-control"
            />
          </div>

          <div className="col-md-6 mb-3">
            <label className="col-form-label"> Percentage: </label>
            <input
              name="percentage"
              type="text"
              value={formData.percentage}
              onChange={handleChange}
              className="form-control"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 text-center">
            <button
              className="btn btn-success"
              onClick={handleSubmit}
              style={{ margin: "2% 0", fontSize: "20px", width: "100%" }}
            >
              Update Qualification
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditQualification;
