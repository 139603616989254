import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
} from "react-router-dom";
import MyLoader from "../Spinner/MyLoader";
import "../css/Admin_css/admin-customize.css";
import TransportAddNewRoute from "./TransportAddNewRoute";
import TransportRoutesDetails from "./TransportRoutesDetails";
import myToast from "../Utils/MyToast";
import axios from "axios";
import SessionManager from "../Utils/SessionManager";

const TransportRoutes = () => {
  const [mRoute, setMRoute] = useState([]);
  const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTransportRoutes();
  }, []);

  const getTransportRoutes = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getRouteList.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    axios
      .get(URL, {
        params: { school_code: SCHOOL_CODE },
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);

        if (response.api_status === "OK") {
          setMRoute(response.data);
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  if (loading) {
    return <MyLoader />;
  }

  return (
    <Router>
      <Switch>
        <Route path={"/AdminMain/TransportRoutes"} exact>
          <div className="content">
            <div className="content-header">
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li>
                    <i className="fa fa-home" aria-hidden="true" />
                    <a href="#">Transport Routes</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bg_page_grey_rounded box_shadow">
              <h2 className="page-title">Transport Routes Details</h2>
              <div className="Transport_Routes">
                {mRoute.length > 0 ? (
                  mRoute.map((item, index) => (
                    <div className="route-box" key={index}>
                      <div className="route-info">
                        <div>
                          <strong>Route Name:</strong> {item.route_name}
                        </div>
                        <div>
                          <strong>Distance:</strong> {item.route_distance}
                        </div>
                        <div>
                          <strong>From:</strong> {item.start_from}
                        </div>
                        <div>
                          <strong>To:</strong> {item.end_to}
                        </div>
                      </div>
                      <Link
                        to={
                          "/AdminMain/TransportRoutesDetails/" + item.route_id
                        }
                      >
                        <button className="details-btn">
                          Route Student Details
                        </button>
                      </Link>
                    </div>
                  ))
                ) : (
                  <div>No routes available</div>
                )}
                <div className="">
                  <Link to="/AdminMain/TransportAddNewRoute">
                    <button
                      className="btn btn-success"
                      style={{
                        margin: "3% 0%",
                        fontSize: "21px",
                        width: "100%",
                      }}
                    >
                      Add New Route
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Route>
      </Switch>
      <Route
        path="/AdminMain/TransportAddNewRoute"
        component={TransportAddNewRoute}
      />
      <Route
        path="/AdminMain/TransportRoutesDetails/:route_id"
        component={TransportRoutesDetails}
      />
    </Router>
  );
};

export default TransportRoutes;
