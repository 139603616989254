import React, { Component, createRef } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import COLORS from "../resources/colors/COLORS";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import OldDueCollection from "./OldDueCollection";
import myToast from "../Utils/MyToast";
import SelectClass from "../ChildComponent/SelectClass";
import AddOldDue from "./AddOldDue";
export default class OldDueList extends Component {
  constructor(props) {
    super(props);
    this.slt_board = createRef();
    this.slt_medium = createRef();
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      LoadingStudent: false,
      classBoard: [],
      classMedium: [],
      ClassSection: [],
      mFilteredClassList: [],
      mFilteredClassSectionList: [],
      mClassData: [],
      CLASS_SELECTED_POSITION: 0,
      mOldDueList: [],
      mFilteredOldDueList: [],
    };
    this.CallBackClassSelect = this.CallBackClassSelect.bind(this);
  }
  componentDidMount() {}
  CallBackClassSelect = (
    class_id,
    section_id,
    session_id,
    selected_position,
    class_name,
    section_name,
    session_name
  ) => {
    this.state.CLASS_ID = class_id;
    this.state.SECTION_ID = section_id;
    this.state.SESSION_ID = session_id;
    this.state.SELECTED_CLASS_POSITION = selected_position;
    // alert("Back"+class_id+"/"+section_id+"/"+session_id);
    this.getOldDueList();
  };
  getOldDueList() {
    this.setState({
      LoadingStudent: true,
    });
    this.state.mOldDueList = [];
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getClassStudentOldDueList.php";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    console.log("URL>>>>>" + URL);
    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          class_id: this.state.CLASS_ID,
          section_id: this.state.SECTION_ID,
          session_id: this.state.SESSION_ID,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          LoadingStudent: false,
        });
        let api_status = response.api_status;
        console.log(JSON.stringify(response));
        if (api_status == "OK") {
          this.setState({
            mOldDueList: response.data,
          });

          this.getFilteredList();
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
          this.getFilteredList();
        }

        //console.log("response>>>>>"+api_status)
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        console.log("error>>>>>" + error);
      });
  }
  getFilteredList() {
    this.state.mFilteredOldDueList = [];
    this.state.total_amount = 0;
    for (var i = 0; i < this.state.mOldDueList.length; i++) {
      if (this.state.mOldDueList[i].old_due !== null) {
        this.state.mFilteredOldDueList.push(this.state.mOldDueList[i]);
      }
    }

    this.setState({});
  }
  getTotalAmount() {
    var total_due = 0;
    var total_paid = 0;
    for (
      let index = 0;
      index < this.state.mFilteredOldDueList.length;
      index++
    ) {
      total_due = total_due + this.getTotalDue(index);
      total_paid = total_paid + this.getTotalPaid(index);
    }
    var tag = this.props.match.params.tag;
    if (tag == "1") {
      return "Total Due: INR " + total_due;
    } else if (tag == "2") {
      return "Total Recieved: INR " + total_paid;
    } else if (tag == "3") {
      return "Current Old Due: INR " + (total_due - total_paid);
    }
  }
  getTotalDue(index) {
    var amt = 0;
    var mOldDue = [];

    mOldDue = this.state.mFilteredOldDueList[index].old_due;
    for (let i = 0; i < mOldDue.length; i++) {
      amt = amt + Number(mOldDue[i].amount);
    }
    return amt;
  }
  getTotalPaid(index) {
    var amt = 0;
    var mOldDue = [];

    mOldDue = this.state.mFilteredOldDueList[index].old_due;
    for (let i = 0; i < mOldDue.length; i++) {
      amt = amt + Number(mOldDue[i].amount_paid);
    }
    return amt;
  }
  saveData = (item) => {
    //alert("saved");
    localStorage.setItem("selected_student", JSON.stringify(item));
  };
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to="/Login" />;
    }
    if (this.state.Loading) {
      return <MyLoader />;
    } else {
      return (
        <Router>
          <switch>
            <Route
              path="/AdminMain/OldDueList/:tag"
              render={() => {
                return (
                  <div className="content">
                    <div
                      className="content-header"
                      style={{ background: COLORS.primary_color_shade5 }}
                    >
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li>
                            <i
                              className="fa fa-home"
                              aria-hidden="true"
                              style={{ color: COLORS.white }}
                            />
                            <a href="#"> Old Due List </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="bg_page_white_rounded box_shadow"
                      style={{ "--mMargin": "50px" }}
                    >
                      <SelectClass
                        action={this.CallBackClassSelect}
                        selected_position="0"
                      ></SelectClass>
                      <div className="row">
                        {/* Display Total Amount */}
                        <div className="col-sm-12 col-md-12 custom-style1 box_shadow bg_card_grey_outline">
                          <p className="total-amount1">
                            {this.getTotalAmount()}
                          </p>
                        </div>

                        {/* Student List Section */}
                        <section>
                          <div className="row">
                            {this.state.LoadingStudent ? (
                              <div>
                                <MyLoader />
                              </div>
                            ) : (
                              this.state.mFilteredOldDueList.map(
                                (item, index) => (
                                  <div
                                    className="modern_oldlistDue"
                                    key={index}
                                  >
                                    <Link
                                      to={{
                                        pathname:
                                          "/AdminMain/OldDueCollection/" +
                                          item.student_enrollment_no +
                                          "/" +
                                          item.class[0].session_id,
                                      }}
                                      className="modern_oldlistDue-link"
                                    >
                                      <div className="modern_oldlistDue-content">
                                        {/* Circular Image */}
                                        <div className="modern_oldlistDue-image">
                                          <img
                                            src={item.student_img_path}
                                            alt="Student"
                                          />
                                        </div>

                                        {/* Details Section */}
                                        <div className="modern_oldlistDue-details">
                                          <h5 className="modern_oldlistDue-name">
                                            {item.stu_name} D/o{" "}
                                            {item.father_name}
                                          </h5>
                                          <p className="modern_oldlistDue-class">
                                            {item.class.length !== 0
                                              ? item.class[0].class_name
                                              : "Class Name"}{" "}
                                            -{" "}
                                            {item.class.length !== 0
                                              ? item.class[0].section_name
                                              : "Section Name"}
                                          </p>
                                        </div>

                                        {/* Buttons Section */}
                                        <div className="modern_oldlistDue-buttons">
                                          <button className="modern_button due">
                                            <strong>
                                              Old Due: INR{" "}
                                              {this.getTotalDue(index)}
                                            </strong>
                                          </button>
                                          <button className="modern_button paid">
                                            <strong>
                                              Paid: INR{" "}
                                              {this.getTotalPaid(index)}
                                            </strong>
                                          </button>
                                          <button className="modern_button current">
                                            <strong>
                                              Current Due: INR{" "}
                                              {this.getTotalDue(index) -
                                                this.getTotalPaid(index)}
                                            </strong>
                                          </button>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                )
                              )
                            )}
                          </div>
                        </section>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 col-md-12">
                          <Link to={"/AdminMain/AddOldDue"}>
                            <button
                              className="btn btn-success"
                              style={{
                                width: "100%",
                                fontSize: "24px",
                                margin: "30px 0px 15px 0px",
                                background: COLORS.primary_color_shade5,
                                border: COLORS.primary_color_shade5,
                              }}
                            >
                              {" "}
                              Add More{" "}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            />

            <Route
              path="/AdminMain/OldDueCollection/:student_id/:session_id"
              component={OldDueCollection}
            />
            <Route path="/AdminMain/AddOldDue" component={AddOldDue} />
          </switch>
        </Router>
      );
    }
  }
}
