import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import myToast from "../Utils/MyToast";
import "../css/Admin_css/admin-customize.css";
import boyImage from "../img/boy.jpg";

const StaffLogin = () => {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("token") !== null
  );
  const [loading, setLoading] = useState(false);
  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    fetchStaffList();
  }, []);

  const fetchStaffList = async () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getStaff.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    try {
      const response = await axios.get(URL, {
        params: { school_code: SCHOOL_CODE },
      });
      setLoading(false);
      if (response.data.api_status === "OK") {
        setStaffList(response.data.staff);
      } else {
        myToast.notify_warning(response.data.api_remark);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching staff list:", error);
    }
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  if (loading) {
    return <MyLoader />;
  }

  return (
    <Router>
      <Switch>
        <Route path="/AdminMain/StaffLogin" exact>
          <div className="content">
            <div className="content-header">
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li>
                    <i className="fa fa-home" aria-hidden="true" />
                    <a href="#"> Staff Login</a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="bg_page_white_rounded box_shadow animated fadeInUp"
              style={{ "--mMargin": "30px" }}
            >
              <h4 className="staff_profile_heading">STAFF LIST</h4>
              <div className="row">
                {staffList.map((staff, index) => (
                  <div className="col-md-12" key={index}>
                    <div className="staff-card d-flex align-items-center p-2 shadow-sm">
                      <img
                        src={staff.staff_image_path || boyImage}
                        alt="Staff"
                        className="staff-photo rounded-circle me-3"
                      />
                      <div className="staff-info1">
                        <div className="staff-details1">
                          <div className="staff-item">
                            <strong>Staff Name:</strong>
                            <span>{staff.staff_name}</span>
                          </div>
                          <div className="staff-item">
                            <strong>Father's Name:</strong>
                            <span>{staff.father_name}</span>
                          </div>
                          <div className="staff-item">
                            <strong>Role:</strong>
                            <span>{staff.staff_role}</span>
                          </div>

                          <div className="Sln_label1">
                            <strong className="">Login Status</strong>
                            <span>Login</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Route>
      </Switch>
    </Router>
  );
};

export default StaffLogin;
