import React, { useState } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import { Modal, Button } from "react-bootstrap";
import myToast from "../Utils/MyToast";
import { Redirect } from "react-router-dom";

const MdlAddTransportVehicles = ({ actionClose }) => {
  const [loggedIn] = useState(!!localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [vehicleName, setVehicleName] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleType, setVehicleType] = useState("");

  // ✅ Close Modal
  const handleClose = () => {
    setShow(false);
    actionClose();
  };

  // ✅ API Call to Add Vehicle
  const handleAddVehicle = async () => {
    if (!vehicleName || !vehicleNumber || !vehicleType) {
      myToast.notify_warning("Please fill all fields");
      return;
    }

    setLoading(true);
    try {
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      const FUNCTION_NAME = "addNewVehicle.php";
      const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;
      const params = new URLSearchParams();
      params.append("vehicle_name", vehicleName);
      params.append("vehicle_number", vehicleNumber);
      params.append("vehicle_type", vehicleType);
      params.append("school_code", SCHOOL_CODE);

      const response = await axios.post(URL, params);
      setLoading(false);

      if (response.data.api_status === "OK") {
        myToast.notify_success("Vehicle added successfully!");
        handleClose();
      } else {
        myToast.notify_warning(response.data.api_remark);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error adding vehicle:", error);
      myToast.notify_error("Failed to add vehicle");
    }
  };

  if (!loggedIn) return <Redirect to="/Login" />;

  return (
    <div className="addTransportVehicle-wrapper">
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton className="addTransportVehicle-header">
          <Modal.Title className="addTransportVehicle-title">
            Add New Vehicle
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="addTransportVehicle-body Vehicles_Transport-container">
          {loading ? (
            <MyLoader />
          ) : (
            <>
              {/* First Row: Vehicle Number and Vehicle Name */}
              <div className="addTransportVehicle-formRow">
                <div className="addTransportVehicle-formField">
                  <label className="addTransportVehicle-label">
                    Vehicle Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Vehicle Name"
                    className="addTransportVehicle-input"
                    value={vehicleName}
                    onChange={(e) => setVehicleName(e.target.value)}
                  />
                </div>
                <div className="addTransportVehicle-formField">
                  <label className="addTransportVehicle-label">
                    Vehicle Number
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Vehicle Number"
                    className="addTransportVehicle-input"
                    value={vehicleNumber}
                    onChange={(e) => setVehicleNumber(e.target.value)}
                  />
                </div>
              </div>

              {/* Second Row: Select Vehicle Type */}
              <div className="addTransportVehicle-formRow">
                <div className="addTransportVehicle-formField">
                  <label className="addTransportVehicle-label">
                    Select Vehicle Type
                  </label>
                  <select
                    className="addTransportVehicle-select"
                    value={vehicleType}
                    onChange={(e) => setVehicleType(e.target.value)}
                  >
                    <option value="">Select Type</option>
                    <option value="Ac">Ac</option>
                    <option value="Non Ac">Non Ac</option>
                  </select>
                </div>
              </div>
            </>
          )}
        </Modal.Body>

        <Modal.Footer className="addTransportVehicle-footer">
          <Button
            variant="success"
            onClick={handleAddVehicle}
            className="addTransportVehicle-submitButton"
            disabled={loading}
          >
            {loading ? "Adding..." : "Add Vehicle"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MdlAddTransportVehicles;
