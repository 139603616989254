import React, { useState, useEffect } from "react";
import "../css/Admin_css/admin-customize.css";
import { Redirect } from "react-router-dom";
import axios from "axios";
import SessionManager from "../Utils/SessionManager";
import MyLoader from "../Spinner/MyLoader";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const UpdateStudentDetails = () => {
  const [state, setState] = useState({
    loggedIn: !!localStorage.getItem("token"),
    Loading: false,
    student_name: "",
    dob: "",
    gender: "",
    community: "",
    RELIGION: "",
    AdharNo: "",
    nationality: "Indian",
    village_town: "",
    post_locality: "",
    police_station: "",
    current_address: "",
    permanent_address: "",
    FatherName: "",
    fatherOccupation: "Farmer",
    motherName: "",
    motherOccupation: "House Wife",
    contact1: "",
    contactName_1: "",
    relation_1: "Father",
    contact2: "",
    contactName_2: "",
    relation_2: "Mother",
    email: "",
    mSTUDetails: [],
    StateSpecificIdName: "",
    StateSpecificIdValue: "",
    PreviousSchoolDetails: "",
    admission_date: "",
    caste: "",
  });

  useEffect(() => {
    const mSTDetails = JSON.parse(localStorage.getItem("STDetails")) || [];
    if (mSTDetails.length > 0) {
      const details = mSTDetails[0];
      const classDetails = details.class?.[0] || {};
      const contactDetails = details.contact_details || [];

      setState((prev) => ({
        ...prev,
        student_name: details.stu_name || "",
        StateSpecificIdName: details.state_specific_id_name || "",
        StateSpecificIdValue: details.state_specific_id_value || "",
        nationality: details.nationality || "Indian",
        AdharNo: details.adharcard_no || "",
        RELIGION: details.religion || "",
        dob: details.birthday || "",
        gender: details.gender || "",
        community: details.community || "",
        caste: details.caste || "",
        admission_date: classDetails.registration_date || "",
        current_address: details.current_address || "",
        permanent_address: details.permanent_address || "",
        village_town: details.village_town || "",
        post_locality: details.post_locality || "",
        police_station: details.police_station || "",
        FatherName: details.father_name || "",
        fatherOccupation: details.father_occupation || "Farmer",
        motherName: details.mother_name || "",
        motherOccupation: details.mother_occupation || "House Wife",
        email: details.email_id || "",
        mSTUDetails: mSTDetails,
        contact1: contactDetails[0]?.contact_number || "",
        contactName_1: contactDetails[0]?.contact_person_name || "",
        relation_1: contactDetails[0]?.contact_person_relation || "Father",
        contact2: contactDetails[1]?.contact_number || "",
        contactName_2: contactDetails[1]?.contact_person_name || "",
        relation_2: contactDetails[1]?.contact_person_relation || "Mother",
      }));
    }
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prev) => ({ ...prev, [id]: value }));
  };

  const handleGenderChange = (e) => {
    setState((prev) => ({ ...prev, gender: e.target.value }));
  };

  const handleCommunityChange = (e) => {
    setState((prev) => ({ ...prev, community: e.target.value }));
  };

  const handleCopyAddress = (e) => {
    if (e.target.checked) {
      setState((prev) => ({
        ...prev,
        permanent_address: prev.current_address,
      }));
    }
  };

  const validateForm = () => {
    if (!state.student_name.trim()) {
      alert("Student name is required");
      return false;
    }
    if (!state.dob) {
      alert("Date of birth is required");
      return false;
    }
    if (!state.gender) {
      alert("Gender is required");
      return false;
    }
    if (!state.community) {
      alert("Community is required");
      return false;
    }
    if (state.AdharNo && !/^\d{14}$/.test(state.AdharNo)) {
      alert("Aadhar number must be 14 digits");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setState((prev) => ({ ...prev, Loading: true }));

    try {
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      const staffData = SessionManager.getStaffDetails();
      const enrollNo = state.mSTUDetails[0]?.student_enrollment_no;

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}updateStudentPersonalDetails.php`,
        {
          params: {
            enrollment_no: enrollNo,
            staff_id: staffData.staff_id,
            school_code: SCHOOL_CODE,
            ...state,
            curAddress: state.current_address,
            perAddress: state.permanent_address,
            aadhar_no: state.AdharNo,
            religion: state.RELIGION,
          },
        }
      );

      if (response.data.api_status === "OK") {
        alert("Student details updated successfully");
        window.location.reload();
      } else {
        throw new Error(response.data.message || "Update failed");
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setState((prev) => ({ ...prev, Loading: false }));
    }
  };

  if (!state.loggedIn) {
    return <Redirect to="/" />;
  }

  if (state.Loading) {
    return <MyLoader />;
  }

  return (
    <div className="content">
      <div className="content-header">
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li>
              <i className="fa fa-home" aria-hidden="true" />
              <a href="#">Update Student Details</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="row animated fadeInUp">
        <div className="col-sm-12 col-lg-12">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="panel">
                <div
                  className="panel-content text-center"
                  style={{ minHeight: "400px" }}
                >
                  <form onSubmit={handleSubmit}>
                    <div className="unique-form-container">
                      <div className="unique-form-left">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="unique-form-group">
                              <label className="unique-label">
                                Admission Number (Optional)
                              </label>
                              <input
                                type="text"
                                className="unique-input"
                                id="AddmissionNumber"
                                value="1"
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="unique-form-group">
                              <label className="unique-label">
                                State Specific Id Name (Optional)
                              </label>
                              <input
                                type="text"
                                className="unique-input"
                                id="StateSpecificIdName"
                                value={state.StateSpecificIdName}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="unique-form-group">
                              <label className="unique-label">
                                State Specific Id Number (Optional)
                              </label>
                              <input
                                type="text"
                                className="unique-input"
                                id="StateSpecificIdValue"
                                value={state.StateSpecificIdValue}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="unique-form-group">
                              <label className="unique-label">
                                Previous School Details:
                              </label>
                              <input
                                type="text"
                                className="unique-input"
                                id="PreviousSchoolDetails"
                                value={state.PreviousSchoolDetails}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="unique-form-right">
                        <img
                          src="https://clevelandpolicefoundation.org/wp-content/uploads/2012/09/female-placeholder.png"
                          alt="student"
                          className="unique-image"
                        />
                        <input
                          type="file"
                          className="unique-file-input"
                          disabled
                        />
                      </div>
                    </div>

                    <div className="pnlSubHedingBorder">
                      <div className="pnlSubHeding heading-with-bg-w">
                        Basic Information
                      </div>
                    </div>

                    <div className="UpdateStudentBasic-row">
                      <div className="UpdateStudentBasic-col">
                        <div className="UpdateStudentBasic-field">
                          <label className="UpdateStudentBasic-label">
                            Student Name
                          </label>
                          <input
                            type="text"
                            className="UpdateStudentBasic-input"
                            id="student_name"
                            value={state.student_name}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="UpdateStudentBasic-col">
                        <div className="UpdateStudentBasic-field">
                          <label className="UpdateStudentBasic-label">
                            Date of Birth
                          </label>
                          <input
                            type="date"
                            className="UpdateStudentBasic-input"
                            id="dob"
                            value={state.dob}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="UpdateStudentBasic-row">
                      <div className="UpdateStudentBasic-col">
                        <div className="UpdateStudentBasic-field">
                          <label className="UpdateStudentBasic-label">
                            Adhar Number
                          </label>
                          <input
                            type="text"
                            className="UpdateStudentBasic-input"
                            id="AdharNo"
                            value={state.AdharNo}
                            onChange={handleChange}
                            maxLength="14"
                          />
                        </div>
                      </div>
                      <div className="UpdateStudentBasic-col">
                        <div className="UpdateStudentBasic-field">
                          <label className="UpdateStudentBasic-label">
                            Community
                          </label>
                          <select
                            className="UpdateStudentBasic-input"
                            value={state.community}
                            onChange={handleCommunityChange}
                          >
                            <option value="SC">SC</option>
                            <option value="ST">ST</option>
                            <option value="OBC">OBC</option>
                            <option value="General">General</option>
                            <option value="Others">Others</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="UpdateStudentBasic-row">
                      <div className="UpdateStudentBasic-col">
                        <div className="UpdateStudentBasic-field">
                          <label className="UpdateStudentBasic-label">
                            Religion
                          </label>
                          <input
                            type="text"
                            className="UpdateStudentBasic-input"
                            id="RELIGION"
                            value={state.RELIGION}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="UpdateStudentBasic-col">
                        <div className="UpdateStudentBasic-field">
                          <label className="UpdateStudentBasic-label">
                            Gender
                          </label>
                          <RadioGroup
                            row
                            value={state.gender}
                            onChange={handleGenderChange}
                          >
                            <FormControlLabel
                              value="Male"
                              control={<Radio color="primary" />}
                              label="Male"
                            />
                            <FormControlLabel
                              value="Female"
                              control={<Radio color="primary" />}
                              label="Female"
                            />
                          </RadioGroup>
                        </div>
                      </div>
                    </div>

                    <div className="UpdateStudentBasic-row">
                      <div className="UpdateStudentBasic-col">
                        <div className="UpdateStudentBasic-field">
                          <label className="UpdateStudentBasic-label">
                            Caste (Optional)
                          </label>
                          <input
                            type="text"
                            className="UpdateStudentBasic-input"
                            id="caste"
                            value={state.caste}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="UpdateStudentBasic-col">
                        <div className="UpdateStudentBasic-field">
                          <label className="UpdateStudentBasic-label">
                            Nationality
                          </label>
                          <input
                            type="text"
                            className="UpdateStudentBasic-input"
                            id="nationality"
                            value={state.nationality}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="UpdateStudentBasic-row">
                      <div className="UpdateStudentBasic-col">
                        <div className="UpdateStudentBasic-field">
                          <label className="UpdateStudentBasic-label">
                            Village/Town
                          </label>
                          <input
                            type="text"
                            className="UpdateStudentBasic-input"
                            id="village_town"
                            value={state.village_town}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="UpdateStudentBasic-col">
                        <div className="UpdateStudentBasic-field">
                          <label className="UpdateStudentBasic-label">
                            Post/Locality
                          </label>
                          <input
                            type="text"
                            className="UpdateStudentBasic-input"
                            id="post_locality"
                            value={state.post_locality}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="pnlSubHedingBorder headMarginTop">
                      <div className="pnlSubHeding heading-with-bg-w">
                        Address Information
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Current Address</label>
                          <input
                            type="text"
                            className="form-control"
                            id="current_address"
                            value={state.current_address}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="pnlSubHedingBorder headMarginTop">
                      <div className="pnlSubHeding heading-with-bg-w">
                        Permanent Address
                      </div>
                    </div>

                    <div className="row mar_top">
                      <div className="col-md-8">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="copyAddress"
                            onChange={handleCopyAddress}
                          />
                          <label className="form-check-label">
                            Same As Current Address
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="permanent_address"
                            value={state.permanent_address}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="pnlSubHedingBorder headMarginTop">
                      <div className="pnlSubHeding heading-with-bg-w">
                        Family Information
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Father's Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="FatherName"
                            value={state.FatherName}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Father's Occupation</label>
                          <input
                            type="text"
                            className="form-control"
                            id="fatherOccupation"
                            value={state.fatherOccupation}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Mother's Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="motherName"
                            value={state.motherName}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Mother's Occupation</label>
                          <input
                            type="text"
                            className="form-control"
                            id="motherOccupation"
                            value={state.motherOccupation}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="pnlSubHedingBorder headMarginTop">
                      <div className="pnlSubHeding heading-with-bg-w">
                        Contact Details
                      </div>
                    </div>

                    <div className="UpdateStudentContact-container">
                      <div className="UpdateStudentContact-row">
                        <div className="UpdateStudentContact-col-2">
                          <label className="UpdateStudentContact-label">
                            Contact Number 1
                          </label>
                        </div>
                        <div className="UpdateStudentContact-col-2">
                          <input
                            type="tel"
                            className="UpdateStudentContact-input"
                            id="contact1"
                            value={state.contact1}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="UpdateStudentContact-col-2">
                          <label className="UpdateStudentContact-label">
                            Name
                          </label>
                        </div>
                        <div className="UpdateStudentContact-col-2">
                          <input
                            type="text"
                            className="UpdateStudentContact-input"
                            id="contactName_1"
                            value={state.contactName_1}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="UpdateStudentContact-col-2">
                          <label className="UpdateStudentContact-label">
                            Relation
                          </label>
                        </div>
                        <div className="UpdateStudentContact-col-2">
                          <select
                            className="UpdateStudentContact-input"
                            value={state.relation_1}
                            onChange={handleChange}
                            id="relation_1"
                          >
                            <option value="Father">Father</option>
                            <option value="Mother">Mother</option>
                            <option value="Uncle">Uncle</option>
                            <option value="Aunt">Aunt</option>
                            <option value="Grand Father">Grand Father</option>
                            <option value="Grand Mother">Grand Mother</option>
                          </select>
                        </div>
                      </div>

                      <div className="UpdateStudentContact-row">
                        <div className="UpdateStudentContact-col-2">
                          <label className="UpdateStudentContact-label">
                            Contact Number 2
                          </label>
                        </div>
                        <div className="UpdateStudentContact-col-2">
                          <input
                            type="tel"
                            className="UpdateStudentContact-input"
                            id="contact2"
                            value={state.contact2}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="UpdateStudentContact-col-2">
                          <label className="UpdateStudentContact-label">
                            Name
                          </label>
                        </div>
                        <div className="UpdateStudentContact-col-2">
                          <input
                            type="text"
                            className="UpdateStudentContact-input"
                            id="contactName_2"
                            value={state.contactName_2}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="UpdateStudentContact-col-2">
                          <label className="UpdateStudentContact-label">
                            Relation
                          </label>
                        </div>
                        <div className="UpdateStudentContact-col-2">
                          <select
                            className="UpdateStudentContact-input"
                            value={state.relation_2}
                            onChange={handleChange}
                            id="relation_2"
                          >
                            <option value="Father">Father</option>
                            <option value="Mother">Mother</option>
                            <option value="Uncle">Uncle</option>
                            <option value="Aunt">Aunt</option>
                            <option value="Grand Father">Grand Father</option>
                            <option value="Grand Mother">Grand Mother</option>
                          </select>
                        </div>
                      </div>

                      <div className="UpdateStudentContact-row">
                        <div className="UpdateStudentContact-col-2">
                          <label className="UpdateStudentContact-label">
                            Email Address
                          </label>
                        </div>
                        <div className="UpdateStudentContact-col-10">
                          <input
                            type="email"
                            className="UpdateStudentContact-input"
                            id="email"
                            value={state.email}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-4">
                      <center>
                        <button
                          type="submit"
                          className="btn btn-success btn-lg"
                          style={{ width: "100%" }}
                        >
                          Update Student Details
                        </button>
                      </center>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateStudentDetails;
