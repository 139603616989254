import React, { useEffect, useState } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import COLORS from "../resources/colors/COLORS";
import { Link, Redirect } from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";

const AccessoriesCategory = () => {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("token") !== null
  );
  const [loading, setLoading] = useState(false);
  const [cat, setCat] = useState("");
  const [accessoriesCategory, setAccessoriesCategory] = useState([]);

  useEffect(() => {
    getAccessoriesCategory();
  }, []);

  const getAccessoriesCategory = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getAccessoriesCategory.php";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    axios
      .get(URL, {
        params: { school_code: SCHOOL_CODE },
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);

        if (response.api_status === "OK") {
          setAccessoriesCategory(response.data);
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  if (loading) {
    return <MyLoader />;
  }

  return (
    <div className="content">
      <div className="content-header" style={{ background: COLORS.green6 }}>
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li>
              <i className="fa fa-home" aria-hidden="true" />
              <a href="#">Accessories Category</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="row class-li">
        {accessoriesCategory.map((item, index) => (
          <div className="col-sm-3 col-md-3" key={index}>
            <Link to="/">
              <div className="row class-list-box">
                <div className="col-sm-12 pt-45">
                  <h4 className="text-center">{item.category_name}</h4>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>

      <section>
        <div className="row mt-4">
          <div className="col-sm-12">
            <h3
              className="btn bg-primary btn-block text-white"
              style={{
                fontSize: "15px",
                textDecoration: "none",
                padding: "10px",
                cursor: "pointer",
              }}
              data-toggle="modal"
              data-target="#addcat"
            >
              <span className="clf">Add More</span>
            </h3>
          </div>
        </div>
      </section>

      <div id="addcat" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content" style={{ width: "800px" }}>
            <div className="modal-header bg-primary">
              <h4 className="modal-title pull-left">Add Category</h4>
              <button
                type="button"
                className="close full-right"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="row" style={{ padding: "0px 15px" }}>
                <div className="col-sm-12 col-md-12">
                  <p>Product Category</p>
                  <div>
                    <input
                      type="text"
                      name="cat"
                      value={cat}
                      placeholder="Enter Category Name"
                      className="select-field"
                      onChange={(e) => setCat(e.target.value)}
                    />
                  </div>
                </div>
                <div
                  className="col-sm-12 text-center"
                  style={{ margin: "15px auto" }}
                >
                  <h3
                    style={{
                      background: "#129cd9",
                      color: "#fff",
                      padding: "7px",
                      cursor: "pointer",
                    }}
                    onClick={() => console.log("Submit clicked", cat)}
                  >
                    Submit
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Modal Section */}
    </div>
  );
};

export default AccessoriesCategory;
